import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  classes,
  mockDimensions,
  mockStudentsResult,
  periods,
  years,
} from "../../../common/Mock";
import { removeUnnecessaryCharacters } from "../../../common/RegexFNS";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Layout from "../../components/Layout";
import Table from "../../components/Table";
import StudentListAsideMenu from "../../components/Layout/AsideMenu/StudentListAsideMenu";
import "./styles.css";
import SupportContentAsideMenu from './../../components/Layout/AsideMenu/SupportContentAsideMenu/index';

const extraColumns: GridColumns = [
  {
    field: "support",
    headerName: "Necessidade de Apoio",
    type: "string",
    width: 255,
    headerClassName: "header",
  },
  {
    field: "encourage",
    headerName: "Necessidade de Incentivo",
    description: "This column has a value getter and is not sortable.",
    type: "string",
    sortable: false,
    width: 255,
    headerClassName: "header",
  },
  {
    field: "potential",
    headerName: "Potenciais Desenvolvidos",
    type: "string",
    width: 245,
    headerClassName: "header",
  },
];
const ActionPlans = () => {
  const mainColumn: GridColumns = [
    {
      field: "socioEmotionalDimension",
      headerName: "Dimensão Socioemocional",
      type: "string",
      width: 255,
      headerClassName: "header",
    },
    {
      field: "studentsAmount",
      headerName: "Incidências",
      type: "string",
      width: 245,
      headerClassName: "header",
    },
  ];

  const history = useNavigate();

  const [columns, setColumns] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);

  const [filter, setFilter] = useState({
    year: { empty: false, value: 0 },
    evaluativePeriod: { empty: false, value: 0 },
    actions: { empty: false, value: 0 },
    studentClass: { empty: false, value: 0 },
  });

  const [chosenAction, setChosenAction] = useState<any>({});
  const [studentList, setStudentList] = useState<any[]>([]);
  const [studentListVisible, setStudentListVisible] = useState<boolean>(false);
  const [customContentVisible, setCustomContentVisible] = useState<boolean>(false);


  const openStudentList: any = (params: any) => {
    let students = [];

    const {
      row: { socioEmotionalDimension, support, encourage, potential },
    } = params;

    switch (chosenAction.field) {
      case "support":
        students = rows.filter(
          (row) =>
            row.socioEmotionalDimension === socioEmotionalDimension &&
            row.support === support
        );
        break;
      case "encourage":
        students = rows.filter(
          (row) =>
            row.socioEmotionalDimension === socioEmotionalDimension &&
            row.encourage === encourage
        );
        break;
      case "potential":
        students = rows.filter(
          (row) =>
            row.socioEmotionalDimension === socioEmotionalDimension &&
            row.potential === potential
        );
        break;
    }
    setStudentList(students);
    setStudentListVisible(!studentListVisible);
  };

  const handleCellClick: any = (params: any, event: any) => {
    const { field } = params;

    switch (field) {
      case "support":
        return setCustomContentVisible(true);
      case "studentsAmount":
        return openStudentList(params);
    }
  };

  const handleFilters = (event: any) => {
    const {
      target: { value, name },
    } = event;

    setFilter({
      ...filter,
      [name]: { value: value, empty: false },
    });
  };

  const handleColumnSelection = (event: any) => {
    const {
      target: { value },
    } = event;
    handleFilters(event);
    setChosenAction(extraColumns.find((column) => column.field == value));
  };

  const calculateStudentsAmount = (
    socioEmotionalDimension: string,
    chosenColumnElement: string,
    results: any[]
  ): number => {
    const { field } = chosenAction;

    let amount = 0;
    if (!socioEmotionalDimension && chosenColumnElement) {
      amount = results.filter((row: any) => row[field] === chosenColumnElement)
        .length;
    } else if (socioEmotionalDimension && !chosenColumnElement) {
      amount = results.filter(
        (row: any) => row["socioEmotionalDimension"] === socioEmotionalDimension
      ).length;
    } else {
      amount = results.filter(
        (row: any) =>
          row[field] === chosenColumnElement &&
          row["socioEmotionalDimension"] === socioEmotionalDimension
      ).length;
    }

    return amount;
  };

  const convertDimension = (dimensionToConvert: string) => {
    return mockDimensions.find(
      (dimension: any) => dimensionToConvert === dimension.type
    ).title;
  };

  const buildActionInformation: any = (chosenAction: string) => {
    let result: any = {
      encourages: [],
      supports: [],
      potentials: [],
    };
    const studentsSupports: any[] = [];
    const studentsEncourages: any[] = [];
    const studentsPotentials: any[] = [];
    mockStudentsResult.forEach(
      ({ socioemotionalResults, student: { name }, id }) => {
        socioemotionalResults.forEach(
          ({ dimension, support, encourage, potentials }) => {
            const allSupports = support.split(";");
            const allEncourages = encourage.split(";");
            const allPotentials = potentials.split(";");

            if (allSupports.some((support: any) => support != "")) {
              allSupports.forEach((support: any) => {
                if (
                  !studentsSupports.some(
                    ({ support: duplicateSupport }: any) =>
                      removeUnnecessaryCharacters === duplicateSupport
                  )
                )
                  studentsSupports.push({
                    socioEmotionalDimension: convertDimension(dimension),
                    support: removeUnnecessaryCharacters(support),
                    studentName: name,
                    studentId: id,
                  });
              });
            }

            if (allEncourages.some((encourage: any) => encourage != "")) {
              allEncourages.forEach((encourage: any) => {
                if (
                  !studentsEncourages.some(
                    ({ encourage: duplicateEncourage }: any) =>
                      encourage === duplicateEncourage
                  )
                )
                  studentsEncourages.push({
                    socioEmotionalDimension: convertDimension(dimension),
                    encourage: removeUnnecessaryCharacters(encourage),
                    studentName: name,
                    studentId: id,
                  });
              });
            }

            if (allPotentials.some((potential: any) => potential != "")) {
              allPotentials.forEach((potential: any) => {
                if (
                  !studentsPotentials.some(
                    ({ potential: duplicatePotential }: any) =>
                      potential === duplicatePotential
                  )
                )
                  studentsPotentials.push({
                    socioEmotionalDimension: convertDimension(dimension),
                    potential: removeUnnecessaryCharacters(potential),
                    studentName: name,
                    studentId: id,
                  });
              });
            }
          }
        );
        result = {
          supports: studentsSupports,
          encourages: studentsEncourages,
          potentials: studentsPotentials,
        };
      }
    );

    switch (chosenAction) {
      case "support":
        return result.supports;
      case "encourage":
        return result.encourages;
      case "potential":
        return result.potentials;
    }
  };

  const buildResults = () => {
    const { year, evaluativePeriod, actions, studentClass } = filter;

    if (
      year.value !== 0 &&
      evaluativePeriod.value !== 0 &&
      actions.value !== 0 &&
      chosenAction
    ) {
      const { [0]: dimension, [1]: amount } = mainColumn;
      setColumns([dimension, chosenAction, amount]);

      const actionInformation = buildActionInformation(chosenAction.field);

      const newRows = actionInformation.map((row: any, index: number) => {
        return {
          id: index,
          ...row,
          studentsAmount: calculateStudentsAmount(
            row.socioEmotionalDimension,
            row[chosenAction.field],
            actionInformation
          ),
        };
      });

      // let filteredRows = [];
      // switch (chosenAction.field) {
      //   case "support":
      //     filteredRows= newRows.filter((row: any, index: number)=> {
      //       return  newRows.some((newRow: any, newIndex: number) => newRow.support === row.support && newRow.socioEmotionalDimension === row.socioEmotionalDimension && index !== newIndex);
      //     })
      //     break;
      //   case "encourage":

      //   case "potential":
      // }

      // console.log("newRows", filteredRows)

      setRows(newRows);
    } else {
      let isFilterEmpty = false;
      let isEvaluativePeriodEmpty = false;
      let isActionEmpty = false;
      // const isFilterEmpty = false;

      if (year.value === 0) {
        isFilterEmpty = true;
      }

      if (evaluativePeriod.value === 0) {
        isEvaluativePeriodEmpty = true;
      }

      if (actions.value === 0) {
        isActionEmpty = true;
      }

      setFilter({
        ...filter,
        ["year"]: {
          empty: isFilterEmpty,
          value: isFilterEmpty ? 0 : filter.year.value,
        },
        ["evaluativePeriod"]: {
          empty: isEvaluativePeriodEmpty,
          value: isEvaluativePeriodEmpty ? 0 : filter.evaluativePeriod.value,
        },

        ["actions"]: {
          empty: isActionEmpty,
          value: isActionEmpty ? 0 : filter.actions.value,
        },
      });
    }
  };

  return (
    <Layout showHeader={true} showMenu={true} showProfileIcon={true}>
      <StudentListAsideMenu
        asideMenuVisible={studentListVisible}
        studentList={studentList}
        handleAsideMenu={() => setStudentListVisible(false)}
      />
      <SupportContentAsideMenu  asideMenuVisible={customContentVisible}
        handleAsideMenu={() => setCustomContentVisible(false)} />
      <div className="wrapper-action-plans">
        <div className="filters">
          <Input
            select={true}
            label={"Ano letivo"}
            options={years}
            value={filter.year.value}
            onChange={handleFilters}
            name="year"
            required={true}
            empty={filter.year.empty}
          />
          <Input
            select={true}
            label={"Período Avaliativo"}
            options={periods}
            value={filter.evaluativePeriod.value}
            onChange={handleFilters}
            name="evaluativePeriod"
            required={true}
            empty={filter.evaluativePeriod.empty}
          />
          <Input
            select={true}
            label={"Turma"}
            empty={filter.studentClass.empty}
            options={classes}
            value={filter.studentClass.value}
            onChange={handleFilters}
            name="studentClass"
          />
          <div className="generate-button">
            <Button
              height={50}
              width={"60%"}
              borderRadius={5}
              onClick={buildResults}
            >
              Gerar plano de ação
            </Button>
          </div>
          <Input
            select={true}
            label={"Ações"}
            empty={filter.actions.empty}
            required={true}
            name="actions"
            value={filter.actions.value}
            onChange={handleColumnSelection}
            options={extraColumns.map((column: any) => {
              return { label: column.headerName, value: column.field };
            })}
          />
        </div>

        <Table
          mockColumns={columns}
          rows={rows}
          width={"90%"}
          height={400}
          isDataGrid={true}
          onCellClick={handleCellClick}
        />
      </div>
    </Layout>
  );
};

export default ActionPlans;
