import { IUser } from "./SessionProvider";

export enum SessionActionTypes {
    login = 'LOGIN',
    logout = 'LOGOUT',
}

export interface ILogin{
    type: SessionActionTypes.login,
    payload: IUser
}

export interface ILogout {
    type: SessionActionTypes.logout,
    payload: string
}


export type SessionActions = ILogin | ILogout ;