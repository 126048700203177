import { Box, ListItemText } from "@mui/material";
import Input from "../../../Input";
import AsideMenu from "..";
import { InputAdornment, List, ListItem } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { Colors } from "../../../../../common/Colors";
import { useNavigate } from "react-router-dom";

import './styles.css'


interface Props {
    asideMenuVisible: boolean,
    handleAsideMenu: () => void,
}

const SupportContentAsideMenu: React.FC<Props> = ({asideMenuVisible = false, handleAsideMenu}) => {

    return (
            <AsideMenu open={asideMenuVisible} toggleDrawer={handleAsideMenu}>
              <Box sx={{ width: 450 }}>
                <div className="wrapper-aside-menu">
                  <h2>CONTEÚDO</h2>
                  <span>Podemos carregar o teu conteúdo personalizado aqui.</span>
                  <br></br>
                  <span>Atividades a realizar, vídeos sugeridos, material digital a ser acessado e compartilhado com os alunos, etc...</span>

                </div>
              </Box>
            </AsideMenu>
          )
}

export default SupportContentAsideMenu;