import { AxiosResponse } from "axios";
import {  IQuestionnaireData } from "./QuestionnaireProvider";

export enum QuestionnaireActionTypes {
    answerQuestion = 'ANSWER_QUESTION',
    getQuestion = 'GET_QUESTION',
}

export interface IAnswerQuestion {
    type: QuestionnaireActionTypes.answerQuestion,
    payload: IQuestionnaireData
}

export interface IGetQuestion {
    type: QuestionnaireActionTypes.getQuestion,
    payload: any
}


export type QuestionnaireActions = IAnswerQuestion | IGetQuestion ;