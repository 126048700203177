import React, {
  useEffect,
  createContext,
  useState,
  useCallback,
  useContext,
  useReducer,
} from "react";
import { useNavigate } from "react-router-dom";
import { ILogin, ILogout, SessionActionTypes } from "./SessionActions";
import { SessionReducer } from "./SessionReducer";

export interface ICredentials {
  email: string;
  password: string;
}

export interface IUser {
  email: string;
  password: string;
  profession: string;
}

interface ISessionContext {
  user: IUser;
  logIn(credentials: ICredentials): void;
  logOut(): void;
}

export interface SessionState {
  user: IUser;
}

const initialState: SessionState = {
  user: { email: "", password: "", profession: "" },
};

const SessionContext = createContext({} as ISessionContext);

const SessionProvider: React.FC = ({ children }: any) => {
  const [state, dispatch] = useReducer(SessionReducer, initialState);

  const [userData, setUserData] = useState<Object>({});
  const history = useNavigate();

  useEffect(() => {
    const loadUser = () => {
      const userData = JSON.parse(sessionStorage.getItem("user") as string);
      if (userData) {
        setUserData(userData);
      }
    };
    loadUser();
  }, []);

  const logIn = useCallback(
    async (credentials: ICredentials) => {
        const { email, password } = credentials
      let fakeUser: any = { name: "Usuário" };
      // sessionStorage.setItem('user', JSON.stringify(fakeUser));

      setUserData(fakeUser);
    //   callbackFn();
      history("/dashboard");
      dispatch({
        type: SessionActionTypes.login,
        payload: credentials,
      } as ILogin);
    },
    [history]
  );

  const logOut = useCallback(async () => {
    sessionStorage.removeItem("user");

    setUserData({});
    window.location.reload();
    dispatch({
        type: SessionActionTypes.logout,
        payload: '',
      } as ILogout);
  }, []);

  const contextValues: ISessionContext = {
    ...state,
    logIn,
    logOut,
  };

  return (
    <SessionContext.Provider value={contextValues}>
      {children}
    </SessionContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(SessionContext);
  if (!context) {
  }
  return context;
};

export { useAuth, SessionProvider };
