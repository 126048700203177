import { IQuestion } from "../interfaces/IQuestionnaire";
import { IStudentLightResult, IStudentResult } from "../interfaces/IResult";

import SelfConfidenceTeen from "../assets/self-confidence-teen.jpeg";
import CommunicationTeen from "../assets/communication-teen.jpg";
import CreativityTeen from "../assets/creativity-teen.jpeg";
import LeaderShipTeen from "../assets/leadership-teen.png";
import CooperationTeen from "../assets/cooperation-teen.jpg";

import SelfConfidence18 from "../assets/self-confidence-18.jpg";
import Communication18 from "../assets/communication-18.jpg";
import Creativity18 from "../assets/creativity-18.jpeg";
import LeaderShip18 from "../assets/leadership-18.png";
import Cooperation18 from "../assets/cooperation-18.jpeg";

export const mockQuestions: IQuestion[] = [
  {
    id: "1234567",
    question: "get_student_ra",
    messages: [
      "Olá! Seja bem-vindo!\r\nEu sou a Meg e estou feliz por você estar aqui neste momento. Ainda temos que tomar muitas precauções, mas estamos próximos de retomar ao menos parte da normalidade de nossas vidas, né?",
      "Não só o isolamento social pelo qual passamos, mas a pandemia em si, de forma geral, trouxe consigo mudanças inimagináveis.... Se você chegou aqui é porque realmente compreende que este novo contexto vai exigir da gente uma reorganização na estrutura de vida e precisaremos exercitar nossas habilidades socioemocionais, apesar, por vezes, do distanciamento físico social, que fará parte de nossa nova tônica de vida.",
      "Muito provavelmente você têm interesse em explorar o universo de suas preferências cerebrais e compreender como elas impactam sobre seu perfil socioemocional e em quais frentes você pode dedicar mais empenho em termos de desenvolvimento pessoal e profissional.",
      "Antes de começarmos, informe o RA do estudante?",
    ],
    inputType: "TEXT",
    inputPlaceholder: "Informe o RA do estudante",
  },
  {
    id: "1234567",
    question: "confirm_student_information",
    studentInformation: {
      studentName: "Ana Clara",
      birthDate: "20/11/2012",
      email: "ana.clara@anglo.com.br",
    },
    messages: [],
    buttons: { "Confirma essas informações?": "Sim" },
  },
  {
    id: "61eaa2332be815007cd32300",
    question: "socioemotional_results_resume",
    messages: [
      "O estudo que fizemos em conjunto: Teste tem bom potencial quanto às habilidades associadas competência comunicação e pode melhorar seu desempenho na medida em que exercitá-las em diferentes situações de aprendizado.",
      "Desenvolver a habilidade de comunicação, significa desenvolver não só o saber ouvir e se expressar, mas também compreensão e debate de ideias",
      "Quer saber um pouco mais?",
      "Pelo que discutimos até aqui, tive alguns insights para compartilhar contigo sobre {{get_student_name}}",
      "Você conhece algumas habilidades já desenvolvidas de {{get_student_name}}, como por exemplo: ter calma; saber ouvir; ter autoaceitação; demonstrar maturidade para lidar com críticas; ter extroversão. ",
      "Sabendo disso, você pode querer ainda apoiar: interesse no ouvinte; empatia; espontaneidade; autocrítica. ",
      "E não menos importante, incentivar ações que possam desenvolver: ",
      "Olha só quanta coisa bacana descobrimos sobre {{get_student_name}} hoje, não é mesmo?",
      "Pois bem...",
      "Ainda temos muito para te contar sobre outras habilidades e preferências cerebrais que {{get_student_name}} tem.",
      "Logo a sua escola entrará em contato para enviar o resultado completo desse questionário.",
    ],
    buttons: {
      "UAU! Obrigado!": "1",
    },
    validVoucher: true,
  },
];

export const years = [
  { label: "2020", value: 1 },
  { label: "2021", value: 2 },
];

export const periods = [
  { label: "1º Trimestre", value: "1" },
  { label: "2º Trimestre", value: "2" },
  { label: "3º Trimestre", value: "3" },
];

export const classes = [
  { label: "Todas as turmas", value: 0 },
  {
    label: "1ºA",
    value: [
      { label: "Adriana", value: 0 },
      { label: "Bruna", value: 1 },
      { label: "Eziquiel", value: 2 },
    ],
  },
  {
    label: "1ºB",
    value: [
      { label: "Adriana", value: 0 },
      { label: "Bruna", value: 1 },
      { label: "Eziquiel", value: 2 },
    ],
  },
  {
    label: "1ºC",
    value: [
      { label: "Adriana", value: 0 },
      { label: "Bruna", value: 1 },
      { label: "Eziquiel", value: 2 },
    ],
  },
];

export const schoolLevel = [
  { label: "Todos os níveis", value: 0 },
  { label: "Berçário", value: [{ label: "Berçário", value: 0 }] },
  {
    label: "Educação Infantil",
    value: [
      { label: "Berçário", value: 0 },
      { label: "Jardim 1", value: 1 },
      { label: "Jardim 2", value: 2 },
      { label: "Maternal 1", value: 3 },
      { label: "Maternal 2", value: 4 },
      { label: "Pré", value: 5 },
    ],
  },
  {
    label: "Ensino Fundamental - Ciclo 1 (6 a 10 anos)",
    value: [
      { label: "1o ano", value: 0 },
      { label: "2o ano", value: 1 },
      { label: "3o ano", value: 2 },
      { label: "4o ano", value: 3 },
      { label: "5o ano", value: 4 },
    ],
  },
  {
    label: "Ensino Fundamental - Ciclo 2 (11 a 14 anos)",
    value: [
      { label: "6o ano", value: 0 },
      { label: "7o ano", value: 1 },
      { label: "8o ano", value: 2 },
      { label: "9o ano", value: 3 },
    ],
  },
  {
    label: "Ensino Médio",
    value: [
      { label: "1o ano", value: 0 },
      { label: "2o ano", value: 1 },
      { label: "3o ano", value: 2 },
    ],
  },
];

export const indicators = [
  { label: "Todas as cores", value: 0 },
  { label: "Amarelo", value: 1 },
  { label: "Indisponível", value: 2 },
  { label: "Verde", value: 3 },
  { label: "Vermelho", value: 4 },
];

export const mockStudentsLightResults: IStudentLightResult[] = [
  {
    id: "6144fa81ad8b4700791e47e5",
    user: {
      name: "Adriane Alves dos Santos",
      email: "adrianektx@gmail.com",
      company: "-",
      role: "Desempregada ",
    },
    abilities: {
      selfConfidence: "Yellow",
      selfManagementAndLeadership: "Yellow",
      communication: "Yellow",
      cooperation: "Green",
      creativity: "Green",
      criticalThinking: "Yellow",
      problemSolving: "Yellow",
    },
    creationDateTime: "2021-09-17T17:28:49.108",
  },
  {
    id: "61422764818c3a0079bd1b0c",
    user: {
      name: "Alanys Oliveira da Silva ",
      email: "alanys14silva@gmail.com",
      company: "Instituto Techmail",
      role: "Estudante",
    },
    abilities: {
      selfConfidence: "Yellow",
      selfManagementAndLeadership: "Yellow",
      communication: "Green",
      cooperation: "Green",
      creativity: "Yellow",
    },
    creationDateTime: "2021-09-15T14:03:32.899",
  },
  {
    id: "6151c331ad8b4700791e4806",
    user: {
      name: "Amanda Maria dos Santos ",
      email: "amandaasantos1777@gmail.com",
      company: "Universidade de Santo Amaro - Unisa ",
      role: "Estudante. ",
    },
    abilities: {
      selfConfidence: "Red",
      selfManagementAndLeadership: "Yellow",
      communication: "Green",
      cooperation: "Green",
      creativity: "Yellow",
      criticalThinking: "Yellow",
      problemSolving: "Yellow",
    },
    creationDateTime: "2021-09-27T10:12:17.075",
  },
  {
    id: "61422e6d818c3a0079bd1b17",
    user: {
      name: "Ana Laura Gomes Batinga",
      email: "Ana.laura.batinga21@gmail.com",
      company: "E.E. Doutor Christiano Altenfelder Silva.",
      role: "Estudante.",
    },
    abilities: {
      selfConfidence: "Yellow",
      selfManagementAndLeadership: "Yellow",
      communication: "Yellow",
      cooperation: "Green",
      creativity: "Green",
    },
    creationDateTime: "2021-09-15T14:33:33.001",
  },
  {
    id: "61422579818c3a0079bd1b00",
    user: {
      name: "Cinthia de Souza Matos Brunelli ",
      email: "cinthia.brunelli@gmail.com",
      company: "Estudo na escola Maud SA de miranda Monteiro ",
      role: "Não trabalho, no momento só sou estudante ",
    },
    abilities: {
      selfConfidence: "Green",
      selfManagementAndLeadership: "Yellow",
      communication: "Yellow",
      cooperation: "Yellow",
      creativity: "Green",
    },
    creationDateTime: "2021-09-15T13:55:21.441",
  },
  {
    id: "614227ce818c3a0079bd1b0d",
    user: {
      name: "Emanuelli Fernandes Santos",
      email: "manunu.fernandes@gmail.com",
      company: "Escola Estadual Beatriz Lopes ",
      role: "Estudante ",
    },
    abilities: {
      selfConfidence: "Red",
      selfManagementAndLeadership: "Yellow",
      communication: "Green",
      cooperation: "Green",
      creativity: "Yellow",
    },
    creationDateTime: "2021-09-15T14:05:18.11",
  },
  {
    id: "61487e77ad8b4700791e47ea",
    user: {
      name: "Emilly Simão DOS santos ",
      email: "emillysimao17@gmail.com",
      company: "Não tenho ",
      role: "Desempregada",
    },
    abilities: {
      selfConfidence: "Yellow",
      selfManagementAndLeadership: "Green",
      communication: "Yellow",
      cooperation: "Green",
      creativity: "Yellow",
      criticalThinking: "Red",
      problemSolving: "Red",
    },
    creationDateTime: "2021-09-20T09:28:39.598",
  },
  {
    id: "6151c85cad8b4700791e4807",
    user: {
      name: "Gabriel Ferreira Dos Santos",
      email: "gabrielsantos75292@gmail.com",
      company: "Nenhum ",
      role: "nenhum ",
    },
    abilities: {
      selfConfidence: "Yellow",
      selfManagementAndLeadership: "Yellow",
      communication: "Yellow",
      cooperation: "Green",
      creativity: "Green",
      criticalThinking: "Yellow",
      problemSolving: "Yellow",
    },
    creationDateTime: "2021-09-27T10:34:20.829",
  },
  {
    id: "61424431818c3a0079bd1b18",
    user: {
      name: "Gabriel Henrique Ramos ",
      email: "ramosgabriel1709@gmail.com",
      company: "Etec abdias do nascimento ",
      role: "Estudante de administração ",
    },
    abilities: {
      selfConfidence: "Yellow",
      selfManagementAndLeadership: "Yellow",
      communication: "Yellow",
      cooperation: "Green",
      creativity: "Green",
      criticalThinking: "Yellow",
      problemSolving: "Yellow",
    },
    creationDateTime: "2021-09-15T16:06:25.146",
  },
  {
    id: "61422630818c3a0079bd1b06",
    user: {
      name: "Giovanna Alves Oliveira",
      email: "giovannajo85@gmail.com",
      company: "Techmail",
      role: "Estudante",
    },
    abilities: {
      selfConfidence: "Yellow",
      selfManagementAndLeadership: "Yellow",
      communication: "Green",
      cooperation: "Green",
      creativity: "Yellow",
    },
    creationDateTime: "2021-09-15T13:58:24.112",
  },
  {
    id: "6143d5c4ad8b4700791e47e3",
    user: {
      name: "Giovanna Gomes Viana",
      email: "giovannaviana22@outlook.com",
      company: "E.E. Roberto Mange",
      role: "Estudante",
    },
    abilities: {
      selfConfidence: "Yellow",
      selfManagementAndLeadership: "Yellow",
      communication: "Green",
      cooperation: "Green",
      creativity: "Red",
    },
    creationDateTime: "2021-09-16T20:39:48.316",
  },
  {
    id: "6142749a818c3a0079bd1b24",
    user: {
      name: "Guilherme George Batista da Cruz",
      email: "guilhermegeorgeb@gmail.com",
      company: "Nenhuma ",
      role: "Desempregado ",
    },
    abilities: {
      selfConfidence: "Yellow",
      selfManagementAndLeadership: "Yellow",
      communication: "Green",
      cooperation: "Yellow",
      creativity: "Yellow",
      criticalThinking: "Yellow",
      problemSolving: "Green",
    },
    creationDateTime: "2021-09-15T19:32:58.951",
  },
  {
    id: "6142525a818c3a0079bd1b1d",
    user: {
      name: "Ianna Ravena Roseno Mineiro ",
      email: "ravenaianna13@gmail.com",
      company: "Instituto Techmaill ",
      role: "Estudante",
    },
    abilities: {
      selfConfidence: "Green",
      selfManagementAndLeadership: "Yellow",
      communication: "Yellow",
      cooperation: "Yellow",
      creativity: "Green",
    },
    creationDateTime: "2021-09-15T17:06:50.308",
  },
  {
    id: "61424f67818c3a0079bd1b1a",
    user: {
      name: "Isabelle de Souza",
      email: "isabelledesouza834@gmail.com",
      company: "techmail",
      role: "estudante",
    },
    abilities: {
      selfConfidence: "Yellow",
      selfManagementAndLeadership: "Yellow",
      communication: "Yellow",
      cooperation: "Yellow",
      creativity: "Green",
      criticalThinking: "Green",
      problemSolving: "Yellow",
    },
    creationDateTime: "2021-09-15T16:54:15.686",
  },
  {
    id: "614263dc818c3a0079bd1b20",
    user: {
      name: "Italo Soares do Amaral ",
      email: "doamaralsoaresitalo@gmail.com",
      company: "Fmu",
      role: "Estudante ",
    },
    abilities: {
      selfConfidence: "Yellow",
      selfManagementAndLeadership: "Yellow",
      communication: "Green",
      cooperation: "Green",
      creativity: "Yellow",
      criticalThinking: "Yellow",
      problemSolving: "Yellow",
    },
    creationDateTime: "2021-09-15T18:21:32.758",
  },
  {
    id: "61574b0ead8b4700791e4810",
    user: {
      name: "Jhonatan Santos Silva",
      email: "jhonsansilva@gmail.com",
      company: "ITM",
      role: "Aluno ",
    },
    abilities: {
      selfConfidence: "Yellow",
      selfManagementAndLeadership: "Yellow",
      communication: "Yellow",
      cooperation: "Yellow",
      creativity: "Yellow",
      criticalThinking: "Green",
      problemSolving: "Green",
    },
    creationDateTime: "2021-10-01T14:53:18.223",
  },
  {
    id: "61576981ad8b4700791e4813",
    user: {
      name: "Júlia Sophia Santos ",
      email: "julia9087@icloud.com",
      company: "Techmail ",
      role: "Cursando",
    },
    abilities: {
      selfConfidence: "Yellow",
      selfManagementAndLeadership: "Yellow",
      communication: "Yellow",
      cooperation: "Green",
      creativity: "Green",
    },
    creationDateTime: "2021-10-01T17:03:13.57",
  },
  {
    id: "6148e252ad8b4700791e47f8",
    user: {
      name: "Karina de Sousa Mendes",
      email: "kaahsousa08287@gmail.com",
      company: "Escola estadual professora Maria Amélia Braz ",
      role: "Estudante ",
    },
    abilities: {
      selfConfidence: "Yellow",
      selfManagementAndLeadership: "Yellow",
      communication: "Green",
      cooperation: "Green",
      creativity: "Yellow",
      criticalThinking: "Yellow",
      problemSolving: "Yellow",
    },
    creationDateTime: "2021-09-20T16:34:42.907",
  },
  {
    id: "61422b7c818c3a0079bd1b14",
    user: {
      name: "Kauany dos Santos Caetano ",
      email: "kauanyscaetano2004@gmail.com",
      company: "Instituto techamail",
      role: "Nenhuma, sou estudante ",
    },
    abilities: {
      selfConfidence: "Yellow",
      selfManagementAndLeadership: "Green",
      communication: "Yellow",
      cooperation: "Green",
      creativity: "Yellow",
    },
    creationDateTime: "2021-09-15T14:21:00.897",
  },
  {
    id: "61422627818c3a0079bd1b05",
    user: {
      name: "Kayllane ",
      email: "kayllanes394@gmail.com",
      company: "Nao trabalho ",
      role: "Não trabalho ",
    },
    abilities: {
      selfConfidence: "Yellow",
      selfManagementAndLeadership: "Yellow",
      communication: "Yellow",
      cooperation: "Green",
      creativity: "Green",
    },
    creationDateTime: "2021-09-15T13:58:15.55",
  },
  {
    id: "6143b9acad8b4700791e47e2",
    user: {
      name: "Larissa Ia Habib da Silva Amaral Santos ",
      email: "larissa2012amaral@gmail.com",
      company: "E.E Professor Roberto Mange ",
      role: "Estudante ",
    },
    abilities: {
      selfConfidence: "Red",
      selfManagementAndLeadership: "Green",
      communication: "Yellow",
      cooperation: "Green",
      creativity: "Yellow",
    },
    creationDateTime: "2021-09-16T18:39:56.494",
  },
  {
    id: "61422d14818c3a0079bd1b16",
    user: {
      name: "Leandro Henrique Oliveira da Silva",
      email: "leandrohenrique05022005@gmail.com",
      company: "Nenhuma no momento ",
      role: "Nenhuma no momento ",
    },
    abilities: {
      selfConfidence: "Yellow",
      selfManagementAndLeadership: "Yellow",
      communication: "Yellow",
      cooperation: "Green",
      creativity: "Green",
    },
    creationDateTime: "2021-09-15T14:27:48.336",
  },
  {
    id: "614250fe818c3a0079bd1b1b",
    user: {
      name: "Nicolly de Lana Santos ",
      email: "nicollylnsantos@gmail.com",
      company: "Escola Estadual Roberto mange ",
      role: "Estudante ",
    },
    abilities: {
      selfConfidence: "Yellow",
      selfManagementAndLeadership: "Yellow",
      communication: "Yellow",
      cooperation: "Green",
      creativity: "Yellow",
      criticalThinking: "Green",
      problemSolving: "Yellow",
    },
    creationDateTime: "2021-09-15T17:01:02.086",
  },
  {
    id: "614dd232ad8b4700791e4803",
    user: {
      name: "Nicolly de Lana Santos ",
      email: "nicollylnsantos@gmail.com",
      company: "E.E Roberto mange ",
      role: "Estudante ",
    },
    abilities: {
      selfConfidence: "Red",
      selfManagementAndLeadership: "Yellow",
      communication: "Yellow",
      cooperation: "Green",
      creativity: "Yellow",
      criticalThinking: "Green",
      problemSolving: "Yellow",
    },
    creationDateTime: "2021-09-24T10:27:14.573",
  },
  {
    id: "614dd1e5ad8b4700791e4802",
    user: {
      name: "Pedro Henrique Silva de Oliveira",
      email: "computadorcomsenhagui@gmail.com",
      company: "Nenhuma",
      role: "Nenhuma",
    },
    abilities: {
      selfConfidence: "Green",
      selfManagementAndLeadership: "Yellow",
      communication: "Yellow",
      cooperation: "Green",
      creativity: "Red",
    },
    creationDateTime: "2021-09-24T10:25:57.546",
  },
  {
    id: "6142b026818c3a0079bd1b28",
    user: {
      name: "Priscila de Jesus Maximiano",
      email: "priscilamaximiano24@hotmail.com",
    },
    abilities: {
      selfConfidence: "Yellow",
      selfManagementAndLeadership: "Yellow",
      communication: "Green",
      cooperation: "Green",
      creativity: "Yellow",
    },
    creationDateTime: "2021-09-15T23:47:02.233",
  },
  {
    id: "614259b9818c3a0079bd1b1f",
    user: {
      name: "Rangel de Jesus Sousa",
      email: "rangelsousa951@gmail.com",
      company: "Techmail",
      role: "Estudante",
    },
    abilities: {
      selfConfidence: "Red",
      selfManagementAndLeadership: "Red",
      communication: "Yellow",
      cooperation: "Green",
      creativity: "Yellow",
      criticalThinking: "Green",
      problemSolving: "Yellow",
    },
    creationDateTime: "2021-09-15T17:38:17.796",
  },
  {
    id: "6142983b818c3a0079bd1b25",
    user: {
      name: "Thatielle Cristine da Silva Nascimento ",
      email: "thatiellecristine182@gmail.com",
      company: "Instituto Techmail",
      role: "Estudante",
    },
    abilities: {
      selfConfidence: "Green",
      selfManagementAndLeadership: "Yellow",
      communication: "Yellow",
      cooperation: "Yellow",
      creativity: "Green",
    },
    creationDateTime: "2021-09-15T22:04:59.317",
  },
  {
    id: "6148e254ad8b4700791e47f9",
    user: {
      name: "Thaynara Martins Sales",
      email: "thaynaram348@gmail.com",
      company: "Techmail",
      role: "cursando ITM",
    },
    abilities: {
      selfConfidence: "Yellow",
      selfManagementAndLeadership: "Red",
      communication: "Yellow",
      cooperation: "Green",
      creativity: "Green",
    },
    creationDateTime: "2021-09-20T16:34:44.622",
  },
  {
    id: "6148d40fad8b4700791e47f6",
    user: {
      email: "thaynara@teste.com.br",

      name: "Thaynara Martins Sales ",
      company: "Instituição techmail ",
      role: "Cursando o ITM",
    },
    abilities: {
      selfConfidence: "Yellow",
      selfManagementAndLeadership: "Yellow",
      communication: "Yellow",
      cooperation: "Green",
      creativity: "Green",
    },
    creationDateTime: "2021-09-20T15:33:51.518",
  },
  {
    id: "61422bef818c3a0079bd1b15",
    user: {
      name: "Vitoria Neri de Souza Magalhães ",
      email: "vvitorianeri@gmail.com",
      company: "Instituto Techmail ",
      role: "Estudante ",
    },
    abilities: {
      selfConfidence: "Yellow",
      selfManagementAndLeadership: "Yellow",
      communication: "Green",
      cooperation: "Green",
      creativity: "Yellow",
      criticalThinking: "Yellow",
      problemSolving: "Yellow",
    },
    creationDateTime: "2021-09-15T14:22:55.494",
  },
  {
    id: "61422919818c3a0079bd1b10",
    user: {
      name: "Vitoria Roberta Gomes de Moraes",
      email: "vitoriargm@outlook.com",
      company: "Instituto Techmail",
      role: "Estudante de cursos de capacitação profissional.",
    },
    abilities: {
      selfConfidence: "Yellow",
      selfManagementAndLeadership: "Yellow",
      communication: "Green",
      cooperation: "Green",
      creativity: "Yellow",
      criticalThinking: "Yellow",
      problemSolving: "Yellow",
    },
    creationDateTime: "2021-09-15T14:10:49.552",
  },
  {
    id: "61422604818c3a0079bd1b03",
    user: {
      name: "Vitoria da Silva",
      email: "vitoria.silva1114@gmail.com",
      company: "Não tem ",
      role: "Autônoma",
    },
    abilities: {
      selfConfidence: "Yellow",
      selfManagementAndLeadership: "Yellow",
      communication: "Green",
      cooperation: "Green",
      creativity: "Yellow",
      criticalThinking: "Red",
      problemSolving: "Red",
    },
    creationDateTime: "2021-09-15T13:57:40.041",
  },
  {
    id: "61422627818c3a0079bd1b04",
    user: {
      name: "gabriel matos dos santos",
      email: "gabrielmatolo3@gmail.com",
      company: "sim",
      role: "nenhuma ",
    },
    abilities: {
      selfConfidence: "Yellow",
      selfManagementAndLeadership: "Yellow",
      communication: "Yellow",
      cooperation: "Green",
      creativity: "Green",
    },
    creationDateTime: "2021-09-15T13:58:15.352",
  },
  {
    id: "61422704818c3a0079bd1b09",
    user: {
      name: "julia honorato de queiroz ",
      email: "juliahonorato77@gmail.com",
      company: "nenhuma",
      role: "estou desempregada ",
    },
    abilities: {
      selfConfidence: "Red",
      selfManagementAndLeadership: "Yellow",
      communication: "Green",
      cooperation: "Green",
      creativity: "Red",
      criticalThinking: "Yellow",
      problemSolving: "Yellow",
    },
    creationDateTime: "2021-09-15T14:01:56.411",
  },
];

export const mockStudentsResult: IStudentResult[] = [
  {
    id: "6144fa81ad8b4700791e47e5",
    user: {
      name: "Adriane Alves dos Santos",
      email: "adrianektx@gmail.com",
    },
    student: {
      name: "Adriane Alves dos Santos",
      birthdate: "2002-11-05",
    },
    processDateTime: "2021-09-17T17:28:49.108",
    socioemotionalResults: [
      {
        dimension: "SELFMANAGEMENT_AND_LEADERSHIP",
        color: "Yellow",
        descriptions: [
          "Provavelmente Adriane pode se sentir desconfortvel no exerccio de algumas habilidades que compem a competncia autogesto e liderana. Para desenvolv-la, podemos incentivar Adriane a experimentar atividades que estimulem o exerccio de habilidades associadas.",
        ],
        support: "autonomia. ",
        encourage: "diversidade cultural; alteridade; tomada de decisões. ",
        potentials:
          "ter flexibilidade; saber compartilhar; ter carisma; ser favorecedor do trabalho cooperativo; ter proatividade. ",
      },
      {
        dimension: "SELFCONFIDENCE",
        color: "Yellow",
        descriptions: [
          "Provavelmente Adriane aplica a competncia autoconfiana em alguns contextos e em outros no. Para desenvolv-la, um bom exerccio  Adriane buscar novos desafios onde possa aplicar e desenvolver as habilidades relacionadas.",
        ],
        support: "equilíbrio interno. ",
        encourage: "expressão; autoexperimentação. ",
        potentials:
          "ter facilidade para autossuperação; saber lidar com a adversidade; ter persistência; apreciar desafios para o aprendizado; saber lidar com conflitos; saber lidar com as frustrações. ",
      },
      {
        dimension: "CRITICAL_THINKING",
        color: "Yellow",
        descriptions: [
          "Provavelmente Adriane pode se sentir desconfortvel no exerccio de algumas habilidades que compem a competncia pensamento crtico. Para desenvolv-la, podemos incentivar Adriane a experimentar atividades que estimulem o exerccio de habilidades associadas.",
        ],
        support: "",
        encourage: "argumentação; cientificidade; comunicação digital. ",
        potentials:
          "ter autocrítica; primar pela credibilidade; ter clareza; ter visão de conjunto ; ter análise crítica; ter persistência. ",
      },
      {
        dimension: "PROBLEM_SOLVING",
        color: "Yellow",
        descriptions: [
          "Provavelmente Adriane aplica a competncia resoluo de problemas em alguns contextos e em outros no. Para desenvolv-la, um bom exerccio  Adriane buscar novos desafios onde possa aplicar e desenvolver as habilidades relacionadas.",
        ],
        support: "assertividade. ",
        encourage: "metacognição. ",
        potentials:
          "ter capacidade mobilizadora; ter capacidade empreendedora; ter capacidade de tomar boas decisões; ser comprometido com os resultados; ter cientificidade; ter resiliência; ter criatividade. ",
      },
      {
        dimension: "COMMUNICATION",
        color: "Yellow",
        descriptions: [
          "Provavelmente Adriane pode se sentir desconfortvel no exerccio de algumas habilidades que compem a competncia comunicao. Para desenvolv-la, podemos incentivar Adriane a experimentar atividades que estimulem o exerccio de habilidades associadas.",
        ],
        support: "",
        encourage: "expressão cultural; reflexão; clareza. ",
        potentials:
          "ter autoaceitação; demonstrar maturidade para lidar com críticas; saber lidar com feedbacks; saber ouvir; saber compreender o outro; ter extroversão. ",
      },
      {
        dimension: "COOPERATION",
        color: "Green",
        descriptions: [
          "Adriane tem bom potencial quanto s habilidades associadas  competncia cooperao e pode melhorar seu desempenho na medida em que exercit-las em diferentes situaes de aprendizado.",
        ],
        support: "",
        encourage: "acolhimento; valorização da diversidade. ",
        potentials:
          "ter altruísmo; valorizar as virtudes; ser cordial; senso de organização; ter coleguismo; seguir as regras; ter sociabilidade. ",
      },
      {
        dimension: "CREATIVITY",
        color: "Green",
        descriptions: [
          "Adriane j aplica as habilidades relacionadas  competncia criatividade e pode melhorar seu desempenho em outras ou situaes sempre que se sentir motivado  exercit-las",
        ],
        support: "",
        encourage: "flexibilidade. ",
        potentials:
          "ter adaptabilidade; ter espontaneidade; ter abertismo; ter inventividade; empenhar-se em busca dos objetivos; gostar do novo; ter persistência; ter versatilidade. ",
      },
    ],
  },
  {
    id: "61422764818c3a0079bd1b0c",
    user: {
      name: "Alanys Oliveira da Silva ",
      email: "alanys14silva@gmail.com",
    },
    student: {
      name: "Alanys Oliveira da Silva ",
      birthdate: "2004-05-23",
    },
    processDateTime: "2021-09-15T14:03:32.899",
    socioemotionalResults: [
      {
        dimension: "SELFCONFIDENCE",
        color: "Yellow",
        descriptions: [
          "Provavelmente Alanys pode se sentir desconfortável no exercício de algumas habilidades que compõem a competência autoconfiança. Para desenvolvê-la, podemos incentivar Alanys a experimentar atividades que estimulem o exercício de habilidades associadas.",
        ],
        support: "",
        encourage:
          "expressão; entendimento do outro; perseverança; autoexperimentação; automotivação. ",
        potentials:
          "ter persistência; ter equilíbrio interno; apreciar desafios para o aprendizado; saber lidar com as frustrações. ",
      },
      {
        dimension: "CREATIVITY",
        color: "Yellow",
        descriptions: [
          "Provavelmente Alanys aplica a competência criatividade em alguns contextos e em outros não. Para desenvolvê-la, um bom exercício é Alanys buscar novos desafios onde possa aplicar e desenvolver as habilidades relacionadas.",
        ],
        support: "",
        encourage: "inventividade; adaptabilidade; soluções; flexibilidade. ",
        potentials:
          "ter persistência; ter espontaneidade; ter abertismo; empenhar-se em busca dos objetivos; gostar do novo. ",
      },
      {
        dimension: "SELFMANAGEMENT_AND_LEADERSHIP",
        color: "Yellow",
        descriptions: [
          "Provavelmente Alanys pode se sentir desconfortável no exercício de algumas habilidades que compõem a competência autogestão e liderança. Para desenvolvê-la, podemos incentivar Alanys a experimentar atividades que estimulem o exercício de habilidades associadas.",
        ],
        support: "",
        encourage: "autoconsciência; liderança; tomada de decisões. ",
        potentials:
          "ser favorecedor do trabalho cooperativo; ter flexibilidade; olhar os outros com bons olhos; ter proatividade; senso crítico; saber compartilhar. ",
      },
      {
        dimension: "COMMUNICATION",
        color: "Green",
        descriptions: [
          "Alanys tem bom potencial quanto às habilidades associadas à competência comunicação e pode melhorar seu desempenho na medida em que exercitá-las em diferentes situações de aprendizado.",
        ],
        support: "espontaneidade. ",
        encourage: "aprender com feedbacks. ",
        potentials:
          "demonstrar maturidade para lidar com críticas; saber ouvir; saber compreender o outro; ter extroversão; ter clareza; ter calma; ter autoaceitação. ",
      },
      {
        dimension: "COOPERATION",
        color: "Green",
        descriptions: [
          "Alanys já aplica as habilidades relacionadas à competência cooperação e pode melhorar seu desempenho em outras ou situações sempre que se sentir motivado à exercitá-las",
        ],
        support: "empatia. ",
        encourage: "",
        potentials:
          "senso de organização; ter coleguismo; olhar os outros com bons olhos; valorizar as virtudes; ter sociabilidade; ser cordial; seguir as regras; ter altruísmo. ",
      },
    ],
  },
  {
    id: '6151c331ad8b4700791e4806',
    "user": {
      "name": "Amanda Maria dos Santos ",
      "email": "amandaasantos1777@gmail.com"
    },
    "student": {
      "name": "Amanda Maria dos Santos ",
      "birthdate": "2002-02-17"
    },
    "processDateTime": "2021-09-27T10:12:17.075",
    "socioemotionalResults": [
      {
        "dimension": "SELFCONFIDENCE",
        "color": "Red",
        "descriptions": [
          "Avaliar a hiptese de existirem fatores emocionais e ou cognitivos dificultadores do desenvolvimento da competncia autoconfiana, no caso de Amanda. Alm disto,  importante acompanhar de perto e considerar o apoio especializado de profissionais aptos  diagnosticarem melhor as possveis causas."
        ],
        "support": "equilíbrio interno. ",
        "encourage": "entendimento do outro; autodidatismo; perseverança; expressão; autovalorização; autoexperimentação. ",
        "potentials": "ter persistência; ter facilidade para autossuperação. "
      },
      {
        "dimension": "CREATIVITY",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Amanda aplica a competncia criatividade em alguns contextos e em outros no. Para desenvolv-la, um bom exerccio  Amanda buscar novos desafios onde possa aplicar e desenvolver as habilidades relacionadas."
        ],
        "support": "adaptabilidade. ",
        "encourage": "exploração de ideias; curiosidade; flexibilidade; execução. ",
        "potentials": "ter espontaneidade; ter versatilidade; empenhar-se em busca dos objetivos; ter inventividade. "
      },
      {
        "dimension": "PROBLEM_SOLVING",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Amanda pode se sentir desconfortvel no exerccio de algumas habilidades que compem a competncia resoluo de problemas. Para desenvolv-la, podemos incentivar Amanda a experimentar atividades que estimulem o exerccio de habilidades associadas."
        ],
        "support": "",
        "encourage": "resiliência; síntese; mundo digital; metacognição. ",
        "potentials": "ter criatividade; ter capacidade de tomar boas decisões; ter capacidade empreendedora; ter cientificidade; ter capacidade mobilizadora. "
      },
      {
        "dimension": "SELFMANAGEMENT_AND_LEADERSHIP",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Amanda aplica a competncia autogesto e liderana em alguns contextos e em outros no. Para desenvolv-la, um bom exerccio  Amanda buscar novos desafios onde possa aplicar e desenvolver as habilidades relacionadas."
        ],
        "support": "",
        "encourage": "saber compartilhar; busca de informação; diversidade cultural; tomada de decisões. ",
        "potentials": "ter carisma; ter flexibilidade; olhar os outros com bons olhos; ser favorecedor do trabalho cooperativo; ter autonomia. "
      },
      {
        "dimension": "CRITICAL_THINKING",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Amanda pode se sentir desconfortvel no exerccio de algumas habilidades que compem a competncia pensamento crtico. Para desenvolv-la, podemos incentivar Amanda a experimentar atividades que estimulem o exerccio de habilidades associadas."
        ],
        "support": "",
        "encourage": "análise crítica; responsabilidade; cientificidade; argumentação. ",
        "potentials": "ter clareza; primar pela credibilidade; ter autocrítica; ter visão de conjunto ; boa comunicabilidade. "
      },
      {
        "dimension": "COMMUNICATION",
        "color": "Green",
        "descriptions": [
          "Amanda tem bom potencial quanto s habilidades associadas  competncia comunicao e pode melhorar seu desempenho na medida em que exercit-las em diferentes situaes de aprendizado."
        ],
        "support": "",
        "encourage": "autoconfiança; reflexão; autocrítica. ",
        "potentials": "saber compreender o outro; ter expressividade; ter autoaceitação; ter clareza; saber lidar com feedbacks; saber ouvir. "
      },
      {
        "dimension": "COOPERATION",
        "color": "Green",
        "descriptions": [
          "Amanda j aplica as habilidades relacionadas  competncia cooperao e pode melhorar seu desempenho em outras ou situaes sempre que se sentir motivado  exercit-las"
        ],
        "support": "",
        "encourage": "colaboração; consciência socioambiental. ",
        "potentials": "seguir as regras; ser cordial; senso de organização; valorizar as virtudes; olhar os outros com bons olhos; ter coleguismo; ter empatia. "
      }
    ]
  },
  {
    id: '61422e6d818c3a0079bd1b17',
    "user": {
      "name": "Ana Laura Gomes Batinga",
      "email": "Ana.laura.batinga21@gmail.com"
    },
    "student": {
      "name": "Ana Laura Gomes Batinga",
      "birthdate": "2004-06-21"
    },
    "processDateTime": "2021-09-15T14:33:33.001",
    "socioemotionalResults": [
      {
        "dimension": "SELFMANAGEMENT_AND_LEADERSHIP",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Ana pode se sentir desconfortável no exercício de algumas habilidades que compõem a competência autogestão e liderança. Para desenvolvê-la, podemos incentivar Ana a experimentar atividades que estimulem o exercício de habilidades associadas."
        ],
        "support": "trabalho cooperativo; empoderamento. ",
        "encourage": "alteridade. ",
        "potentials": "ter flexibilidade; ter iniciativa; senso crítico; saber compartilhar; ter autonomia; ter carisma. "
      },
      {
        "dimension": "COMMUNICATION",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Ana aplica a competência comunicação em alguns contextos e em outros não. Para desenvolvê-la, um bom exercício é Ana buscar novos desafios onde possa aplicar e desenvolver as habilidades relacionadas."
        ],
        "support": "espontaneidade; desinibição. ",
        "encourage": "clareza; autoaceitação. ",
        "potentials": "saber lidar com feedbacks; ter calma; saber compreender o outro; saber ouvir; demonstrar maturidade para lidar com críticas. "
      },
      {
        "dimension": "SELFCONFIDENCE",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Ana pode se sentir desconfortável no exercício de algumas habilidades que compõem a competência autoconfiança. Para desenvolvê-la, podemos incentivar Ana a experimentar atividades que estimulem o exercício de habilidades associadas."
        ],
        "support": "coragem criativa. ",
        "encourage": "autoexperimentação; expressão. ",
        "potentials": "ter persistência; saber lidar com a adversidade; saber lidar com as frustrações; saber lidar com conflitos; ter equilíbrio interno; ter facilidade para autossuperação. "
      },
      {
        "dimension": "CREATIVITY",
        "color": "Green",
        "descriptions": [
          "Ana tem bom potencial quanto às habilidades associadas à competência criatividade e pode melhorar seu desempenho na medida em que exercitá-las em diferentes situações de aprendizado."
        ],
        "support": "curiosidade. ",
        "encourage": "flexibilidade; adaptabilidade; inventividade. ",
        "potentials": "empenhar-se em busca dos objetivos; ter abertismo; ter espontaneidade; ter versatilidade; ter persistência. "
      },
      {
        "dimension": "COOPERATION",
        "color": "Green",
        "descriptions": [
          "Ana já aplica as habilidades relacionadas à competência cooperação e pode melhorar seu desempenho em outras ou situações sempre que se sentir motivado à exercitá-las"
        ],
        "support": "sociabilidade. ",
        "encourage": "valorização da diversidade; acolhimento. ",
        "potentials": "ter coleguismo; ser cordial; ter altruísmo; senso de organização; valorizar as virtudes; seguir as regras. "
      }
    ]
  },
  {
    id: '61422579818c3a0079bd1b00',
    "user": {
      "name": "Cinthia de Souza Matos Brunelli ",
      "email": "cinthia.brunelli@gmail.com"
    },
    "student": {
      "name": "Cinthia de Souza Matos Brunelli ",
      "birthdate": "2004-10-14"
    },
    "processDateTime": "2021-09-15T13:55:21.441",
    "socioemotionalResults": [
      {
        "dimension": "SELFMANAGEMENT_AND_LEADERSHIP",
        "color": "Yellow",
        "descriptions": [
          "Embora Cinthia tenha bom potencial para a competência autogestão e liderança, é importante incentivar a prática das habilidades associadas em contextos diversificados."
        ],
        "support": "",
        "encourage": "diversidade cultural; autoconsciência; autoeficácia; liderança. ",
        "potentials": "ter flexibilidade; ter iniciativa; saber compartilhar; olhar os outros com bons olhos; ter proatividade. "
      },
      {
        "dimension": "COMMUNICATION",
        "color": "Yellow",
        "descriptions": [
          "Embora Cinthia tenha bom potencial para a competência comunicação, é importante incentivar a prática das habilidades associadas em contextos diversificados."
        ],
        "support": "",
        "encourage": "autoaceitação; expressão cultural. ",
        "potentials": "saber ouvir; ter clareza; ter calma; demonstrar maturidade para lidar com críticas; saber compreender o outro; saber lidar com feedbacks; ter extroversão. "
      },
      {
        "dimension": "COOPERATION",
        "color": "Yellow",
        "descriptions": [
          "Embora Cinthia tenha bom potencial para a competência cooperação, é importante incentivar a prática das habilidades associadas em contextos diversificados."
        ],
        "support": "",
        "encourage": "acolhimento. ",
        "potentials": "seguir as regras; ter altruísmo; olhar os outros com bons olhos; ter sociabilidade; ser cordial; ter coleguismo; valorizar as virtudes; senso de organização. "
      },
      {
        "dimension": "SELFCONFIDENCE",
        "color": "Green",
        "descriptions": [
          "Cinthia já aplica as habilidades relacionadas à competência autoconfiança e pode melhorar seu desempenho em outras ou situações sempre que se sentir motivado à exercitá-las"
        ],
        "support": "",
        "encourage": "autoexperimentação. ",
        "potentials": "ter facilidade para autossuperação; ter persistência; saber lidar com a adversidade; saber lidar com conflitos; saber lidar com as frustrações; apreciar desafios para o aprendizado; ter extroversão; ter equilíbrio interno. "
      },
      {
        "dimension": "CREATIVITY",
        "color": "Green",
        "descriptions": [
          "Cinthia sente-se confortável ao aplicar as habilidades associadas à competência criatividade."
        ],
        "support": "",
        "encourage": "",
        "potentials": "ter inventividade; empenhar-se em busca dos objetivos; ter abertismo; ter versatilidade; ter adaptabilidade; gostar do novo; ter persistência; ter espontaneidade; ter flexibilidade. "
      }
    ]
  },
  {
    id: '614227ce818c3a0079bd1b0d',
    "user": {
      "name": "Emanuelli Fernandes Santos",
      "email": "manunu.fernandes@gmail.com"
    },
    "student": {
      "name": "Emanuelli Fernandes Santos",
      "birthdate": "2005-08-11"
    },
    "processDateTime": "2021-09-15T14:05:18.11",
    "socioemotionalResults": [
      {
        "dimension": "SELFCONFIDENCE",
        "color": "Red",
        "descriptions": [
          "Avaliar a hipótese de existirem fatores emocionais e ou cognitivos dificultadores do desenvolvimento da competência autoconfiança, no caso de Emanuelli. Além disto, é importante acompanhar de perto e considerar o apoio especializado de profissionais aptos à diagnosticarem melhor as possíveis causas."
        ],
        "support": "desinibição; anticonflitividade; equilíbrio interno; coragem criativa. ",
        "encourage": "autovalorização; perseverança; autoexperimentação. ",
        "potentials": "ter persistência; ter facilidade para autossuperação. "
      },
      {
        "dimension": "SELFMANAGEMENT_AND_LEADERSHIP",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Emanuelli aplica a competência autogestão e liderança em alguns contextos e em outros não. Para desenvolvê-la, um bom exercício é Emanuelli buscar novos desafios onde possa aplicar e desenvolver as habilidades relacionadas."
        ],
        "support": "empoderamento; companheirismo. ",
        "encourage": "tomada de decisões; autoconsciência; diversidade cultural. ",
        "potentials": "saber compartilhar; olhar os outros com bons olhos; ser favorecedor do trabalho cooperativo; ter flexibilidade. "
      },
      {
        "dimension": "CREATIVITY",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Emanuelli pode se sentir desconfortável no exercício de algumas habilidades que compõem a competência criatividade. Para desenvolvê-la, podemos incentivar Emanuelli a experimentar atividades que estimulem o exercício de habilidades associadas."
        ],
        "support": "curiosidade; inventividade. ",
        "encourage": "adaptabilidade; flexibilidade. ",
        "potentials": "empenhar-se em busca dos objetivos; ter abertismo; ter espontaneidade; ter persistência; ter versatilidade. "
      },
      {
        "dimension": "COMMUNICATION",
        "color": "Green",
        "descriptions": [
          "Emanuelli tem bom potencial quanto às habilidades associadas à competência comunicação e pode melhorar seu desempenho na medida em que exercitá-las em diferentes situações de aprendizado."
        ],
        "support": "desinibição. ",
        "encourage": "autoaceitação. ",
        "potentials": "demonstrar maturidade para lidar com críticas; ter expressividade; ter calma; ter clareza; saber lidar com feedbacks; saber compreender o outro; saber ouvir. "
      },
      {
        "dimension": "COOPERATION",
        "color": "Green",
        "descriptions": [
          "Emanuelli já aplica as habilidades relacionadas à competência cooperação e pode melhorar seu desempenho em outras ou situações sempre que se sentir motivado à exercitá-las"
        ],
        "support": "sociabilidade. ",
        "encourage": "",
        "potentials": "ter coleguismo; ter altruísmo; seguir as regras; valorizar as virtudes; olhar os outros com bons olhos; ter empatia; ser cordial; senso de organização. "
      }
    ]
  },
  {
    id: '61487e77ad8b4700791e47ea',
    "user": {
      "name": "Emilly Simão DOS santos ",
      "email": "emillysimao17@gmail.com"
    },
    "student": {
      "name": "Emilly Simão DOS santos ",
      "birthdate": "2001-12-20"
    },
    "processDateTime": "2021-09-20T09:28:39.598",
    "socioemotionalResults": [
      {
        "dimension": "CRITICAL_THINKING",
        "color": "Red",
        "descriptions": [
          "Avaliar a hiptese de existirem fatores emocionais e ou cognitivos dificultadores do desenvolvimento da competncia pensamento crtico, no caso de Emilly. Alm disto,  importante acompanhar de perto e considerar o apoio especializado de profissionais aptos  diagnosticarem melhor as possveis causas."
        ],
        "support": "",
        "encourage": "cientificidade; perspectiva global; lógica; responsabilidade; análise crítica; autoavaliação; interpretação de dados; comunicação digital. ",
        "potentials": "valoriza a racionalidade. "
      },
      {
        "dimension": "PROBLEM_SOLVING",
        "color": "Red",
        "descriptions": [
          "Avaliar a hiptese de existirem fatores emocionais e ou cognitivos dificultadores do desenvolvimento da competncia resoluo de problemas, no caso de Emilly. Alm disto,  importante acompanhar de perto e considerar o apoio especializado de profissionais aptos  diagnosticarem melhor as possveis causas."
        ],
        "support": "assertividade. ",
        "encourage": "mundo digital; metacognição; resiliência; aplicação do conhecimento; compreensão do trabalho. ",
        "potentials": "ter criatividade; ter cientificidade; ter capacidade mobilizadora. "
      },
      {
        "dimension": "SELFCONFIDENCE",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Emilly pode se sentir desconfortvel no exerccio de algumas habilidades que compem a competncia autoconfiana. Para desenvolv-la, podemos incentivar Emilly a experimentar atividades que estimulem o exerccio de habilidades associadas."
        ],
        "support": "",
        "encourage": "perseverança; autodidatismo; expressão; entendimento do outro; automotivação; autoexperimentação. ",
        "potentials": "saber lidar com as frustrações; ter persistência; ter equilíbrio interno. "
      },
      {
        "dimension": "COMMUNICATION",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Emilly aplica a competncia comunicao em alguns contextos e em outros no. Para desenvolv-la, um bom exerccio  Emilly buscar novos desafios onde possa aplicar e desenvolver as habilidades relacionadas."
        ],
        "support": "interesse no ouvinte. ",
        "encourage": "autocrítica; autoconfiança; aprender com feedbacks; escuta; reflexão; expressão cultural. ",
        "potentials": "saber compreender o outro; ter autoaceitação. "
      },
      {
        "dimension": "CREATIVITY",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Emilly pode se sentir desconfortvel no exerccio de algumas habilidades que compem a competncia criatividade. Para desenvolv-la, podemos incentivar Emilly a experimentar atividades que estimulem o exerccio de habilidades associadas."
        ],
        "support": "inventividade. ",
        "encourage": "curiosidade; execução; adaptabilidade. ",
        "potentials": "empenhar-se em busca dos objetivos; ter versatilidade; ter flexibilidade; ter espontaneidade; ter abertismo. "
      },
      {
        "dimension": "SELFMANAGEMENT_AND_LEADERSHIP",
        "color": "Green",
        "descriptions": [
          "Emilly tem bom potencial quanto s habilidades associadas  competncia autogesto e liderana e pode melhorar seu desempenho na medida em que exercit-las em diferentes situaes de aprendizado."
        ],
        "support": "",
        "encourage": "busca de informação; tomada de decisões; diversidade cultural; autoeficácia. ",
        "potentials": "ter flexibilidade; ter autonomia; olhar os outros com bons olhos; saber compartilhar; ter carisma. "
      },
      {
        "dimension": "COOPERATION",
        "color": "Green",
        "descriptions": [
          "Emilly j aplica as habilidades relacionadas  competncia cooperao e pode melhorar seu desempenho em outras ou situaes sempre que se sentir motivado  exercit-las"
        ],
        "support": "",
        "encourage": "consciência socioambiental; organização; acolhimento. ",
        "potentials": "seguir as regras; olhar os outros com bons olhos; valorizar as virtudes; ter altruísmo; ter coleguismo; ser cordial. "
      }
    ]
  },
  {
    id: '6151c85cad8b4700791e4807',
    "user": {
      "name": "Gabriel Ferreira Dos Santos",
      "email": "gabrielsantos75292@gmail.com"
    },
    "student": {
      "name": "Gabriel Ferreira Dos Santos",
      "birthdate": "2003-08-27"
    },
    "processDateTime": "2021-09-27T10:34:20.829",
    "socioemotionalResults": [
      {
        "dimension": "CRITICAL_THINKING",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Gabriel pode se sentir desconfortvel no exerccio de algumas habilidades que compem a competncia pensamento crtico. Para desenvolv-la, podemos incentivar Gabriel a experimentar atividades que estimulem o exerccio de habilidades associadas."
        ],
        "support": "persistência. ",
        "encourage": "cientificidade; perspectiva global; comunicação digital. ",
        "potentials": "ter clareza; primar pela credibilidade; ter análise crítica; valoriza a racionalidade; ter autocrítica. "
      },
      {
        "dimension": "SELFCONFIDENCE",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Gabriel aplica a competncia autoconfiana em alguns contextos e em outros no. Para desenvolv-la, um bom exerccio  Gabriel buscar novos desafios onde possa aplicar e desenvolver as habilidades relacionadas."
        ],
        "support": "coragem criativa. ",
        "encourage": "autoestima; autoexperimentação; expressão. ",
        "potentials": "saber lidar com a adversidade; ter persistência; ter facilidade para autossuperação; saber lidar com as frustrações; saber lidar com conflitos. "
      },
      {
        "dimension": "SELFMANAGEMENT_AND_LEADERSHIP",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Gabriel pode se sentir desconfortvel no exerccio de algumas habilidades que compem a competncia autogesto e liderana. Para desenvolv-la, podemos incentivar Gabriel a experimentar atividades que estimulem o exerccio de habilidades associadas."
        ],
        "support": "empoderamento. ",
        "encourage": "saber compartilhar; autoeficácia; diversidade cultural; tomada de decisões. ",
        "potentials": "ter flexibilidade; ter carisma; ter autonomia; olhar os outros com bons olhos. "
      },
      {
        "dimension": "COMMUNICATION",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Gabriel aplica a competncia comunicao em alguns contextos e em outros no. Para desenvolv-la, um bom exerccio  Gabriel buscar novos desafios onde possa aplicar e desenvolver as habilidades relacionadas."
        ],
        "support": "desinibição. ",
        "encourage": "autoaceitação; reflexão; autocrítica. ",
        "potentials": "saber compreender o outro; ter expressividade; saber ouvir; saber lidar com feedbacks; ter clareza. "
      },
      {
        "dimension": "PROBLEM_SOLVING",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Gabriel pode se sentir desconfortvel no exerccio de algumas habilidades que compem a competncia resoluo de problemas. Para desenvolv-la, podemos incentivar Gabriel a experimentar atividades que estimulem o exerccio de habilidades associadas."
        ],
        "support": "resiliência. ",
        "encourage": "compreensão do trabalho; aplicação do conhecimento. ",
        "potentials": "ter assertividade; ter criatividade; ser comprometido com os resultados; ter capacidade mobilizadora; analisar em profundidade; ter cientificidade. "
      },
      {
        "dimension": "CREATIVITY",
        "color": "Green",
        "descriptions": [
          "Gabriel tem bom potencial quanto s habilidades associadas  competncia criatividade e pode melhorar seu desempenho na medida em que exercit-las em diferentes situaes de aprendizado."
        ],
        "support": "curiosidade. ",
        "encourage": "espontaneidade. ",
        "potentials": "ter abertismo; empenhar-se em busca dos objetivos; ter versatilidade; ter persistência; ter adaptabilidade; ter inventividade; ter flexibilidade. "
      },
      {
        "dimension": "COOPERATION",
        "color": "Green",
        "descriptions": [
          "Gabriel j aplica as habilidades relacionadas  competncia cooperao e pode melhorar seu desempenho em outras ou situaes sempre que se sentir motivado  exercit-las"
        ],
        "support": "sociabilidade. ",
        "encourage": "colaboração. ",
        "potentials": "ser cordial; ter coleguismo; ter empatia; seguir as regras; valorizar as virtudes; senso de organização; olhar os outros com bons olhos. "
      }
    ]
  },
  {
    id: '61424431818c3a0079bd1b18',
    "user": {
      "name": "Gabriel Henrique Ramos ",
      "email": "ramosgabriel1709@gmail.com"
    },
    "student": {
      "name": "Gabriel Henrique Ramos ",
      "birthdate": "2003-09-17"
    },
    "processDateTime": "2021-09-15T16:06:25.146",
    "socioemotionalResults": [
      {
        "dimension": "CRITICAL_THINKING",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Gabriel pode se sentir desconfortável no exercício de algumas habilidades que compõem a competência pensamento crítico. Para desenvolvê-la, podemos incentivar Gabriel a experimentar atividades que estimulem o exercício de habilidades associadas."
        ],
        "support": "autocrítica. ",
        "encourage": "responsabilidade; cientificidade; análise crítica; interpretação de dados. ",
        "potentials": "ter visão de conjunto ; ter clareza; boa comunicabilidade; valoriza a racionalidade. "
      },
      {
        "dimension": "SELFCONFIDENCE",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Gabriel aplica a competência autoconfiança em alguns contextos e em outros não. Para desenvolvê-la, um bom exercício é Gabriel buscar novos desafios onde possa aplicar e desenvolver as habilidades relacionadas."
        ],
        "support": "desdramatização do erro; equilíbrio interno. ",
        "encourage": "autodidatismo; expressão. ",
        "potentials": "ter facilidade para autossuperação; saber lidar com a adversidade; ter persistência; saber lidar com as frustrações; saber lidar com conflitos. "
      },
      {
        "dimension": "PROBLEM_SOLVING",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Gabriel pode se sentir desconfortável no exercício de algumas habilidades que compõem a competência resolução de problemas. Para desenvolvê-la, podemos incentivar Gabriel a experimentar atividades que estimulem o exercício de habilidades associadas."
        ],
        "support": "",
        "encourage": "resiliência; responsabilidade; aplicação do conhecimento; metacognição; compreensão do trabalho. ",
        "potentials": "ter criatividade; ser comprometido com os resultados; ter assertividade; ter cientificidade. "
      },
      {
        "dimension": "SELFMANAGEMENT_AND_LEADERSHIP",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Gabriel aplica a competência autogestão e liderança em alguns contextos e em outros não. Para desenvolvê-la, um bom exercício é Gabriel buscar novos desafios onde possa aplicar e desenvolver as habilidades relacionadas."
        ],
        "support": "autonomia. ",
        "encourage": "busca de informação. ",
        "potentials": "ter flexibilidade; ter iniciativa; saber compartilhar; ter carisma; olhar os outros com bons olhos; ser favorecedor do trabalho cooperativo; senso crítico. "
      },
      {
        "dimension": "COMMUNICATION",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Gabriel pode se sentir desconfortável no exercício de algumas habilidades que compõem a competência comunicação. Para desenvolvê-la, podemos incentivar Gabriel a experimentar atividades que estimulem o exercício de habilidades associadas."
        ],
        "support": "",
        "encourage": "autoconfiança; autoaceitação; reflexão. ",
        "potentials": "ter clareza; saber compreender o outro; ter expressividade; saber ouvir; demonstrar maturidade para lidar com críticas; saber lidar com feedbacks. "
      },
      {
        "dimension": "CREATIVITY",
        "color": "Green",
        "descriptions": [
          "Gabriel tem bom potencial quanto às habilidades associadas à competência criatividade e pode melhorar seu desempenho na medida em que exercitá-las em diferentes situações de aprendizado."
        ],
        "support": "",
        "encourage": "curiosidade; adaptabilidade. ",
        "potentials": "ter abertismo; ter persistência; ter espontaneidade; ter flexibilidade; ter versatilidade; empenhar-se em busca dos objetivos; ter inventividade. "
      },
      {
        "dimension": "COOPERATION",
        "color": "Green",
        "descriptions": [
          "Gabriel já aplica as habilidades relacionadas à competência cooperação e pode melhorar seu desempenho em outras ou situações sempre que se sentir motivado à exercitá-las"
        ],
        "support": "",
        "encourage": "consciência socioambiental; acolhimento. ",
        "potentials": "valorizar as virtudes; senso de organização; seguir as regras; ser cordial; ter altruísmo; olhar os outros com bons olhos; ter coleguismo. "
      }
    ]
  },
  {
    id: "61422630818c3a0079bd1b06" , 
    "user": {
      "name": "Giovanna Alves Oliveira",
      "email": "giovannajo85@gmail.com"
    },
    "student": {
      "name": "Giovanna Alves Oliveira",
      "birthdate": "2003-11-27"
    },
    "processDateTime": "2021-09-15T13:58:24.112",
    "socioemotionalResults": [
      {
        "dimension": "SELFMANAGEMENT_AND_LEADERSHIP",
        "color": "Yellow",
        "descriptions": [
          "Embora Giovanna tenha bom potencial para a competência autogestão e liderança, é importante incentivar a prática das habilidades associadas em contextos diversificados."
        ],
        "support": "trabalho cooperativo. ",
        "encourage": "liderança. ",
        "potentials": "ter flexibilidade; olhar os outros com bons olhos; ter autonomia; senso crítico; ter proatividade; saber compartilhar; ter iniciativa. "
      },
      {
        "dimension": "CREATIVITY",
        "color": "Yellow",
        "descriptions": [
          "Embora Giovanna tenha bom potencial para a competência criatividade, é importante incentivar a prática das habilidades associadas em contextos diversificados."
        ],
        "support": "",
        "encourage": "espontaneidade; flexibilidade. ",
        "potentials": "ter inventividade; ter adaptabilidade; ter versatilidade; gostar do novo; empenhar-se em busca dos objetivos; ter abertismo; ter persistência. "
      },
      {
        "dimension": "SELFCONFIDENCE",
        "color": "Yellow",
        "descriptions": [
          "Embora Giovanna tenha bom potencial para a competência autoconfiança, é importante incentivar a prática das habilidades associadas em contextos diversificados."
        ],
        "support": "",
        "encourage": "autoexperimentação. ",
        "potentials": "ter equilíbrio interno; saber lidar com a adversidade; saber lidar com conflitos; apreciar desafios para o aprendizado; ter extroversão; ter facilidade para autossuperação; ter persistência; saber lidar com as frustrações. "
      },
      {
        "dimension": "COMMUNICATION",
        "color": "Green",
        "descriptions": [
          "Giovanna tem ótimo potencial para as habilidades relacionadas e provavelmente se destacará na aplicação da competência comunicação."
        ],
        "support": "",
        "encourage": "expressão cultural. ",
        "potentials": "demonstrar maturidade para lidar com críticas; ter autoaceitação; saber ouvir; ter extroversão; ter clareza; saber compreender o outro; saber lidar com feedbacks; ter calma. "
      },
      {
        "dimension": "COOPERATION",
        "color": "Green",
        "descriptions": [
          "Giovanna sente-se confortável ao aplicar as habilidades associadas à competência cooperação."
        ],
        "support": "",
        "encourage": "",
        "potentials": "seguir as regras; olhar os outros com bons olhos; ter coleguismo; senso de organização; ter sociabilidade; ter empatia; ter altruísmo; ser cordial; valorizar as virtudes. "
      }
    ]
  },
  {
    id: "6143d5c4ad8b4700791e47e3", 
    "user": {
      "name": "Giovanna Gomes Viana",
      "email": "giovannaviana22@outlook.com"
    },
    "student": {
      "name": "Giovanna Gomes Viana",
      "birthdate": "2003-12-23"
    },
    "processDateTime": "2021-09-16T20:39:48.316",
    "socioemotionalResults": [
      {
        "dimension": "CREATIVITY",
        "color": "Red",
        "descriptions": [
          "Avaliar a hiptese de existirem fatores emocionais e ou cognitivos dificultadores do desenvolvimento da competncia criatividade, no caso de Giovanna. Alm disto,  importante acompanhar de perto e considerar o apoio especializado de profissionais aptos  diagnosticarem melhor as possveis causas."
        ],
        "support": "flexibilidade. ",
        "encourage": "espontaneidade; curiosidade; adaptabilidade; soluções; execução; inventividade. ",
        "potentials": "empenhar-se em busca dos objetivos; ter abertismo. "
      },
      {
        "dimension": "SELFCONFIDENCE",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Giovanna aplica a competncia autoconfiana em alguns contextos e em outros no. Para desenvolv-la, um bom exerccio  Giovanna buscar novos desafios onde possa aplicar e desenvolver as habilidades relacionadas."
        ],
        "support": "",
        "encourage": "autodidatismo; automotivação; entendimento do outro; autovalorização; expressão; autoexperimentação. ",
        "potentials": "ter persistência; saber lidar com a adversidade; ter equilíbrio interno. "
      },
      {
        "dimension": "SELFMANAGEMENT_AND_LEADERSHIP",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Giovanna pode se sentir desconfortvel no exerccio de algumas habilidades que compem a competncia autogesto e liderana. Para desenvolv-la, podemos incentivar Giovanna a experimentar atividades que estimulem o exerccio de habilidades associadas."
        ],
        "support": "",
        "encourage": "diversidade cultural; busca de informação; saber compartilhar; autoeficácia; liderança. ",
        "potentials": "ter autonomia; ter flexibilidade; olhar os outros com bons olhos; ter iniciativa. "
      },
      {
        "dimension": "COMMUNICATION",
        "color": "Green",
        "descriptions": [
          "Giovanna tem bom potencial quanto s habilidades associadas  competncia comunicao e pode melhorar seu desempenho na medida em que exercit-las em diferentes situaes de aprendizado."
        ],
        "support": "",
        "encourage": "autoaceitação; autoconfiança; clareza; expressão cultural; autocrítica. ",
        "potentials": "saber ouvir; saber compreender o outro; ter calma; saber lidar com feedbacks. "
      },
      {
        "dimension": "COOPERATION",
        "color": "Green",
        "descriptions": [
          "Giovanna j aplica as habilidades relacionadas  competncia cooperao e pode melhorar seu desempenho em outras ou situaes sempre que se sentir motivado  exercit-las"
        ],
        "support": "",
        "encourage": "consciência socioambiental; colaboração; acolhimento. ",
        "potentials": "ter coleguismo; senso de organização; ser cordial; seguir as regras; valorizar as virtudes; olhar os outros com bons olhos. "
      }
    ]
  },
  {
    id: "6142749a818c3a0079bd1b24",
    "user": {
      "name": "Guilherme George Batista da Cruz",
      "email": "guilhermegeorgeb@gmail.com"
    },
    "student": {
      "name": "Guilherme George Batista da Cruz",
      "birthdate": "2000-05-20"
    },
    "processDateTime": "2021-09-15T19:32:58.951",
    "socioemotionalResults": [
      {
        "dimension": "SELFCONFIDENCE",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Guilherme pode se sentir desconfortável no exercício de algumas habilidades que compõem a competência autoconfiança. Para desenvolvê-la, podemos incentivar Guilherme a experimentar atividades que estimulem o exercício de habilidades associadas."
        ],
        "support": "equilíbrio interno. ",
        "encourage": "expressão; autovalorização; autoexperimentação; autodidatismo. ",
        "potentials": "ter facilidade para autossuperação; saber lidar com conflitos; saber lidar com a adversidade; ter persistência. "
      },
      {
        "dimension": "SELFMANAGEMENT_AND_LEADERSHIP",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Guilherme aplica a competência autogestão e liderança em alguns contextos e em outros não. Para desenvolvê-la, um bom exercício é Guilherme buscar novos desafios onde possa aplicar e desenvolver as habilidades relacionadas."
        ],
        "support": "",
        "encourage": "autoeficácia; busca de informação; autoconsciência; saber compartilhar. ",
        "potentials": "olhar os outros com bons olhos; ter flexibilidade; senso crítico; ter carisma; ter iniciativa. "
      },
      {
        "dimension": "CRITICAL_THINKING",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Guilherme pode se sentir desconfortável no exercício de algumas habilidades que compõem a competência pensamento crítico. Para desenvolvê-la, podemos incentivar Guilherme a experimentar atividades que estimulem o exercício de habilidades associadas."
        ],
        "support": "",
        "encourage": "cientificidade; responsabilidade. ",
        "potentials": "primar pela credibilidade; valoriza a racionalidade; boa comunicabilidade; ter análise crítica; ter clareza; ter visão de conjunto ; ter autocrítica. "
      },
      {
        "dimension": "CREATIVITY",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Guilherme aplica a competência criatividade em alguns contextos e em outros não. Para desenvolvê-la, um bom exercício é Guilherme buscar novos desafios onde possa aplicar e desenvolver as habilidades relacionadas."
        ],
        "support": "flexibilidade. ",
        "encourage": "execução; curiosidade. ",
        "potentials": "ter inventividade; ter adaptabilidade; ter versatilidade; ter abertismo; ter espontaneidade; empenhar-se em busca dos objetivos. "
      },
      {
        "dimension": "COOPERATION",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Guilherme pode se sentir desconfortável no exercício de algumas habilidades que compõem a competência cooperação. Para desenvolvê-la, podemos incentivar Guilherme a experimentar atividades que estimulem o exercício de habilidades associadas."
        ],
        "support": "",
        "encourage": "ética; consciência socioambiental; acolhimento; colaboração. ",
        "potentials": "ter coleguismo; senso de organização; seguir as regras; olhar os outros com bons olhos; ser cordial. "
      },
      {
        "dimension": "PROBLEM_SOLVING",
        "color": "Green",
        "descriptions": [
          "Guilherme tem bom potencial quanto às habilidades associadas à competência resolução de problemas e pode melhorar seu desempenho na medida em que exercitá-las em diferentes situações de aprendizado."
        ],
        "support": "",
        "encourage": "resiliência. ",
        "potentials": "analisar em profundidade; ter capacidade empreendedora; ter criatividade; ser comprometido com os resultados; ter capacidade mobilizadora; ter cientificidade; ter assertividade; ter capacidade de tomar boas decisões. "
      },
      {
        "dimension": "COMMUNICATION",
        "color": "Green",
        "descriptions": [
          "Guilherme já aplica as habilidades relacionadas à competência comunicação e pode melhorar seu desempenho em outras ou situações sempre que se sentir motivado à exercitá-las"
        ],
        "support": "",
        "encourage": "reflexão; autoconfiança. ",
        "potentials": "demonstrar maturidade para lidar com críticas; saber ouvir; saber compreender o outro; saber lidar com feedbacks; ter expressividade; ter clareza; ter autoaceitação. "
      }
    ]
  },
  {
    id: "61422627818c3a0079bd1b04",
        "user": {
      "name": "gabriel matos dos santos",
      "email": "gabrielmatolo3@gmail.com"
    },
    "student": {
      "name": "gabriel matos dos santos",
      "birthdate": "2004-02-23"
    },
    "processDateTime": "2021-09-15T13:58:15.352",
    "socioemotionalResults": [
      {
        "dimension": "COMMUNICATION",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente gabriel pode se sentir desconfortável no exercício de algumas habilidades que compõem a competência comunicação. Para desenvolvê-la, podemos incentivar gabriel a experimentar atividades que estimulem o exercício de habilidades associadas."
        ],
        "support": "aprendizado a partir das críticas. ",
        "encourage": "autoaceitação; expressão cultural; clareza. ",
        "potentials": "saber lidar com feedbacks; saber ouvir; ter extroversão; ter calma; saber compreender o outro. "
      },
      {
        "dimension": "SELFMANAGEMENT_AND_LEADERSHIP",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente gabriel aplica a competência autogestão e liderança em alguns contextos e em outros não. Para desenvolvê-la, um bom exercício é gabriel buscar novos desafios onde possa aplicar e desenvolver as habilidades relacionadas."
        ],
        "support": "companheirismo. ",
        "encourage": "diversidade cultural; autoeficácia. ",
        "potentials": "ter autonomia; saber compartilhar; ter proatividade; ter iniciativa; olhar os outros com bons olhos; ter flexibilidade. "
      },
      {
        "dimension": "SELFCONFIDENCE",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente gabriel pode se sentir desconfortável no exercício de algumas habilidades que compõem a competência autoconfiança. Para desenvolvê-la, podemos incentivar gabriel a experimentar atividades que estimulem o exercício de habilidades associadas."
        ],
        "support": "equilíbrio interno. ",
        "encourage": "perseverança; expressão. ",
        "potentials": "saber lidar com as frustrações; saber lidar com conflitos; permitir-se experimentar; apreciar desafios para o aprendizado; ter persistência; ter facilidade para autossuperação. "
      },
      {
        "dimension": "COOPERATION",
        "color": "Green",
        "descriptions": [
          "gabriel tem bom potencial quanto às habilidades associadas à competência cooperação e pode melhorar seu desempenho na medida em que exercitá-las em diferentes situações de aprendizado."
        ],
        "support": "",
        "encourage": "mediação de conflitos. ",
        "potentials": "ser cordial; ter altruísmo; ter sociabilidade; senso de organização; ter empatia; seguir as regras; olhar os outros com bons olhos; valorizar as virtudes. "
      },
      {
        "dimension": "CREATIVITY",
        "color": "Green",
        "descriptions": [
          "gabriel já aplica as habilidades relacionadas à competência criatividade e pode melhorar seu desempenho em outras ou situações sempre que se sentir motivado à exercitá-las"
        ],
        "support": "",
        "encourage": "flexibilidade. ",
        "potentials": "gostar do novo; empenhar-se em busca dos objetivos; ter espontaneidade; ter inventividade; ter adaptabilidade; ter versatilidade; ter abertismo; ter persistência. "
      }
    ]
  },
  {
    id: "61422604818c3a0079bd1b03",
    "user": {
      "name": "Vitoria da Silva",
      "email": "vitoria.silva1114@gmail.com"
    },
    "student": {
      "name": "Vitoria da Silva",
      "birthdate": "2002-09-14"
    },
    "processDateTime": "2021-09-15T13:57:40.041",
    "socioemotionalResults": [
      {
        "dimension": "CRITICAL_THINKING",
        "color": "Red",
        "descriptions": [
          "Avaliar a hipótese de existirem fatores emocionais e ou cognitivos dificultadores do desenvolvimento da competência pensamento crítico, no caso de Vitoria. Além disto, é importante acompanhar de perto e considerar o apoio especializado de profissionais aptos à diagnosticarem melhor as possíveis causas."
        ],
        "support": "visão de conjunto; racionalidade; comunicabilidade. ",
        "encourage": "cientificidade; autoavaliação; lógica; interpretação de dados; responsabilidade; análise crítica. ",
        "potentials": ""
      },
      {
        "dimension": "PROBLEM_SOLVING",
        "color": "Red",
        "descriptions": [
          "Avaliar a hipótese de existirem fatores emocionais e ou cognitivos dificultadores do desenvolvimento da competência resolução de problemas, no caso de Vitoria. Além disto, é importante acompanhar de perto e considerar o apoio especializado de profissionais aptos à diagnosticarem melhor as possíveis causas."
        ],
        "support": "assertividade; capacidade de tomar boas decisões; comprometimento. ",
        "encourage": "metacognição; compreensão do trabalho; raciocínio; resiliência. ",
        "potentials": "ter capacidade mobilizadora; ter criatividade. "
      },
      {
        "dimension": "SELFCONFIDENCE",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Vitoria pode se sentir desconfortável no exercício de algumas habilidades que compõem a competência autoconfiança. Para desenvolvê-la, podemos incentivar Vitoria a experimentar atividades que estimulem o exercício de habilidades associadas."
        ],
        "support": "desdramatização do erro; desinibição. ",
        "encourage": "autovalorização; entendimento do outro; autodidatismo. ",
        "potentials": "ter persistência; ter equilíbrio interno; saber lidar com a adversidade; ter facilidade para autossuperação. "
      },
      {
        "dimension": "CREATIVITY",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Vitoria aplica a competência criatividade em alguns contextos e em outros não. Para desenvolvê-la, um bom exercício é Vitoria buscar novos desafios onde possa aplicar e desenvolver as habilidades relacionadas."
        ],
        "support": "espontaneidade; persistência. ",
        "encourage": "inventividade; adaptabilidade; flexibilidade; curiosidade. ",
        "potentials": "empenhar-se em busca dos objetivos; ter abertismo; ter versatilidade. "
      },
      {
        "dimension": "SELFMANAGEMENT_AND_LEADERSHIP",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Vitoria pode se sentir desconfortável no exercício de algumas habilidades que compõem a competência autogestão e liderança. Para desenvolvê-la, podemos incentivar Vitoria a experimentar atividades que estimulem o exercício de habilidades associadas."
        ],
        "support": "iniciativa. ",
        "encourage": "alteridade; autoconsciência; busca de informação. ",
        "potentials": "ter carisma; ser favorecedor do trabalho cooperativo; saber compartilhar; senso crítico; ter flexibilidade. "
      },
      {
        "dimension": "COMMUNICATION",
        "color": "Green",
        "descriptions": [
          "Vitoria tem bom potencial quanto às habilidades associadas à competência comunicação e pode melhorar seu desempenho na medida em que exercitá-las em diferentes situações de aprendizado."
        ],
        "support": "",
        "encourage": "escuta; aprender com feedbacks; clareza; reflexão; autocrítica; autoconfiança. ",
        "potentials": "ter autoaceitação; saber compreender o outro; ter expressividade. "
      },
      {
        "dimension": "COOPERATION",
        "color": "Green",
        "descriptions": [
          "Vitoria já aplica as habilidades relacionadas à competência cooperação e pode melhorar seu desempenho em outras ou situações sempre que se sentir motivado à exercitá-las"
        ],
        "support": "",
        "encourage": "acolhimento; valorização da diversidade; mediação de conflitos; consciência socioambiental. ",
        "potentials": "ter altruísmo; ser cordial; seguir as regras; valorizar as virtudes; senso de organização. "
      }
    ]
  },
  {
    id: "61422919818c3a0079bd1b10",
    "user": {
      "name": "Vitoria Roberta Gomes de Moraes",
      "email": "vitoriargm@outlook.com"
    },
    "student": {
      "name": "Vitoria Roberta Gomes de Moraes",
      "birthdate": "2002-11-17"
    },
    "processDateTime": "2021-09-15T14:10:49.552",
    "socioemotionalResults": [
      {
        "dimension": "SELFCONFIDENCE",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Vitoria pode se sentir desconfortável no exercício de algumas habilidades que compõem a competência autoconfiança. Para desenvolvê-la, podemos incentivar Vitoria a experimentar atividades que estimulem o exercício de habilidades associadas."
        ],
        "support": "",
        "encourage": "expressão; autodidatismo; autoexperimentação. ",
        "potentials": "ter persistência; saber lidar com conflitos; saber lidar com as frustrações; saber lidar com a adversidade; ter facilidade para autossuperação; ter equilíbrio interno. "
      },
      {
        "dimension": "CRITICAL_THINKING",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Vitoria aplica a competência pensamento crítico em alguns contextos e em outros não. Para desenvolvê-la, um bom exercício é Vitoria buscar novos desafios onde possa aplicar e desenvolver as habilidades relacionadas."
        ],
        "support": "",
        "encourage": "argumentação; autoavaliação; responsabilidade; cientificidade. ",
        "potentials": "primar pela credibilidade; boa comunicabilidade; ter visão de conjunto ; ter análise crítica; ter clareza. "
      },
      {
        "dimension": "CREATIVITY",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Vitoria pode se sentir desconfortável no exercício de algumas habilidades que compõem a competência criatividade. Para desenvolvê-la, podemos incentivar Vitoria a experimentar atividades que estimulem o exercício de habilidades associadas."
        ],
        "support": "flexibilidade. ",
        "encourage": "execução; curiosidade. ",
        "potentials": "ter inventividade; ter espontaneidade; empenhar-se em busca dos objetivos; ter abertismo; ter versatilidade; ter adaptabilidade. "
      },
      {
        "dimension": "SELFMANAGEMENT_AND_LEADERSHIP",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Vitoria aplica a competência autogestão e liderança em alguns contextos e em outros não. Para desenvolvê-la, um bom exercício é Vitoria buscar novos desafios onde possa aplicar e desenvolver as habilidades relacionadas."
        ],
        "support": "iniciativa; autonomia. ",
        "encourage": "busca de informação. ",
        "potentials": "ter carisma; olhar os outros com bons olhos; saber compartilhar; ser favorecedor do trabalho cooperativo; senso crítico; ter flexibilidade. "
      },
      {
        "dimension": "PROBLEM_SOLVING",
        "color": "Yellow",
        "descriptions": [
          "Embora Vitoria tenha bom potencial para a competência resolução de problemas, é importante incentivar a prática das habilidades associadas em contextos diversificados."
        ],
        "support": "",
        "encourage": "resiliência. ",
        "potentials": "ter assertividade; ter capacidade mobilizadora; ter capacidade empreendedora; ter capacidade de tomar boas decisões; analisar em profundidade; ter cientificidade; ser comprometido com os resultados; ter criatividade. "
      },
      {
        "dimension": "COMMUNICATION",
        "color": "Green",
        "descriptions": [
          "Vitoria tem bom potencial quanto às habilidades associadas à competência comunicação e pode melhorar seu desempenho na medida em que exercitá-las em diferentes situações de aprendizado."
        ],
        "support": "",
        "encourage": "aprender com feedbacks; autoconfiança. ",
        "potentials": "ter autoaceitação; saber ouvir; ter expressividade; saber compreender o outro; demonstrar maturidade para lidar com críticas; ter calma; ter clareza. "
      },
      {
        "dimension": "COOPERATION",
        "color": "Green",
        "descriptions": [
          "Vitoria já aplica as habilidades relacionadas à competência cooperação e pode melhorar seu desempenho em outras ou situações sempre que se sentir motivado à exercitá-las"
        ],
        "support": "",
        "encourage": "consciência socioambiental. ",
        "potentials": "olhar os outros com bons olhos; ter coleguismo; seguir as regras; ter empatia; ter altruísmo; ser cordial; valorizar as virtudes; senso de organização. "
      }
    ]
  },
  {
    id: "61422bef818c3a0079bd1b15",
    "user": {
      "name": "Vitoria Neri de Souza Magalhães ",
      "email": "vvitorianeri@gmail.com"
    },
    "student": {
      "name": "Vitoria Neri de Souza Magalhães ",
      "birthdate": "2003-04-04"
    },
    "processDateTime": "2021-09-15T14:22:55.494",
    "socioemotionalResults": [
      {
        "dimension": "CRITICAL_THINKING",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Vitoria pode se sentir desconfortável no exercício de algumas habilidades que compõem a competência pensamento crítico. Para desenvolvê-la, podemos incentivar Vitoria a experimentar atividades que estimulem o exercício de habilidades associadas."
        ],
        "support": "racionalidade. ",
        "encourage": "cientificidade; lógica; interpretação de dados; comunicação digital; responsabilidade. ",
        "potentials": "ter análise crítica; ter visão de conjunto ; ter autocrítica. "
      },
      {
        "dimension": "SELFMANAGEMENT_AND_LEADERSHIP",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Vitoria aplica a competência autogestão e liderança em alguns contextos e em outros não. Para desenvolvê-la, um bom exercício é Vitoria buscar novos desafios onde possa aplicar e desenvolver as habilidades relacionadas."
        ],
        "support": "autonomia. ",
        "encourage": "diversidade cultural; saber compartilhar; busca de informação; tomada de decisões. ",
        "potentials": "ter carisma; olhar os outros com bons olhos; ser favorecedor do trabalho cooperativo; ter flexibilidade. "
      },
      {
        "dimension": "SELFCONFIDENCE",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Vitoria pode se sentir desconfortável no exercício de algumas habilidades que compõem a competência autoconfiança. Para desenvolvê-la, podemos incentivar Vitoria a experimentar atividades que estimulem o exercício de habilidades associadas."
        ],
        "support": "",
        "encourage": "entendimento do outro; perseverança; autoestima; automotivação; autodidatismo. ",
        "potentials": "ter persistência; saber lidar com as frustrações; permitir-se experimentar; ter extroversão. "
      },
      {
        "dimension": "PROBLEM_SOLVING",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Vitoria aplica a competência resolução de problemas em alguns contextos e em outros não. Para desenvolvê-la, um bom exercício é Vitoria buscar novos desafios onde possa aplicar e desenvolver as habilidades relacionadas."
        ],
        "support": "",
        "encourage": "síntese; compreensão do trabalho; resiliência; metacognição. ",
        "potentials": "ter capacidade de tomar boas decisões; ter capacidade mobilizadora; ser comprometido com os resultados; ter criatividade; ter cientificidade. "
      },
      {
        "dimension": "CREATIVITY",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Vitoria pode se sentir desconfortável no exercício de algumas habilidades que compõem a competência criatividade. Para desenvolvê-la, podemos incentivar Vitoria a experimentar atividades que estimulem o exercício de habilidades associadas."
        ],
        "support": "",
        "encourage": "execução; flexibilidade; espontaneidade; curiosidade. ",
        "potentials": "empenhar-se em busca dos objetivos; ter adaptabilidade; ter inventividade; ter abertismo; ter versatilidade. "
      },
      {
        "dimension": "COMMUNICATION",
        "color": "Green",
        "descriptions": [
          "Vitoria tem bom potencial quanto às habilidades associadas à competência comunicação e pode melhorar seu desempenho na medida em que exercitá-las em diferentes situações de aprendizado."
        ],
        "support": "",
        "encourage": "expressão cultural; clareza; autoconfiança. ",
        "potentials": "saber compreender o outro; demonstrar maturidade para lidar com críticas; ter calma; saber lidar com feedbacks; saber ouvir; ter autoaceitação. "
      },
      {
        "dimension": "COOPERATION",
        "color": "Green",
        "descriptions": [
          "Vitoria já aplica as habilidades relacionadas à competência cooperação e pode melhorar seu desempenho em outras ou situações sempre que se sentir motivado à exercitá-las"
        ],
        "support": "",
        "encourage": "colaboração; consciência socioambiental. ",
        "potentials": "senso de organização; seguir as regras; ter empatia; olhar os outros com bons olhos; ter coleguismo; valorizar as virtudes; ser cordial. "
      }
    ]
  },
  {
    id: "6148d40fad8b4700791e47f6",
    "user": {
      "name": "Thaynara Martins Sales ",
      "email": "Thaynara@gmail.com.br",
    },
    "student": {
      "name": "Thaynara Martins Sales ",
      "birthdate": "2004-05-01"
    },
    "processDateTime": "2021-09-20T15:33:51.518",
    "socioemotionalResults": [
      {
        "dimension": "COMMUNICATION",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Thaynara pode se sentir desconfortvel no exerccio de algumas habilidades que compem a competncia comunicao. Para desenvolv-la, podemos incentivar Thaynara a experimentar atividades que estimulem o exerccio de habilidades associadas."
        ],
        "support": "espontaneidade. ",
        "encourage": "autoaceitação; aprender com feedbacks; autoconfiança; autocrítica. ",
        "potentials": "ter clareza; ter calma; saber ouvir; saber compreender o outro. "
      },
      {
        "dimension": "SELFCONFIDENCE",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Thaynara aplica a competncia autoconfiana em alguns contextos e em outros no. Para desenvolv-la, um bom exerccio  Thaynara buscar novos desafios onde possa aplicar e desenvolver as habilidades relacionadas."
        ],
        "support": "",
        "encourage": "expressão; autodidatismo; autovalorização; perseverança; autoexperimentação. ",
        "potentials": "ter persistência; saber lidar com conflitos; ter equilíbrio interno; ter facilidade para autossuperação. "
      },
      {
        "dimension": "SELFMANAGEMENT_AND_LEADERSHIP",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Thaynara pode se sentir desconfortvel no exerccio de algumas habilidades que compem a competncia autogesto e liderana. Para desenvolv-la, podemos incentivar Thaynara a experimentar atividades que estimulem o exerccio de habilidades associadas."
        ],
        "support": "valorização dos outros. ",
        "encourage": "busca de informação; liderança. ",
        "potentials": "ser favorecedor do trabalho cooperativo; ter autonomia; senso crítico; ter flexibilidade; ter iniciativa; saber compartilhar. "
      },
      {
        "dimension": "CREATIVITY",
        "color": "Green",
        "descriptions": [
          "Thaynara tem bom potencial quanto s habilidades associadas  competncia criatividade e pode melhorar seu desempenho na medida em que exercit-las em diferentes situaes de aprendizado."
        ],
        "support": "",
        "encourage": "adaptabilidade; curiosidade; espontaneidade. ",
        "potentials": "empenhar-se em busca dos objetivos; ter inventividade; ter persistência; ter abertismo; ter versatilidade; ter flexibilidade. "
      },
      {
        "dimension": "COOPERATION",
        "color": "Green",
        "descriptions": [
          "Thaynara j aplica as habilidades relacionadas  competncia cooperao e pode melhorar seu desempenho em outras ou situaes sempre que se sentir motivado  exercit-las"
        ],
        "support": "otimismo quanto às pessoas. ",
        "encourage": "acolhimento; consciência socioambiental. ",
        "potentials": "ser cordial; senso de organização; valorizar as virtudes; seguir as regras; ter altruísmo; ter coleguismo. "
      }
    ]
  },
  {
    id: "6148e254ad8b4700791e47f9",
    "user": {
      "name": "Thaynara Martins Sales",
      "email": "thaynaram348@gmail.com"
    },
    "student": {
      "name": "Thaynara Martins Sales",
      "birthdate": "2004-05-01"
    },
    "processDateTime": "2021-09-20T16:34:44.622",
    "socioemotionalResults": [
      {
        "dimension": "SELFMANAGEMENT_AND_LEADERSHIP",
        "color": "Red",
        "descriptions": [
          "Avaliar a hiptese de existirem fatores emocionais e ou cognitivos dificultadores do desenvolvimento da competncia autogesto e liderana, no caso de Thaynara. Alm disto,  importante acompanhar de perto e considerar o apoio especializado de profissionais aptos  diagnosticarem melhor as possveis causas."
        ],
        "support": "trabalho cooperativo; valorização dos outros. ",
        "encourage": "respeito; busca de informação; liderança; tomada de decisões. ",
        "potentials": "ter autonomia; saber compartilhar; senso crítico. "
      },
      {
        "dimension": "SELFCONFIDENCE",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Thaynara aplica a competncia autoconfiana em alguns contextos e em outros no. Para desenvolv-la, um bom exerccio  Thaynara buscar novos desafios onde possa aplicar e desenvolver as habilidades relacionadas."
        ],
        "support": "",
        "encourage": "autovalorização; automotivação; perseverança; autoexperimentação; autoestima; expressão; autodidatismo; entendimento do outro. ",
        "potentials": "ter persistência. "
      },
      {
        "dimension": "COMMUNICATION",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Thaynara pode se sentir desconfortvel no exerccio de algumas habilidades que compem a competncia comunicao. Para desenvolv-la, podemos incentivar Thaynara a experimentar atividades que estimulem o exerccio de habilidades associadas."
        ],
        "support": "espontaneidade. ",
        "encourage": "autocrítica; autoconfiança; aprender com feedbacks; escuta; clareza; autoaceitação. ",
        "potentials": "saber compreender o outro; ter calma. "
      },
      {
        "dimension": "CREATIVITY",
        "color": "Green",
        "descriptions": [
          "Thaynara tem bom potencial quanto s habilidades associadas  competncia criatividade e pode melhorar seu desempenho na medida em que exercit-las em diferentes situaes de aprendizado."
        ],
        "support": "espontaneidade. ",
        "encourage": "execução; adaptabilidade; curiosidade; flexibilidade. ",
        "potentials": "empenhar-se em busca dos objetivos; ter inventividade; ter versatilidade; ter abertismo. "
      },
      {
        "dimension": "COOPERATION",
        "color": "Green",
        "descriptions": [
          "Thaynara j aplica as habilidades relacionadas  competncia cooperao e pode melhorar seu desempenho em outras ou situaes sempre que se sentir motivado  exercit-las"
        ],
        "support": "otimismo quanto às pessoas; empatia. ",
        "encourage": "consciência socioambiental. ",
        "potentials": "ter coleguismo; ser cordial; senso de organização; ter altruísmo; valorizar as virtudes; seguir as regras. "
      }
    ]
  },
  {
    id: "6142983b818c3a0079bd1b25",
    "user": {
      "name": "Thatielle Cristine da Silva Nascimento ",
      "email": "thatiellecristine182@gmail.com"
    },
    "student": {
      "name": "Thatielle Cristine da Silva Nascimento ",
      "birthdate": "2005-05-06"
    },
    "processDateTime": "2021-09-15T22:04:59.317",
    "socioemotionalResults": [
      {
        "dimension": "COMMUNICATION",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Thatielle pode se sentir desconfortável no exercício de algumas habilidades que compõem a competência comunicação. Para desenvolvê-la, podemos incentivar Thatielle a experimentar atividades que estimulem o exercício de habilidades associadas."
        ],
        "support": "desinibição. ",
        "encourage": "aprender com feedbacks; autoaceitação; autocrítica; reflexão. ",
        "potentials": "saber compreender o outro; saber ouvir; ter expressividade; ter clareza. "
      },
      {
        "dimension": "SELFMANAGEMENT_AND_LEADERSHIP",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Thatielle aplica a competência autogestão e liderança em alguns contextos e em outros não. Para desenvolvê-la, um bom exercício é Thatielle buscar novos desafios onde possa aplicar e desenvolver as habilidades relacionadas."
        ],
        "support": "empoderamento. ",
        "encourage": "respeito; liderança. ",
        "potentials": "ter iniciativa; ser favorecedor do trabalho cooperativo; senso crítico; ter autonomia; saber compartilhar; olhar os outros com bons olhos. "
      },
      {
        "dimension": "COOPERATION",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Thatielle pode se sentir desconfortável no exercício de algumas habilidades que compõem a competência cooperação. Para desenvolvê-la, podemos incentivar Thatielle a experimentar atividades que estimulem o exercício de habilidades associadas."
        ],
        "support": "sociabilidade. ",
        "encourage": "",
        "potentials": "valorizar as virtudes; seguir as regras; senso de organização; ter empatia; ter coleguismo; ser cordial; ter altruísmo; olhar os outros com bons olhos. "
      },
      {
        "dimension": "CREATIVITY",
        "color": "Green",
        "descriptions": [
          "Thatielle tem bom potencial quanto às habilidades associadas à competência criatividade e pode melhorar seu desempenho na medida em que exercitá-las em diferentes situações de aprendizado."
        ],
        "support": "curiosidade. ",
        "encourage": "inventividade; adaptabilidade. ",
        "potentials": "ter versatilidade; empenhar-se em busca dos objetivos; ter persistência; ter espontaneidade; ter abertismo; ter flexibilidade. "
      },
      {
        "dimension": "SELFCONFIDENCE",
        "color": "Green",
        "descriptions": [
          "Thatielle já aplica as habilidades relacionadas à competência autoconfiança e pode melhorar seu desempenho em outras ou situações sempre que se sentir motivado à exercitá-las"
        ],
        "support": "coragem criativa. ",
        "encourage": "autoestima. ",
        "potentials": "saber lidar com a adversidade; saber lidar com conflitos; ter extroversão; ter persistência; permitir-se experimentar; saber lidar com as frustrações; ter facilidade para autossuperação. "
      }
    ]
  },
  {
    id: "614259b9818c3a0079bd1b1f",
    "user": {
      "name": "Rangel de Jesus Sousa",
      "email": "rangelsousa951@gmail.com"
    },
    "student": {
      "name": "Rangel de Jesus Sousa",
      "birthdate": "2003-03-22"
    },
    "processDateTime": "2021-09-15T17:38:17.796",
    "socioemotionalResults": [
      {
        "dimension": "SELFCONFIDENCE",
        "color": "Red",
        "descriptions": [
          "Avaliar a hipótese de existirem fatores emocionais e ou cognitivos dificultadores do desenvolvimento da competência autoconfiança, no caso de Rangel. Além disto, é importante acompanhar de perto e considerar o apoio especializado de profissionais aptos à diagnosticarem melhor as possíveis causas."
        ],
        "support": "equilíbrio interno; coragem criativa. ",
        "encourage": "expressão; autovalorização; automotivação; autoexperimentação. ",
        "potentials": "ter persistência; saber lidar com conflitos; saber lidar com a adversidade. "
      },
      {
        "dimension": "SELFMANAGEMENT_AND_LEADERSHIP",
        "color": "Red",
        "descriptions": [
          "Avaliar a hipótese de existirem fatores emocionais e ou cognitivos dificultadores do desenvolvimento da competência autogestão e liderança, no caso de Rangel. Além disto, é importante acompanhar de perto e considerar o apoio especializado de profissionais aptos à diagnosticarem melhor as possíveis causas."
        ],
        "support": "trabalho cooperativo; empoderamento. ",
        "encourage": "autoconsciência; saber compartilhar; alteridade; tomada de decisões; liderança. ",
        "potentials": "senso crítico; ter flexibilidade. "
      },
      {
        "dimension": "CREATIVITY",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Rangel pode se sentir desconfortável no exercício de algumas habilidades que compõem a competência criatividade. Para desenvolvê-la, podemos incentivar Rangel a experimentar atividades que estimulem o exercício de habilidades associadas."
        ],
        "support": "curiosidade. ",
        "encourage": "flexibilidade; inventividade; espontaneidade; exploração de ideias; execução; adaptabilidade. ",
        "potentials": "empenhar-se em busca dos objetivos; ter versatilidade. "
      },
      {
        "dimension": "COMMUNICATION",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Rangel aplica a competência comunicação em alguns contextos e em outros não. Para desenvolvê-la, um bom exercício é Rangel buscar novos desafios onde possa aplicar e desenvolver as habilidades relacionadas."
        ],
        "support": "espontaneidade; desinibição. ",
        "encourage": "reflexão; clareza; escuta. ",
        "potentials": "saber lidar com feedbacks; saber compreender o outro; demonstrar maturidade para lidar com críticas; ter autoaceitação. "
      },
      {
        "dimension": "PROBLEM_SOLVING",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Rangel pode se sentir desconfortável no exercício de algumas habilidades que compõem a competência resolução de problemas. Para desenvolvê-la, podemos incentivar Rangel a experimentar atividades que estimulem o exercício de habilidades associadas."
        ],
        "support": "resiliência. ",
        "encourage": "síntese; metacognição; compreensão do trabalho. ",
        "potentials": "ter cientificidade; ser comprometido com os resultados; ter capacidade de tomar boas decisões; ter criatividade; ter capacidade mobilizadora. "
      },
      {
        "dimension": "COOPERATION",
        "color": "Green",
        "descriptions": [
          "Rangel tem bom potencial quanto às habilidades associadas à competência cooperação e pode melhorar seu desempenho na medida em que exercitá-las em diferentes situações de aprendizado."
        ],
        "support": "sociabilidade. ",
        "encourage": "ética; colaboração; valorização da diversidade. ",
        "potentials": "seguir as regras; senso de organização; ser cordial; ter coleguismo; ter empatia. "
      },
      {
        "dimension": "CRITICAL_THINKING",
        "color": "Green",
        "descriptions": [
          "Rangel já aplica as habilidades relacionadas à competência pensamento crítico e pode melhorar seu desempenho em outras ou situações sempre que se sentir motivado à exercitá-las"
        ],
        "support": "persistência. ",
        "encourage": "análise crítica; cientificidade. ",
        "potentials": "primar pela credibilidade; valoriza a racionalidade; boa comunicabilidade; ter visão de conjunto ; ter autocrítica; ter clareza. "
      }
    ]
  },
  {
    id: "6142b026818c3a0079bd1b28",
    "user": {
      "name": "Priscila de Jesus Maximiano",
      "email": "priscilamaximiano24@hotmail.com"
    },
    "student": {
      "name": "Letícia Maximiano de Santana",
      "birthdate": "2004-06-06"
    },
    "processDateTime": "2021-09-15T23:47:02.233",
    "socioemotionalResults": [
      {
        "dimension": "SELFCONFIDENCE",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente LetÃ­cia pode se sentir desconfortável no exercício de algumas habilidades que compõem a competência autoconfiança. Para desenvolvê-la, podemos incentivar LetÃ­cia a experimentar atividades que estimulem o exercício de habilidades associadas."
        ],
        "support": "",
        "encourage": "entendimento do outro; perseverança; esforço; expressão; autoexperimentação; autoestima; automotivação. ",
        "potentials": "saber lidar com as frustrações; apreciar desafios para o aprendizado. "
      },
      {
        "dimension": "CREATIVITY",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente LetÃ­cia aplica a competência criatividade em alguns contextos e em outros não. Para desenvolvê-la, um bom exercício é LetÃ­cia buscar novos desafios onde possa aplicar e desenvolver as habilidades relacionadas."
        ],
        "support": "",
        "encourage": "determinação; execução; soluções; espontaneidade; flexibilidade. ",
        "potentials": "ter abertismo; ter inventividade; gostar do novo; ter adaptabilidade. "
      },
      {
        "dimension": "SELFMANAGEMENT_AND_LEADERSHIP",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente LetÃ­cia pode se sentir desconfortável no exercício de algumas habilidades que compõem a competência autogestão e liderança. Para desenvolvê-la, podemos incentivar LetÃ­cia a experimentar atividades que estimulem o exercício de habilidades associadas."
        ],
        "support": "trabalho cooperativo. ",
        "encourage": "tomada de decisões; respeito. ",
        "potentials": "ter autonomia; ter carisma; olhar os outros com bons olhos; senso crítico; saber compartilhar; ter proatividade. "
      },
      {
        "dimension": "COMMUNICATION",
        "color": "Green",
        "descriptions": [
          "LetÃ­cia tem bom potencial quanto às habilidades associadas à competência comunicação e pode melhorar seu desempenho na medida em que exercitá-las em diferentes situações de aprendizado."
        ],
        "support": "",
        "encourage": "expressão cultural; escuta; clareza. ",
        "potentials": "ter calma; saber lidar com feedbacks; saber compreender o outro; ter autoaceitação; demonstrar maturidade para lidar com críticas; ter extroversão. "
      },
      {
        "dimension": "COOPERATION",
        "color": "Green",
        "descriptions": [
          "LetÃ­cia já aplica as habilidades relacionadas à competência cooperação e pode melhorar seu desempenho em outras ou situações sempre que se sentir motivado à exercitá-las"
        ],
        "support": "",
        "encourage": "",
        "potentials": "senso de organização; ser cordial; seguir as regras; ter coleguismo; olhar os outros com bons olhos; ter empatia; ter altruísmo; ter sociabilidade; valorizar as virtudes. "
      }
    ]
  },
  {
    id: "614dd1e5ad8b4700791e4802",
    "user": {
      "name": "Pedro Henrique Silva de Oliveira",
      "email": "computadorcomsenhagui@gmail.com"
    },
    "student": {
      "name": "Pedro Henrique Silva de Oliveira",
      "birthdate": "2005-02-15"
    },
    "processDateTime": "2021-09-24T10:25:57.546",
    "socioemotionalResults": [
      {
        "dimension": "CREATIVITY",
        "color": "Red",
        "descriptions": [
          "Avaliar a hiptese de existirem fatores emocionais e ou cognitivos dificultadores do desenvolvimento da competncia criatividade, no caso de Pedro. Alm disto,  importante acompanhar de perto e considerar o apoio especializado de profissionais aptos  diagnosticarem melhor as possveis causas."
        ],
        "support": "espontaneidade; flexibilidade; inventividade. ",
        "encourage": "soluções; adaptabilidade; determinação. ",
        "potentials": "ter persistência; gostar do novo; ter abertismo. "
      },
      {
        "dimension": "SELFMANAGEMENT_AND_LEADERSHIP",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Pedro aplica a competncia autogesto e liderana em alguns contextos e em outros no. Para desenvolv-la, um bom exerccio  Pedro buscar novos desafios onde possa aplicar e desenvolver as habilidades relacionadas."
        ],
        "support": "companheirismo; valorização dos outros; iniciativa. ",
        "encourage": "autoconsciência; autoeficácia. ",
        "potentials": "ter proatividade; saber compartilhar; ter flexibilidade; senso crítico. "
      },
      {
        "dimension": "COMMUNICATION",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Pedro pode se sentir desconfortvel no exerccio de algumas habilidades que compem a competncia comunicao. Para desenvolv-la, podemos incentivar Pedro a experimentar atividades que estimulem o exerccio de habilidades associadas."
        ],
        "support": "espontaneidade; autocrítica; interesse no ouvinte. ",
        "encourage": "reflexão. ",
        "potentials": "ter autoaceitação; ter extroversão; saber ouvir; demonstrar maturidade para lidar com críticas; saber compreender o outro. "
      },
      {
        "dimension": "COOPERATION",
        "color": "Green",
        "descriptions": [
          "Pedro tem bom potencial quanto s habilidades associadas  competncia cooperao e pode melhorar seu desempenho na medida em que exercit-las em diferentes situaes de aprendizado."
        ],
        "support": "otimismo quanto às pessoas; coleguismo. ",
        "encourage": "acolhimento. ",
        "potentials": "senso de organização; seguir as regras; ter sociabilidade; ter altruísmo; valorizar as virtudes; ser cordial. "
      },
      {
        "dimension": "SELFCONFIDENCE",
        "color": "Green",
        "descriptions": [
          "Pedro j aplica as habilidades relacionadas  competncia autoconfiana e pode melhorar seu desempenho em outras ou situaes sempre que se sentir motivado  exercit-las"
        ],
        "support": "",
        "encourage": "esforço. ",
        "potentials": "ter facilidade para autossuperação; apreciar desafios para o aprendizado; saber lidar com conflitos; ter extroversão; saber lidar com as frustrações; saber lidar com a adversidade; permitir-se experimentar; ter equilíbrio interno. "
      }
    ]
  },
  {
    id: '614dd232ad8b4700791e4803',
    "user": {
      "name": "Nicolly de Lana Santos ",
      "email": "nicollylnsantos@gmail.com"
    },
    "student": {
      "name": "Nicolly de Lana Santos ",
      "birthdate": "2002-06-28"
    },
    "processDateTime": "2021-09-24T10:27:14.573",
    "socioemotionalResults": [
      {
        "dimension": "SELFCONFIDENCE",
        "color": "Red",
        "descriptions": [
          "Avaliar a hiptese de existirem fatores emocionais e ou cognitivos dificultadores do desenvolvimento da competncia autoconfiana, no caso de Nicolly. Alm disto,  importante acompanhar de perto e considerar o apoio especializado de profissionais aptos  diagnosticarem melhor as possveis causas."
        ],
        "support": "coragem criativa; desdramatização do erro; desinibição. ",
        "encourage": "perseverança; autovalorização; autoestima; entendimento do outro. ",
        "potentials": "ter facilidade para autossuperação; ter persistência. "
      },
      {
        "dimension": "CREATIVITY",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Nicolly aplica a competncia criatividade em alguns contextos e em outros no. Para desenvolv-la, um bom exerccio  Nicolly buscar novos desafios onde possa aplicar e desenvolver as habilidades relacionadas."
        ],
        "support": "flexibilidade; curiosidade. ",
        "encourage": "espontaneidade; adaptabilidade. ",
        "potentials": "ter inventividade; ter persistência; ter versatilidade; empenhar-se em busca dos objetivos; ter abertismo. "
      },
      {
        "dimension": "SELFMANAGEMENT_AND_LEADERSHIP",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Nicolly pode se sentir desconfortvel no exerccio de algumas habilidades que compem a competncia autogesto e liderana. Para desenvolv-la, podemos incentivar Nicolly a experimentar atividades que estimulem o exerccio de habilidades associadas."
        ],
        "support": "empoderamento; trabalho cooperativo. ",
        "encourage": "diversidade cultural; autoconsciência. ",
        "potentials": "ter flexibilidade; ter carisma; saber compartilhar; ter iniciativa; olhar os outros com bons olhos. "
      },
      {
        "dimension": "COMMUNICATION",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Nicolly aplica a competncia comunicao em alguns contextos e em outros no. Para desenvolv-la, um bom exerccio  Nicolly buscar novos desafios onde possa aplicar e desenvolver as habilidades relacionadas."
        ],
        "support": "desinibição; reflexão sobre as emoções. ",
        "encourage": "autocrítica; autoaceitação. ",
        "potentials": "saber lidar com feedbacks; ter expressividade; saber ouvir; saber compreender o outro; ter clareza. "
      },
      {
        "dimension": "PROBLEM_SOLVING",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Nicolly pode se sentir desconfortvel no exerccio de algumas habilidades que compem a competncia resoluo de problemas. Para desenvolv-la, podemos incentivar Nicolly a experimentar atividades que estimulem o exerccio de habilidades associadas."
        ],
        "support": "resiliência. ",
        "encourage": "síntese; compreensão do trabalho; metacognição. ",
        "potentials": "ter criatividade; ter capacidade de tomar boas decisões; ser comprometido com os resultados; ter capacidade mobilizadora; ter cientificidade. "
      },
      {
        "dimension": "COOPERATION",
        "color": "Green",
        "descriptions": [
          "Nicolly tem bom potencial quanto s habilidades associadas  competncia cooperao e pode melhorar seu desempenho na medida em que exercit-las em diferentes situaes de aprendizado."
        ],
        "support": "sociabilidade. ",
        "encourage": "ética. ",
        "potentials": "ter empatia; senso de organização; ter altruísmo; olhar os outros com bons olhos; seguir as regras; ter coleguismo; ser cordial. "
      },
      {
        "dimension": "CRITICAL_THINKING",
        "color": "Green",
        "descriptions": [
          "Nicolly j aplica as habilidades relacionadas  competncia pensamento crtico e pode melhorar seu desempenho em outras ou situaes sempre que se sentir motivado  exercit-las"
        ],
        "support": "persistência. ",
        "encourage": "",
        "potentials": "ter visão de conjunto ; boa comunicabilidade; ter análise crítica; valoriza a racionalidade; primar pela credibilidade; ter isenção; ter clareza; ter autocrítica. "
      }
    ]
  },
  {
    id: "614250fe818c3a0079bd1b1b",
    "user": {
      "name": "Nicolly de Lana Santos ",
      "email": "nicollylnsantos@gmail.com"
    },
    "student": {
      "name": "Nicolly de Lana Santos ",
      "birthdate": "2002-06-28"
    },
    "processDateTime": "2021-09-15T17:01:02.086",
    "socioemotionalResults": [
      {
        "dimension": "SELFCONFIDENCE",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Nicolly pode se sentir desconfortável no exercício de algumas habilidades que compõem a competência autoconfiança. Para desenvolvê-la, podemos incentivar Nicolly a experimentar atividades que estimulem o exercício de habilidades associadas."
        ],
        "support": "equilíbrio interno; desdramatização do erro. ",
        "encourage": "expressão; perseverança; autovalorização. ",
        "potentials": "ter facilidade para autossuperação; saber lidar com conflitos; ter persistência; apreciar desafios para o aprendizado. "
      },
      {
        "dimension": "COMMUNICATION",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Nicolly aplica a competência comunicação em alguns contextos e em outros não. Para desenvolvê-la, um bom exercício é Nicolly buscar novos desafios onde possa aplicar e desenvolver as habilidades relacionadas."
        ],
        "support": "espontaneidade. ",
        "encourage": "reflexão; autocrítica; autoaceitação. ",
        "potentials": "ter clareza; saber lidar com feedbacks; ter extroversão; saber compreender o outro; saber ouvir. "
      },
      {
        "dimension": "CREATIVITY",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Nicolly pode se sentir desconfortável no exercício de algumas habilidades que compõem a competência criatividade. Para desenvolvê-la, podemos incentivar Nicolly a experimentar atividades que estimulem o exercício de habilidades associadas."
        ],
        "support": "flexibilidade. ",
        "encourage": "espontaneidade. ",
        "potentials": "empenhar-se em busca dos objetivos; ter persistência; ter abertismo; gostar do novo; ter inventividade; ter versatilidade; ter adaptabilidade. "
      },
      {
        "dimension": "SELFMANAGEMENT_AND_LEADERSHIP",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Nicolly aplica a competência autogestão e liderança em alguns contextos e em outros não. Para desenvolvê-la, um bom exercício é Nicolly buscar novos desafios onde possa aplicar e desenvolver as habilidades relacionadas."
        ],
        "support": "",
        "encourage": "diversidade cultural. ",
        "potentials": "ser favorecedor do trabalho cooperativo; saber compartilhar; ter autonomia; ter proatividade; ter iniciativa; olhar os outros com bons olhos; ter carisma; ter flexibilidade. "
      },
      {
        "dimension": "PROBLEM_SOLVING",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Nicolly pode se sentir desconfortável no exercício de algumas habilidades que compõem a competência resolução de problemas. Para desenvolvê-la, podemos incentivar Nicolly a experimentar atividades que estimulem o exercício de habilidades associadas."
        ],
        "support": "",
        "encourage": "",
        "potentials": "ter assertividade; ter capacidade empreendedora; ser comprometido com os resultados; ter capacidade mobilizadora; ter resiliência; ter cientificidade; ter criatividade; ter capacidade de tomar boas decisões; analisar em profundidade. "
      },
      {
        "dimension": "COOPERATION",
        "color": "Green",
        "descriptions": [
          "Nicolly tem bom potencial quanto às habilidades associadas à competência cooperação e pode melhorar seu desempenho na medida em que exercitá-las em diferentes situações de aprendizado."
        ],
        "support": "",
        "encourage": "ética. ",
        "potentials": "ter altruísmo; ter sociabilidade; ser cordial; senso de organização; olhar os outros com bons olhos; seguir as regras; ter coleguismo; ter empatia. "
      },
      {
        "dimension": "CRITICAL_THINKING",
        "color": "Green",
        "descriptions": [
          "Nicolly já aplica as habilidades relacionadas à competência pensamento crítico e pode melhorar seu desempenho em outras ou situações sempre que se sentir motivado à exercitá-las"
        ],
        "support": "",
        "encourage": "",
        "potentials": "boa comunicabilidade; primar pela credibilidade; ter análise crítica; ter autocrítica; ter persistência; ter clareza; valoriza a racionalidade; ter isenção; ter visão de conjunto . "
      }
    ]
  },
  {
    id: "61422d14818c3a0079bd1b16",
    "user": {
      "name": "Leandro Henrique Oliveira da Silva",
      "email": "leandrohenrique05022005@gmail.com"
    },
    "student": {
      "name": "Leandro Henrique Oliveira da Silva",
      "birthdate": "2005-02-05"
    },
    "processDateTime": "2021-09-15T14:27:48.336",
    "socioemotionalResults": [
      {
        "dimension": "SELFCONFIDENCE",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Leandro pode se sentir desconfortável no exercício de algumas habilidades que compõem a competência autoconfiança. Para desenvolvê-la, podemos incentivar Leandro a experimentar atividades que estimulem o exercício de habilidades associadas."
        ],
        "support": "desdramatização do erro. ",
        "encourage": "autodidatismo; expressão; autovalorização; perseverança. ",
        "potentials": "ter equilíbrio interno; saber lidar com conflitos; ter facilidade para autossuperação; ter persistência. "
      },
      {
        "dimension": "COMMUNICATION",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Leandro aplica a competência comunicação em alguns contextos e em outros não. Para desenvolvê-la, um bom exercício é Leandro buscar novos desafios onde possa aplicar e desenvolver as habilidades relacionadas."
        ],
        "support": "espontaneidade. ",
        "encourage": "autoconfiança; clareza. ",
        "potentials": "ter autoaceitação; demonstrar maturidade para lidar com críticas; saber ouvir; saber lidar com feedbacks; ter calma; saber compreender o outro. "
      },
      {
        "dimension": "SELFMANAGEMENT_AND_LEADERSHIP",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Leandro pode se sentir desconfortável no exercício de algumas habilidades que compõem a competência autogestão e liderança. Para desenvolvê-la, podemos incentivar Leandro a experimentar atividades que estimulem o exercício de habilidades associadas."
        ],
        "support": "trabalho cooperativo. ",
        "encourage": "busca de informação; liderança. ",
        "potentials": "ter autonomia; ter flexibilidade; saber compartilhar; ter iniciativa; olhar os outros com bons olhos; senso crítico. "
      },
      {
        "dimension": "CREATIVITY",
        "color": "Green",
        "descriptions": [
          "Leandro tem bom potencial quanto às habilidades associadas à competência criatividade e pode melhorar seu desempenho na medida em que exercitá-las em diferentes situações de aprendizado."
        ],
        "support": "",
        "encourage": "curiosidade; flexibilidade. ",
        "potentials": "ter adaptabilidade; ter espontaneidade; ter versatilidade; ter inventividade; ter abertismo; ter persistência; empenhar-se em busca dos objetivos. "
      },
      {
        "dimension": "COOPERATION",
        "color": "Green",
        "descriptions": [
          "Leandro já aplica as habilidades relacionadas à competência cooperação e pode melhorar seu desempenho em outras ou situações sempre que se sentir motivado à exercitá-las"
        ],
        "support": "",
        "encourage": "consciência socioambiental. ",
        "potentials": "ter altruísmo; olhar os outros com bons olhos; ter empatia; ser cordial; ter coleguismo; valorizar as virtudes; senso de organização; seguir as regras. "
      }
    ]
  },
  {
    id: "6143b9acad8b4700791e47e2", 
    "user": {
      "name": "Larissa Ia Habib da Silva Amaral Santos ",
      "email": "larissa2012amaral@gmail.com"
    },
    "student": {
      "name": "Larissa Ia Habib da Silva Amaral Santos ",
      "birthdate": "2003-12-20"
    },
    "processDateTime": "2021-09-16T18:39:56.494",
    "socioemotionalResults": [
      {
        "dimension": "SELFCONFIDENCE",
        "color": "Red",
        "descriptions": [
          "Avaliar a hiptese de existirem fatores emocionais e ou cognitivos dificultadores do desenvolvimento da competncia autoconfiana, no caso de Larissa. Alm disto,  importante acompanhar de perto e considerar o apoio especializado de profissionais aptos  diagnosticarem melhor as possveis causas."
        ],
        "support": "desdramatização do erro; desinibição. ",
        "encourage": "autodidatismo; perseverança; autovalorização; automotivação; entendimento do outro. ",
        "potentials": "ter equilíbrio interno; ter persistência. "
      },
      {
        "dimension": "CREATIVITY",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Larissa aplica a competncia criatividade em alguns contextos e em outros no. Para desenvolv-la, um bom exerccio  Larissa buscar novos desafios onde possa aplicar e desenvolver as habilidades relacionadas."
        ],
        "support": "flexibilidade. ",
        "encourage": "soluções; curiosidade; execução; espontaneidade. ",
        "potentials": "ter inventividade; empenhar-se em busca dos objetivos; ter abertismo; ter adaptabilidade. "
      },
      {
        "dimension": "COMMUNICATION",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Larissa pode se sentir desconfortvel no exerccio de algumas habilidades que compem a competncia comunicao. Para desenvolv-la, podemos incentivar Larissa a experimentar atividades que estimulem o exerccio de habilidades associadas."
        ],
        "support": "espontaneidade. ",
        "encourage": "autoaceitação; autoconfiança; clareza. ",
        "potentials": "saber lidar com feedbacks; demonstrar maturidade para lidar com críticas; saber compreender o outro; saber ouvir; ter calma. "
      },
      {
        "dimension": "SELFMANAGEMENT_AND_LEADERSHIP",
        "color": "Green",
        "descriptions": [
          "Larissa tem bom potencial quanto s habilidades associadas  competncia autogesto e liderana e pode melhorar seu desempenho na medida em que exercit-las em diferentes situaes de aprendizado."
        ],
        "support": "",
        "encourage": "busca de informação; autoeficácia; liderança; saber compartilhar. ",
        "potentials": "ter autonomia; ter iniciativa; senso crítico; olhar os outros com bons olhos; ter flexibilidade. "
      },
      {
        "dimension": "COOPERATION",
        "color": "Green",
        "descriptions": [
          "Larissa j aplica as habilidades relacionadas  competncia cooperao e pode melhorar seu desempenho em outras ou situaes sempre que se sentir motivado  exercit-las"
        ],
        "support": "",
        "encourage": "consciência socioambiental; colaboração. ",
        "potentials": "ter coleguismo; valorizar as virtudes; seguir as regras; senso de organização; ter empatia; olhar os outros com bons olhos; ser cordial. "
      }
    ]
  },
  {
    id: "61422627818c3a0079bd1b05",
    "user": {
      "name": "Kayllane ",
      "email": "kayllanes394@gmail.com"
    },
    "student": {
      "name": "Kayllane ",
      "birthdate": "2004-10-24"
    },
    "processDateTime": "2021-09-15T13:58:15.55",
    "socioemotionalResults": [
      {
        "dimension": "COMMUNICATION",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Kayllane pode se sentir desconfortável no exercício de algumas habilidades que compõem a competência comunicação. Para desenvolvê-la, podemos incentivar Kayllane a experimentar atividades que estimulem o exercício de habilidades associadas."
        ],
        "support": "espontaneidade. ",
        "encourage": "clareza; diálogo; autoaceitação. ",
        "potentials": "ter extroversão; demonstrar maturidade para lidar com críticas; saber lidar com feedbacks; ter calma; saber ouvir. "
      },
      {
        "dimension": "SELFMANAGEMENT_AND_LEADERSHIP",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Kayllane aplica a competência autogestão e liderança em alguns contextos e em outros não. Para desenvolvê-la, um bom exercício é Kayllane buscar novos desafios onde possa aplicar e desenvolver as habilidades relacionadas."
        ],
        "support": "",
        "encourage": "tomada de decisões; autoeficácia; liderança; autoconsciência. ",
        "potentials": "ter flexibilidade; ter proatividade; olhar os outros com bons olhos; senso crítico; saber compartilhar. "
      },
      {
        "dimension": "SELFCONFIDENCE",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Kayllane pode se sentir desconfortável no exercício de algumas habilidades que compõem a competência autoconfiança. Para desenvolvê-la, podemos incentivar Kayllane a experimentar atividades que estimulem o exercício de habilidades associadas."
        ],
        "support": "desdramatização do erro. ",
        "encourage": "entendimento do outro; expressão; perseverança. ",
        "potentials": "apreciar desafios para o aprendizado; ter equilíbrio interno; ter persistência; ter facilidade para autossuperação; saber lidar com as frustrações. "
      },
      {
        "dimension": "CREATIVITY",
        "color": "Green",
        "descriptions": [
          "Kayllane tem bom potencial quanto às habilidades associadas à competência criatividade e pode melhorar seu desempenho na medida em que exercitá-las em diferentes situações de aprendizado."
        ],
        "support": "inventividade. ",
        "encourage": "flexibilidade. ",
        "potentials": "ter espontaneidade; gostar do novo; empenhar-se em busca dos objetivos; ter adaptabilidade; ter persistência; ter versatilidade; ter abertismo. "
      },
      {
        "dimension": "COOPERATION",
        "color": "Green",
        "descriptions": [
          "Kayllane já aplica as habilidades relacionadas à competência cooperação e pode melhorar seu desempenho em outras ou situações sempre que se sentir motivado à exercitá-las"
        ],
        "support": "",
        "encourage": "",
        "potentials": "ter sociabilidade; olhar os outros com bons olhos; valorizar as virtudes; ter empatia; ter coleguismo; ter altruísmo; senso de organização; ser cordial; seguir as regras. "
      }
    ]
  },
  {
    id: "61422b7c818c3a0079bd1b14",
    "user": {
      "name": "Kauany dos Santos Caetano ",
      "email": "kauanyscaetano2004@gmail.com"
    },
    "student": {
      "name": "Kauany dos Santos Caetano ",
      "birthdate": "2004-05-28"
    },
    "processDateTime": "2021-09-15T14:21:00.897",
    "socioemotionalResults": [
      {
        "dimension": "COMMUNICATION",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Kauany pode se sentir desconfortável no exercício de algumas habilidades que compõem a competência comunicação. Para desenvolvê-la, podemos incentivar Kauany a experimentar atividades que estimulem o exercício de habilidades associadas."
        ],
        "support": "empatia; interesse no ouvinte. ",
        "encourage": "autocrítica; expressão cultural; autoaceitação; escuta. ",
        "potentials": "saber lidar com feedbacks; ter extroversão; ter calma. "
      },
      {
        "dimension": "CREATIVITY",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Kauany aplica a competência criatividade em alguns contextos e em outros não. Para desenvolvê-la, um bom exercício é Kauany buscar novos desafios onde possa aplicar e desenvolver as habilidades relacionadas."
        ],
        "support": "espontaneidade. ",
        "encourage": "flexibilidade; inventividade; execução. ",
        "potentials": "ter versatilidade; ter abertismo; gostar do novo; empenhar-se em busca dos objetivos; ter adaptabilidade. "
      },
      {
        "dimension": "SELFCONFIDENCE",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Kauany pode se sentir desconfortável no exercício de algumas habilidades que compõem a competência autoconfiança. Para desenvolvê-la, podemos incentivar Kauany a experimentar atividades que estimulem o exercício de habilidades associadas."
        ],
        "support": "anticonflitividade. ",
        "encourage": "autoestima; autoexperimentação; perseverança; expressão. ",
        "potentials": "apreciar desafios para o aprendizado; saber lidar com as frustrações; ter persistência; ter facilidade para autossuperação. "
      },
      {
        "dimension": "COOPERATION",
        "color": "Green",
        "descriptions": [
          "Kauany tem bom potencial quanto às habilidades associadas à competência cooperação e pode melhorar seu desempenho na medida em que exercitá-las em diferentes situações de aprendizado."
        ],
        "support": "coleguismo; senso de organização. ",
        "encourage": "",
        "potentials": "ser cordial; seguir as regras; ter sociabilidade; ter empatia; valorizar as virtudes; olhar os outros com bons olhos; ter altruísmo. "
      },
      {
        "dimension": "SELFMANAGEMENT_AND_LEADERSHIP",
        "color": "Green",
        "descriptions": [
          "Kauany já aplica as habilidades relacionadas à competência autogestão e liderança e pode melhorar seu desempenho em outras ou situações sempre que se sentir motivado à exercitá-las"
        ],
        "support": "autonomia. ",
        "encourage": "",
        "potentials": "ter iniciativa; ter proatividade; ter carisma; ter flexibilidade; ser favorecedor do trabalho cooperativo; olhar os outros com bons olhos; saber compartilhar; senso crítico. "
      }
    ]
  },
  {
    id: "6148e252ad8b4700791e47f8",
    "user": {
      "name": "Karina de Sousa Mendes",
      "email": "kaahsousa08287@gmail.com"
    },
    "student": {
      "name": "Karina de Sousa Mendes",
      "birthdate": "2003-04-12"
    },
    "processDateTime": "2021-09-20T16:34:42.907",
    "socioemotionalResults": [
      {
        "dimension": "PROBLEM_SOLVING",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Karina pode se sentir desconfortvel no exerccio de algumas habilidades que compem a competncia resoluo de problemas. Para desenvolv-la, podemos incentivar Karina a experimentar atividades que estimulem o exerccio de habilidades associadas."
        ],
        "support": "resiliência; assertividade. ",
        "encourage": "compreensão do trabalho; aplicação do conhecimento; metacognição; raciocínio. ",
        "potentials": "ter criatividade; ser comprometido com os resultados; ter capacidade mobilizadora. "
      },
      {
        "dimension": "SELFCONFIDENCE",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Karina aplica a competncia autoconfiana em alguns contextos e em outros no. Para desenvolv-la, um bom exerccio  Karina buscar novos desafios onde possa aplicar e desenvolver as habilidades relacionadas."
        ],
        "support": "coragem criativa; equilíbrio interno. ",
        "encourage": "expressão; autoexperimentação; entendimento do outro. ",
        "potentials": "saber lidar com a adversidade; saber lidar com as frustrações; ter persistência; ter facilidade para autossuperação. "
      },
      {
        "dimension": "CREATIVITY",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Karina pode se sentir desconfortvel no exerccio de algumas habilidades que compem a competncia criatividade. Para desenvolv-la, podemos incentivar Karina a experimentar atividades que estimulem o exerccio de habilidades associadas."
        ],
        "support": "flexibilidade; curiosidade. ",
        "encourage": "execução; inventividade. ",
        "potentials": "ter versatilidade; ter abertismo; empenhar-se em busca dos objetivos; ter espontaneidade; ter adaptabilidade. "
      },
      {
        "dimension": "CRITICAL_THINKING",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Karina aplica a competncia pensamento crtico em alguns contextos e em outros no. Para desenvolv-la, um bom exerccio  Karina buscar novos desafios onde possa aplicar e desenvolver as habilidades relacionadas."
        ],
        "support": "persistência. ",
        "encourage": "comunicação digital; cientificidade. ",
        "potentials": "ter visão de conjunto ; ter autocrítica; primar pela credibilidade; ter análise crítica; ter clareza; valoriza a racionalidade. "
      },
      {
        "dimension": "SELFMANAGEMENT_AND_LEADERSHIP",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Karina pode se sentir desconfortvel no exerccio de algumas habilidades que compem a competncia autogesto e liderana. Para desenvolv-la, podemos incentivar Karina a experimentar atividades que estimulem o exerccio de habilidades associadas."
        ],
        "support": "empoderamento. ",
        "encourage": "tomada de decisões; autoconsciência. ",
        "potentials": "saber compartilhar; senso crítico; ter carisma; olhar os outros com bons olhos; ter flexibilidade; ser favorecedor do trabalho cooperativo. "
      },
      {
        "dimension": "COMMUNICATION",
        "color": "Green",
        "descriptions": [
          "Karina tem bom potencial quanto s habilidades associadas  competncia comunicao e pode melhorar seu desempenho na medida em que exercit-las em diferentes situaes de aprendizado."
        ],
        "support": "desinibição. ",
        "encourage": "expressão cultural; clareza; autocrítica. ",
        "potentials": "ter calma; saber lidar com feedbacks; ter autoaceitação; saber ouvir; saber compreender o outro. "
      },
      {
        "dimension": "COOPERATION",
        "color": "Green",
        "descriptions": [
          "Karina j aplica as habilidades relacionadas  competncia cooperao e pode melhorar seu desempenho em outras ou situaes sempre que se sentir motivado  exercit-las"
        ],
        "support": "sociabilidade. ",
        "encourage": "",
        "potentials": "ter altruísmo; ter coleguismo; ser cordial; olhar os outros com bons olhos; seguir as regras; valorizar as virtudes; ter empatia; senso de organização. "
      }
    ]
  },
  {
    id: "61576981ad8b4700791e4813",
    "user": {
      "name": "Júlia Sophia Santos ",
      "email": "julia9087@icloud.com"
    },
    "student": {
      "name": "Júlia Sophia Santos ",
      "birthdate": "2005-03-31"
    },
    "processDateTime": "2021-10-01T17:03:13.57",
    "socioemotionalResults": [
      {
        "dimension": "SELFCONFIDENCE",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Jlia pode se sentir desconfortvel no exerccio de algumas habilidades que compem a competncia autoconfiana. Para desenvolv-la, podemos incentivar Jlia a experimentar atividades que estimulem o exerccio de habilidades associadas."
        ],
        "support": "equilíbrio interno. ",
        "encourage": "perseverança; expressão; autoexperimentação. ",
        "potentials": "saber lidar com conflitos; apreciar desafios para o aprendizado; ter persistência; ter facilidade para autossuperação; saber lidar com as frustrações. "
      },
      {
        "dimension": "COMMUNICATION",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Jlia aplica a competncia comunicao em alguns contextos e em outros no. Para desenvolv-la, um bom exerccio  Jlia buscar novos desafios onde possa aplicar e desenvolver as habilidades relacionadas."
        ],
        "support": "espontaneidade. ",
        "encourage": "autoaceitação. ",
        "potentials": "ter clareza; ter calma; saber lidar com feedbacks; demonstrar maturidade para lidar com críticas; saber ouvir; ter extroversão; saber compreender o outro. "
      },
      {
        "dimension": "SELFMANAGEMENT_AND_LEADERSHIP",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Jlia pode se sentir desconfortvel no exerccio de algumas habilidades que compem a competncia autogesto e liderana. Para desenvolv-la, podemos incentivar Jlia a experimentar atividades que estimulem o exerccio de habilidades associadas."
        ],
        "support": "",
        "encourage": "liderança. ",
        "potentials": "ter flexibilidade; senso crítico; olhar os outros com bons olhos; ter autonomia; saber compartilhar; ter iniciativa; ter proatividade; ser favorecedor do trabalho cooperativo. "
      },
      {
        "dimension": "CREATIVITY",
        "color": "Green",
        "descriptions": [
          "Jlia tem bom potencial quanto s habilidades associadas  competncia criatividade e pode melhorar seu desempenho na medida em que exercit-las em diferentes situaes de aprendizado."
        ],
        "support": "",
        "encourage": "",
        "potentials": "ter adaptabilidade; ter persistência; ter inventividade; ter flexibilidade; ter versatilidade; ter abertismo; gostar do novo; empenhar-se em busca dos objetivos; ter espontaneidade. "
      },
      {
        "dimension": "COOPERATION",
        "color": "Green",
        "descriptions": [
          "Jlia j aplica as habilidades relacionadas  competncia cooperao e pode melhorar seu desempenho em outras ou situaes sempre que se sentir motivado  exercit-las"
        ],
        "support": "",
        "encourage": "",
        "potentials": "ter coleguismo; seguir as regras; ser cordial; olhar os outros com bons olhos; valorizar as virtudes; ter empatia; ter altruísmo; senso de organização; ter sociabilidade. "
      }
    ]
  },
  {
    id: "61574b0ead8b4700791e4810",
    "user": {
      "name": "Jhonatan Santos Silva",
      "email": "jhonsansilva@gmail.com"
    },
    "student": {
      "name": "Jhonatan Santos Silva",
      "birthdate": "2003-02-16"
    },
    "processDateTime": "2021-10-01T14:53:18.223",
    "socioemotionalResults": [
      {
        "dimension": "CREATIVITY",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Jhonatan pode se sentir desconfortvel no exerccio de algumas habilidades que compem a competncia criatividade. Para desenvolv-la, podemos incentivar Jhonatan a experimentar atividades que estimulem o exerccio de habilidades associadas."
        ],
        "support": "flexibilidade. ",
        "encourage": "inventividade; execução; soluções. ",
        "potentials": "gostar do novo; ter espontaneidade; ter adaptabilidade; empenhar-se em busca dos objetivos; ter abertismo. "
      },
      {
        "dimension": "COMMUNICATION",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Jhonatan aplica a competncia comunicao em alguns contextos e em outros no. Para desenvolv-la, um bom exerccio  Jhonatan buscar novos desafios onde possa aplicar e desenvolver as habilidades relacionadas."
        ],
        "support": "reflexão sobre as emoções. ",
        "encourage": "escuta; autocrítica. ",
        "potentials": "ter autoaceitação; ter clareza; ter extroversão; saber compreender o outro; saber lidar com feedbacks; ter expressividade. "
      },
      {
        "dimension": "SELFCONFIDENCE",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Jhonatan pode se sentir desconfortvel no exerccio de algumas habilidades que compem a competncia autoconfiana. Para desenvolv-la, podemos incentivar Jhonatan a experimentar atividades que estimulem o exerccio de habilidades associadas."
        ],
        "support": "assimilação sadia das frustrações. ",
        "encourage": "autoestima; autoexperimentação. ",
        "potentials": "saber lidar com a adversidade; apreciar desafios para o aprendizado; ter extroversão; ter facilidade para autossuperação; ter persistência; saber lidar com conflitos. "
      },
      {
        "dimension": "SELFMANAGEMENT_AND_LEADERSHIP",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Jhonatan aplica a competncia autogesto e liderana em alguns contextos e em outros no. Para desenvolv-la, um bom exerccio  Jhonatan buscar novos desafios onde possa aplicar e desenvolver as habilidades relacionadas."
        ],
        "support": "valorização dos outros. ",
        "encourage": "liderança; saber compartilhar. ",
        "potentials": "ter proatividade; ter autonomia; ter flexibilidade; senso crítico; ser favorecedor do trabalho cooperativo; ter iniciativa. "
      },
      {
        "dimension": "COOPERATION",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Jhonatan pode se sentir desconfortvel no exerccio de algumas habilidades que compem a competncia cooperao. Para desenvolv-la, podemos incentivar Jhonatan a experimentar atividades que estimulem o exerccio de habilidades associadas."
        ],
        "support": "otimismo quanto às pessoas. ",
        "encourage": "colaboração. ",
        "potentials": "ter empatia; ter sociabilidade; senso de organização; seguir as regras; ser cordial; valorizar as virtudes; ter coleguismo. "
      },
      {
        "dimension": "CRITICAL_THINKING",
        "color": "Green",
        "descriptions": [
          "Jhonatan tem bom potencial quanto s habilidades associadas  competncia pensamento crtico e pode melhorar seu desempenho na medida em que exercit-las em diferentes situaes de aprendizado."
        ],
        "support": "",
        "encourage": "cientificidade. ",
        "potentials": "ter clareza; boa comunicabilidade; ter autocrítica; ter persistência; primar pela credibilidade; valoriza a racionalidade; ter visão de conjunto ; ter análise crítica. "
      },
      {
        "dimension": "PROBLEM_SOLVING",
        "color": "Green",
        "descriptions": [
          "Jhonatan j aplica as habilidades relacionadas  competncia resoluo de problemas e pode melhorar seu desempenho em outras ou situaes sempre que se sentir motivado  exercit-las"
        ],
        "support": "",
        "encourage": "formulação de perguntas. ",
        "potentials": "ter resiliência; ter capacidade mobilizadora; ter capacidade empreendedora; ter capacidade de tomar boas decisões; ter cientificidade; analisar em profundidade; ser comprometido com os resultados; ter assertividade. "
      }
    ]
  },
  {
    id: "614263dc818c3a0079bd1b20",
    "user": {
      "name": "Italo Soares do Amaral ",
      "email": "doamaralsoaresitalo@gmail.com"
    },
    "student": {
      "name": "Italo Soares do Amaral ",
      "birthdate": "2000-03-03"
    },
    "processDateTime": "2021-09-15T18:21:32.758",
    "socioemotionalResults": [
      {
        "dimension": "SELFCONFIDENCE",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Italo pode se sentir desconfortável no exercício de algumas habilidades que compõem a competência autoconfiança. Para desenvolvê-la, podemos incentivar Italo a experimentar atividades que estimulem o exercício de habilidades associadas."
        ],
        "support": "",
        "encourage": "perseverança; autoexperimentação; autoestima; autodidatismo; automotivação. ",
        "potentials": "saber lidar com conflitos; saber lidar com as frustrações; ter persistência; ter extroversão. "
      },
      {
        "dimension": "CREATIVITY",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Italo aplica a competência criatividade em alguns contextos e em outros não. Para desenvolvê-la, um bom exercício é Italo buscar novos desafios onde possa aplicar e desenvolver as habilidades relacionadas."
        ],
        "support": "flexibilidade. ",
        "encourage": "curiosidade; execução. ",
        "potentials": "ter inventividade; ter espontaneidade; ter abertismo; empenhar-se em busca dos objetivos; ter adaptabilidade; ter versatilidade. "
      },
      {
        "dimension": "CRITICAL_THINKING",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Italo pode se sentir desconfortável no exercício de algumas habilidades que compõem a competência pensamento crítico. Para desenvolvê-la, podemos incentivar Italo a experimentar atividades que estimulem o exercício de habilidades associadas."
        ],
        "support": "",
        "encourage": "responsabilidade; autoavaliação; cientificidade; análise crítica. ",
        "potentials": "ter visão de conjunto ; primar pela credibilidade; ter clareza; boa comunicabilidade; valoriza a racionalidade. "
      },
      {
        "dimension": "PROBLEM_SOLVING",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Italo aplica a competência resolução de problemas em alguns contextos e em outros não. Para desenvolvê-la, um bom exercício é Italo buscar novos desafios onde possa aplicar e desenvolver as habilidades relacionadas."
        ],
        "support": "",
        "encourage": "resiliência; responsabilidade. ",
        "potentials": "ter cientificidade; ter capacidade de tomar boas decisões; ser comprometido com os resultados; ter assertividade; analisar em profundidade; ter capacidade empreendedora; ter criatividade. "
      },
      {
        "dimension": "SELFMANAGEMENT_AND_LEADERSHIP",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Italo pode se sentir desconfortável no exercício de algumas habilidades que compõem a competência autogestão e liderança. Para desenvolvê-la, podemos incentivar Italo a experimentar atividades que estimulem o exercício de habilidades associadas."
        ],
        "support": "",
        "encourage": "liderança; busca de informação; saber compartilhar; diversidade cultural. ",
        "potentials": "ser favorecedor do trabalho cooperativo; olhar os outros com bons olhos; ter flexibilidade; ter iniciativa; ter autonomia. "
      },
      {
        "dimension": "COOPERATION",
        "color": "Green",
        "descriptions": [
          "Italo tem bom potencial quanto às habilidades associadas à competência cooperação e pode melhorar seu desempenho na medida em que exercitá-las em diferentes situações de aprendizado."
        ],
        "support": "",
        "encourage": "consciência socioambiental; ética; colaboração. ",
        "potentials": "seguir as regras; olhar os outros com bons olhos; senso de organização; ter empatia; ser cordial; ter coleguismo. "
      },
      {
        "dimension": "COMMUNICATION",
        "color": "Green",
        "descriptions": [
          "Italo já aplica as habilidades relacionadas à competência comunicação e pode melhorar seu desempenho em outras ou situações sempre que se sentir motivado à exercitá-las"
        ],
        "support": "",
        "encourage": "autoconfiança; escuta. ",
        "potentials": "ter calma; ter autoaceitação; demonstrar maturidade para lidar com críticas; ter expressividade; saber lidar com feedbacks; ter clareza; saber compreender o outro. "
      }
    ]
  },
  {
    id: "6142525a818c3a0079bd1b1d",
    "user": {
      "name": "Ianna Ravena Roseno Mineiro ",
      "email": "ravenaianna13@gmail.com"
    },
    "student": {
      "name": "Ianna Ravena Roseno Mineiro ",
      "birthdate": "2004-10-25"
    },
    "processDateTime": "2021-09-15T17:06:50.308",
    "socioemotionalResults": [
      {
        "dimension": "COMMUNICATION",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Ianna pode se sentir desconfortável no exercício de algumas habilidades que compõem a competência comunicação. Para desenvolvê-la, podemos incentivar Ianna a experimentar atividades que estimulem o exercício de habilidades associadas."
        ],
        "support": "empatia; interesse no ouvinte; espontaneidade. ",
        "encourage": "",
        "potentials": "ter extroversão; saber lidar com feedbacks; ter autoaceitação; demonstrar maturidade para lidar com críticas; saber ouvir; ter calma. "
      },
      {
        "dimension": "COOPERATION",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Ianna aplica a competência cooperação em alguns contextos e em outros não. Para desenvolvê-la, um bom exercício é Ianna buscar novos desafios onde possa aplicar e desenvolver as habilidades relacionadas."
        ],
        "support": "disciplina; coleguismo. ",
        "encourage": "acolhimento; valorização da diversidade. ",
        "potentials": "ter sociabilidade; ter altruísmo; valorizar as virtudes; senso de organização; ser cordial. "
      },
      {
        "dimension": "SELFMANAGEMENT_AND_LEADERSHIP",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente Ianna pode se sentir desconfortável no exercício de algumas habilidades que compõem a competência autogestão e liderança. Para desenvolvê-la, podemos incentivar Ianna a experimentar atividades que estimulem o exercício de habilidades associadas."
        ],
        "support": "",
        "encourage": "liderança; tomada de decisões; alteridade. ",
        "potentials": "ter autonomia; ter proatividade; saber compartilhar; ser favorecedor do trabalho cooperativo; senso crítico; ter flexibilidade. "
      },
      {
        "dimension": "SELFCONFIDENCE",
        "color": "Green",
        "descriptions": [
          "Ianna tem bom potencial quanto às habilidades associadas à competência autoconfiança e pode melhorar seu desempenho na medida em que exercitá-las em diferentes situações de aprendizado."
        ],
        "support": "anticonflitividade. ",
        "encourage": "expressão; autoexperimentação. ",
        "potentials": "apreciar desafios para o aprendizado; saber lidar com as frustrações; ter equilíbrio interno; ter persistência; ter facilidade para autossuperação; saber lidar com a adversidade. "
      },
      {
        "dimension": "CREATIVITY",
        "color": "Green",
        "descriptions": [
          "Ianna já aplica as habilidades relacionadas à competência criatividade e pode melhorar seu desempenho em outras ou situações sempre que se sentir motivado à exercitá-las"
        ],
        "support": "",
        "encourage": "adaptabilidade; inventividade. ",
        "potentials": "gostar do novo; empenhar-se em busca dos objetivos; ter abertismo; ter flexibilidade; ter espontaneidade; ter versatilidade; ter persistência. "
      }
    ]
  },
  {
    id: "61422704818c3a0079bd1b09",
    "user": {
      "name": "julia honorato de queiroz ",
      "email": "juliahonorato77@gmail.com"
    },
    "student": {
      "name": "julia honorato de queiroz ",
      "birthdate": "2001-12-14"
    },
    "processDateTime": "2021-09-15T14:01:56.411",
    "socioemotionalResults": [
      {
        "dimension": "SELFCONFIDENCE",
        "color": "Red",
        "descriptions": [
          "Avaliar a hipótese de existirem fatores emocionais e ou cognitivos dificultadores do desenvolvimento da competência autoconfiança, no caso de julia. Além disto, é importante acompanhar de perto e considerar o apoio especializado de profissionais aptos à diagnosticarem melhor as possíveis causas."
        ],
        "support": "equilíbrio interno; anticonflitividade; automotivação; desdramatização do erro; coragem criativa. ",
        "encourage": "expressão; esforço. ",
        "potentials": "saber lidar com as frustrações; saber lidar com a adversidade. "
      },
      {
        "dimension": "CREATIVITY",
        "color": "Red",
        "descriptions": [
          "Avaliar a hipótese de existirem fatores emocionais e ou cognitivos dificultadores do desenvolvimento da competência criatividade, no caso de julia. Além disto, é importante acompanhar de perto e considerar o apoio especializado de profissionais aptos à diagnosticarem melhor as possíveis causas."
        ],
        "support": "espontaneidade; adaptabilidade; curiosidade. ",
        "encourage": "determinação; flexibilidade; inventividade; execução. ",
        "potentials": "ter versatilidade; ter abertismo. "
      },
      {
        "dimension": "SELFMANAGEMENT_AND_LEADERSHIP",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente julia pode se sentir desconfortável no exercício de algumas habilidades que compõem a competência autogestão e liderança. Para desenvolvê-la, podemos incentivar julia a experimentar atividades que estimulem o exercício de habilidades associadas."
        ],
        "support": "autonomia; iniciativa; empoderamento. ",
        "encourage": "autoeficácia. ",
        "potentials": "senso crítico; olhar os outros com bons olhos; ter carisma; saber compartilhar; ter flexibilidade. "
      },
      {
        "dimension": "PROBLEM_SOLVING",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente julia aplica a competência resolução de problemas em alguns contextos e em outros não. Para desenvolvê-la, um bom exercício é julia buscar novos desafios onde possa aplicar e desenvolver as habilidades relacionadas."
        ],
        "support": "resiliência. ",
        "encourage": "mundo digital; aplicação do conhecimento; metacognição. ",
        "potentials": "ter cientificidade; ter capacidade empreendedora; ter criatividade; ter assertividade; ter capacidade mobilizadora. "
      },
      {
        "dimension": "CRITICAL_THINKING",
        "color": "Yellow",
        "descriptions": [
          "Provavelmente julia pode se sentir desconfortável no exercício de algumas habilidades que compõem a competência pensamento crítico. Para desenvolvê-la, podemos incentivar julia a experimentar atividades que estimulem o exercício de habilidades associadas."
        ],
        "support": "persistência. ",
        "encourage": "autoavaliação; cientificidade; comunicação digital. ",
        "potentials": "ter clareza; valoriza a racionalidade; ter visão de conjunto ; ter análise crítica; primar pela credibilidade. "
      },
      {
        "dimension": "COMMUNICATION",
        "color": "Green",
        "descriptions": [
          "julia tem bom potencial quanto às habilidades associadas à competência comunicação e pode melhorar seu desempenho na medida em que exercitá-las em diferentes situações de aprendizado."
        ],
        "support": "desinibição. ",
        "encourage": "expressão cultural; reflexão. ",
        "potentials": "ter clareza; saber lidar com feedbacks; demonstrar maturidade para lidar com críticas; saber ouvir; saber compreender o outro; ter autoaceitação. "
      },
      {
        "dimension": "COOPERATION",
        "color": "Green",
        "descriptions": [
          "julia já aplica as habilidades relacionadas à competência cooperação e pode melhorar seu desempenho em outras ou situações sempre que se sentir motivado à exercitá-las"
        ],
        "support": "sociabilidade. ",
        "encourage": "",
        "potentials": "ser cordial; valorizar as virtudes; olhar os outros com bons olhos; ter coleguismo; seguir as regras; ter altruísmo; ter empatia; senso de organização. "
      }
    ]
  },

  

];

export const mockDimensions: any = [
  {
    type: "SELFCONFIDENCE",
    title: "AUTOCONFIANÇA",
    guide: "Convicção sobre as próprias capacidades para enfrentar desafios em todas as áreas da vida",
    img: [
      {
        age: 1,
        img:
          "https://schoolastic-public.s3-sa-east-1.amazonaws.com/reconhecame/rsz_ob83q10.jpg",
      },
      {
        age: 11,
        img: SelfConfidenceTeen,
      },
      {
        age: 18,
        img: SelfConfidence18,
      },
    ],

    icon: "https://app.reconheca.me/static/img/cards/self-confidence-icon.png",
  },
  {
    type: "PROBLEM_SOLVING",
    title: "RESOLUÇÃO DE PROBLEMAS",
    guide: "Uso de métodos, de uma forma ordenada, para encontrar soluções de problemas específicos",
    img: [
      {
        age: 1,
        img:
          "https://schoolastic-public.s3-sa-east-1.amazonaws.com/reconhecame/a3-process-six-sigma-768x512.jpg",
      },
      {
        age: 11,
        img:
          "https://schoolastic-public.s3-sa-east-1.amazonaws.com/reconhecame/a3-process-six-sigma-768x512.jpg",
      },
      {
        age: 18,
        img:
          "https://schoolastic-public.s3-sa-east-1.amazonaws.com/reconhecame/a3-process-six-sigma-768x512.jpg",
      },
    ],

    icon: "https://app.reconheca.me/static/img/cards/problem-solving-icon.png",
  },
  {
    type: "CREATIVITY",
    title: "CRIATIVIDADE",
    guide: "Flexibilidade mental para novas ideias e solução de problemas",
    img: [
      {
        age: 1,
        img:
          "https://schoolastic-public.s3-sa-east-1.amazonaws.com/reconhecame/thinkstockphotos-518589470.jpg",
      },
      {
        age: 11,
        img: CreativityTeen,
      },
      {
        age: 18,
        img: Creativity18,
      },
    ],

    icon: "https://app.reconheca.me/static/img/cards/creativity-icon.png",
  },
  {
    type: "COMMUNICATION",
    title: "COMUNICAÇÃO",
    guide: "Saber ouvir, compreender, expressar-se e debater ideias",
    img: [
      {
        age: 1,
        img:
          "https://schoolastic-public.s3-sa-east-1.amazonaws.com/reconhecame/image.jpg",
      },
      {
        age: 11,
        img: CommunicationTeen,
      },
      {
        age: 18,
        img: Communication18,
      },
    ],
    icon: "https://app.reconheca.me/static/img/cards/comunication-icon.png",
  },
  {
    type: "CRITICAL_THINKING",
    title: "PENSAMENTO CRÍTICO",
    guide: "Traduz um julgamento propositado e reflexivo sobre o que acreditar ou o que fazer em resposta a uma observação, experiência, expressão verbal ou escrita, ou argumentos",
    img: [
      {
        age: 1,
        img:
          "https://schoolastic-public.s3-sa-east-1.amazonaws.com/reconhecame/improvecriticalthinking.jpg",
      },
      {
        age: 11,
        img:
          "https://schoolastic-public.s3-sa-east-1.amazonaws.com/reconhecame/improvecriticalthinking.jpg",
      },
      {
        age: 18,
        img:
          "https://schoolastic-public.s3-sa-east-1.amazonaws.com/reconhecame/improvecriticalthinking.jpg",
      },
    ],
    icon:
      "https://app.reconheca.me/static/img/cards/critical-thinking-icon.png",
  },
  {
    type: "SELFMANAGEMENT_AND_LEADERSHIP",
    title: "AUTOGESTÃO E LIDERANÇA",
    guide: "Saber motivar a si mesmo e mobilizar pessoas",

    img: [
      {
        age: 1,
        img:
          "https://schoolastic-public.s3-sa-east-1.amazonaws.com/reconhecame/hogeschoolwerk.jpg",
      },
      {
        age: 11,
        img: LeaderShipTeen,
      },
      {
        age: 18,
        img: LeaderShip18,
      },
    ],

    icon:
      "https://app.reconheca.me/static/img/cards/self-management-and-leadership-icon.png",
  },
  {
    type: "COOPERATION",
    title: "COOPERAÇÃO",
    guide: "Capacidade de favorecer a sinergia no trabalho em grupo visando objetivos comuns",
    img: [
      {
        age: 1,
        img:
          "https://schoolastic-public.s3-sa-east-1.amazonaws.com/reconhecame/ajudando.jpg",
      },
      {
        age: 11,
        img: CooperationTeen,
      },
      {
        age: 18,
        img: Cooperation18,
      },
    ],

    icon: "https://app.reconheca.me/static/img/cards/cooperation-icon.png",
  },
];

export const mockSchoolGeneralResult: any[] = [
  {
    actionType: "dimension",
    result: {
      AÇÃO: "Dimensão",

      AUTOCONFIANÇA: {
        red: 20,
        green: 20,
        yellow: 60,
      },

      RESOLUÇÃO_DE_PROBLEMAS: {
        red: 60,
        green: 10,
        yellow: 30,
      },

      CRIATIVIDADE: {
        red: 25,
        green: 25,
        yellow: 50,
      },

      COMUNICAÇÃO: {
        red: 30,
        green: 30,
        yellow: 40,
      },

      PENSAMENTO_CRITÍCO: {
        red: 15,
        green: 15,
        yellow: 70,
      },

      LIDERANÇA: {
        red: 90,
        green: 5,
        yellow: 5,
      },

      COOPERAÇÃO: {
        red: 90,
        green: 3,
        yellow: 7,
      },
    },
  },
  {
    actionType: "support",
    result: {
      AÇÃO: "Necessidades de apoio",

      CORAGEM_CRIATIVA: {
        red: 20,
        green: 20,
        yellow: 60,
      },

      ANTICONFLITIVDADE: {
        red: 60,
        green: 10,
        yellow: 30,
      },

      RESILIÊNCIA: {
        red: 25,
        green: 25,
        yellow: 50,
      },

      EQUILÍBRIO_INTERNO: {
        red: 30,
        green: 30,
        yellow: 40,
      },

      PERSISTÊNCIA: {
        red: 15,
        green: 15,
        yellow: 70,
      },

      SOCIABILIDADE: {
        red: 90,
        green: 5,
        yellow: 5,
      },

      EMPATIA: {
        red: 90,
        green: 3,
        yellow: 7,
      },

      COLEGUISMO: {
        red: 90,
        green: 3,
        yellow: 7,
      },

      DISCIPLINA: {
        red: 10,
        green: 20,
        yellow: 70,
      },
    },
  },
  {
    actionType: "encourage",
    result: {
      AÇÃO: "Necessidades de Incentivo",

      ENTENDIMENTO_DO_OUTRO: {
        red: 20,
        green: 20,
        yellow: 60,
      },

      AUTOESTIMA: {
        red: 60,
        green: 10,
        yellow: 30,
      },

      PERSEVERANÇA: {
        red: 25,
        green: 25,
        yellow: 50,
      },

      AUTOVALORIZAÇÃO: {
        red: 30,
        green: 30,
        yellow: 40,
      },

      AUTOEXPERIMENTAÇÃO: {
        red: 15,
        green: 15,
        yellow: 70,
      },

      ADAPTABILIDADE: {
        red: 90,
        green: 5,
        yellow: 5,
      },

      EXECUÇÃO: {
        red: 90,
        green: 3,
        yellow: 7,
      },

      SOLUÇÕES: {
        red: 90,
        green: 3,
        yellow: 7,
      },

      FLEXIBILIDADE: {
        red: 10,
        green: 20,
        yellow: 70,
      },
    },
  },
  {
    actionType: "potentials",
    result: {
      action: "Potenciais Desenvolvidos",

      TER_PERSISTÊNCIA: {
        red: 20,
        green: 20,
        yellow: 60,
      },

      TER_CLAREZA: {
        red: 60,
        green: 10,
        yellow: 30,
      },

      TER_EXTROVERSÃO: {
        red: 25,
        green: 25,
        yellow: 50,
      },

      APRECIAR_DESAFIOS: {
        red: 30,
        green: 30,
        yellow: 40,
      },

      TER_ESPONTANEIDADE: {
        red: 90,
        green: 5,
        yellow: 5,
      },

      TER_ABERTISMO: {
        red: 90,
        green: 3,
        yellow: 7,
      },

      TER_COLEGUISMO: {
        red: 90,
        green: 3,
        yellow: 7,
      },

      GOSTAR_DO_NOVO: {
        red: 10,
        green: 20,
        yellow: 70,
      },
    },
  },
];
