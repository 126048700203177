import { apiGet, apiPost } from "../services/ApiService";
import { Routes } from "../common/Routes";
import { IQuestionnaireData } from "../hooks/Questionnaire/QuestionnaireProvider";
const getMessages = async (
  // answers?: IQuestionnaireData[],
  answers?: any,
  id?: string,
  userType?: string
) => {
  try {
    const response = userType === "parents" 

    ? ( await apiPost(Routes.API_POST_PARENTS_ANSWERS,
      {
        answers: answers,
        id: id,
      }
    ))
    : (
      ( await apiPost(Routes.API_POST_PROFESSIONALS_ANSWERS,
        {
          answers: answers,
          id: id,
        }
    ))

   
        
    );
    return response.data;
  } catch (err) {
    console.error(err);
  }
};



export { getMessages };
