import {
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { DataGrid, ptBR } from "@mui/x-data-grid";
import * as React from "react";
import { useState } from "react";
import MultiSelect from "../MultiSelect";
import { GraphicRow } from "./GraphicRow";
import { Row } from "./Row";
import "./styles.css";

interface Props {
  width?: number | string;
  height?: number | string;
  mockColumns: any[];
  rows: any[];
  isDataGrid: boolean;
  mainProperty?: string;
  isGraphicRow?: boolean;
  onCellClick?: () => {};
  onGraphicClick?: () => void;
}

const Table: React.FC<Props> = ({
  width,
  height,
  mockColumns,
  rows,
  isDataGrid = true,
  mainProperty,
  isGraphicRow = false,
  onCellClick,
  onGraphicClick,
}) => {
  const [columns, setColumns] = useState(
    mockColumns
      .filter(
        (column) => column.field != mainProperty && column.field != "actions"
      )
      .map((column) => {
        return column.headerName;
      })
  );
  const [rowProperties, setRowProperties] = useState({
    properties: mockColumns
      .filter((column) => column.field != mainProperty)
      .map((column) => {
        return { ...column };
      }),

    mainProperty: mainProperty,
  });

  const handleColumn = (event: any) => {
    const {
      target: { value },
    } = event;

    const newColumns = typeof value === "string" ? value.split(",") : value;
    setColumns(newColumns);

    setRowProperties({
      ...rowProperties,
      properties: mockColumns.filter(
        (column) =>
          column.field != mainProperty &&
          newColumns.some((col: any) => col === column.headerName)
      ),
    });
  };

  return (
    <div style={{ height: height, width: width }} className="wrapper-table">
      {isDataGrid ? (
        <DataGrid
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          rows={rows}
          columns={mockColumns}
          rowsPerPageOptions={[15, 30, 60, 100, 200]}
          disableSelectionOnClick
          onCellClick={onCellClick}
        />
      ) : (
        <TableContainer component={Paper}>
          <MuiTable aria-label="collapsible table">
            <TableHead sx={{ width: "100px" }}>
              <TableRow>
                <TableCell />
                {mockColumns
                  .filter((column) => column.field == mainProperty)
                  .map((column) => (
                    <TableCell sx={{ width: 200 }}>
                      {column.headerName}
                    </TableCell>
                  ))}
                <TableCell>
                  <div className="columns-filter">
                    <MultiSelect
                      value={columns}
                      onChange={handleColumn}
                      options={mockColumns
                        .filter(
                          (column) =>
                            column.field != mainProperty &&
                            column.field != "actions"
                        )
                        .map((column) => {
                          return column.headerName;
                        })}
                      label="Colunas"
                    />
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isGraphicRow
                ? rows.map((row) => (
                    <GraphicRow
                      onGraphicClick={onGraphicClick}
                      key={row.name}
                      row={row}
                      rowProperties={rowProperties}
                    />
                  ))
                : rows.map((row) => (
                    <Row
                      key={row.name}
                      row={row}
                      rowProperties={rowProperties}
                    />
                  ))}
            </TableBody>
          </MuiTable>
        </TableContainer>
      )}
    </div>
  );
};

export default Table;
