import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp, Search } from "@material-ui/icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Colors } from "../../../../common/Colors";
import { PieChart } from "react-minimal-pie-chart";
import "./styles.css";

interface Props {
  row: any;
  rowProperties?: any;
  onGraphicClick?: () => void
}

export const GraphicRow: React.FC<Props> = ({ row, rowProperties, onGraphicClick }) => {
  const [open, setOpen] = useState(true);
  const { properties, mainProperty } = rowProperties;

  const history = useNavigate();

  const showGraphic = (value: any, column: string) => {
    const { red, green, yellow } = value;

    if (column !== "action") {
      const shiftSize = 7;
      return (
        <div className="graphic-row-cell">
          <PieChart
            startAngle={-360}
            lengthAngle={360}
            lineWidth={50}
            viewBoxSize={[100, 100]}
            radius={PieChart.defaultProps.radius - shiftSize}
            label={({ dataEntry }) => `${dataEntry.value}%`}
            onClick={onGraphicClick}
            labelPosition={70}
            segmentsShift={(index) => (index === 0 ? shiftSize : 0.5)}
           
            data={[
              { title: "Vermelho", value: red, color: Colors.COLOR_RED },
              { title: "Amarelo", value: yellow, color: Colors.COLOR_YELLOW },
              { title: "Verde", value: green, color: Colors.COLOR_GREEN },
            ]}
          />
        </div>
      );
    }
  };

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" style={{ fontWeight: "bold" }}>
          {row[mainProperty]}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead className="wrapper-collapse-header">
                  <TableRow>
                    {properties?.map((property: any) => (
                      <TableCell key={property.field} className="table-cell">
                        <div className="cell">{property.headerName}</div>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody className="table-body">
                  {properties?.map((property: any) => (
                    <TableCell
                      className="table-cell"
                      component="th"
                      scope="row"
                      key={property.field}
                    >
                      {showGraphic(row[property.field], property.field)}
                    </TableCell>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
