import { Colors } from "../../../common/Colors";

import "./styles.css";

interface Props {
  children: any;
  borderRadius?: number;
  backgroundColor?: string;
  width?: number | string;
  height?: number | string;
  onClick?: () => void;
  className?: string | undefined;
  key?: any;
}

const Button: React.FC<Props> = ({
  children,
  borderRadius,
  backgroundColor,
  width,
  height,
  onClick,
  className,
  key,
}) => {
  return (
    <button
      key={key}
      onClick={onClick ? onClick : () => {}}
      className={`wrapper-button ${className}`}
      style={{
        borderRadius: borderRadius ? borderRadius : 28,
        width: width ? width : 215,
        height: height ? height : 60,
      }}
    >
      {children}
    </button>
  );
};

export default Button;
