import {
  AppBar,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Menu as MuiMenu,
  Paper,
  Popper,
  styled,
  Toolbar,
} from "@material-ui/core";
import { AccountCircle, Menu } from "@material-ui/icons";
import { Wysiwyg } from "@mui/icons-material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import LogoPNG from "../../../assets/reconhecametoolbarlogo.png";
import { Colors } from "../../../common/Colors";
import Button from "../Button";
import "./styles.css";

interface Props {
  isMenuOpen: boolean;
  handleDrawer: () => void;
  drawerWidth: number;
  showProfileIcon: boolean;
  showMenuIcon: boolean;
  showPanel: boolean;
}

const Header: React.FC<Props> = ({
  isMenuOpen,
  handleDrawer,
  drawerWidth,
  showProfileIcon,
  showMenuIcon,
  showPanel,
}) => {
  const [isProfileOpened, setIsProfileOpened] = useState<boolean>(false);

  const history = useNavigate();

  interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
  }

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const handleProfile = () => {
    setIsProfileOpened(!isProfileOpened);
  };

  return (
    <AppBar position="fixed" open={isMenuOpen} className="header-wrapper">
      <Toolbar>
        {showMenuIcon && (
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawer}
            edge="start"
            sx={{
              marginRight: "36px",
              ...(isMenuOpen && { display: "none" }),
            }}
          >
            <Menu style={{ color: Colors.COLOR_BLACK }} />
          </IconButton>
        )}
        <header>
          <img src={LogoPNG} alt="ReconhecaMe Logo" />
        </header>

        {showPanel && (
          <div className="button-wrapper">
            <Button
              className="panel-button"
              width={100}
              height={40}
              borderRadius={10}
              onClick={() => history("/login")}
            >
              <Wysiwyg />
              Painel
            </Button>
          </div>
        )}

        {showProfileIcon && (
          <div className="profile">
            <IconButton
              onClick={handleProfile}
              edge="end"
              sx={{
                marginRight: "36px",
              }}
            >
              <AccountCircle
                style={{ color: Colors.COLOR_CYAN }}
                fontSize="large"
              />
            </IconButton>

            <MuiMenu
              open={isProfileOpened}
              onClose={handleProfile}
              className="profile-menu"
            >
              <MenuItem onClick={() => history("/login")}>Sair</MenuItem>
            </MuiMenu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
