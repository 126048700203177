import {
    SessionActions,
    SessionActionTypes,
  } from "./SessionActions";
  import { SessionState } from "./SessionProvider";
  
  export const SessionReducer = (
    state: SessionState,
    action: SessionActions
  ): SessionState => {
    switch (action.type) {
      case SessionActionTypes.login:
        return {
          ...state,
        
        };
  
      case SessionActionTypes.logout:
  
        return {
          ...state,
  
        };
  
      default:
        return state;
    }
  };
  