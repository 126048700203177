import { AxiosResponse } from "axios";
import React, {
  createContext,
  useReducer,
  useContext,
  useCallback,
} from "react";
import { getMessages } from "../../services/QuestionnaireService";

import {
  IAnswerQuestion,
  IGetQuestion,
  QuestionnaireActionTypes,
} from "./QuestionnaireActions";
import { QuestionnaireReducer } from "./QuestionnaireReducer";
import { mockQuestions } from "./../../common/Mock";
import { IQuestion } from "../../interfaces/IQuestionnaire";

export interface IQuestionnaireData {
  questionName: string;
  answer: string;
}

interface IQuestionnaireContext {
  answers: IQuestionnaireData[];
  currentQuestionOrder: number;
  getQuestion(userType?: string, questionOrder?: number): Promise<any>;
  answerQuestion(answer: IQuestionnaireData): void;
}

export interface QuestionnaireState {
  answers: IQuestionnaireData[];
  currentQuestionOrder: number;
  id: string;
}

const initialState: QuestionnaireState = {
  answers: [],
  id: "",
  currentQuestionOrder: 0,
};

const QuestionnaireContext = createContext({} as IQuestionnaireContext);

const QuestionnaireProvider: React.FC = ({ children }: any) => {
  const [state, dispatch] = useReducer(QuestionnaireReducer, initialState);

  const { answers: stateAnswers, id, currentQuestionOrder } = state;

  const getQuestion = async (userType?: string, questionOrder?: number) => {
    let question: IQuestion = {
      id: "",
      question: "",
      messages: [],
    };

    let newAnswers: any[] = [];
    switch (stateAnswers.length) {
      case 0:
        question = mockQuestions[0];
        break;

      case 1:
        question = mockQuestions[1];
        break;

      case 2:
        newAnswers =
          userType === "parents"
            ? [
                { answer: "232", questionName: "get_name" },
                { answer: "Teste", questionName: "get_student_name" },
                { answer: "2022-01-19", questionName: "get_student_birthdate" },
              ]
            : [
                { answer: "23", questionName: "get_name" },
                {
                  answer: "2007-01-20",
                  questionName: "get_professional_birthdate",
                },
                {
                  answer: "2008-01-2523",
                  questionName: "get_professional_role",
                },
                {
                  answer: "2022-01-25233",
                  questionName: "get_professional_company",
                },
              ];
        question = await getMessages(newAnswers, state.id, userType);
        break;
      case 41:
        question = mockQuestions[2];
        break;
      default:
        newAnswers = stateAnswers;
        question = await getMessages(stateAnswers, state.id, userType);

        break;
    }

    if (stateAnswers)
      dispatch({
        type: QuestionnaireActionTypes.getQuestion,
        // payload: question.id,
        payload: {
          answers: newAnswers,
          questionOrder:
            newAnswers.length > 0 && currentQuestionOrder < 2
              ? userType === "parents"
                ? 2
                : 3
              : stateAnswers.length,
        },
      } as IGetQuestion);
    return question;
  };

  const answerQuestion = (answer: IQuestionnaireData) => {
    dispatch({
      type: QuestionnaireActionTypes.answerQuestion,
      payload: answer,
    } as IAnswerQuestion);
  };

  const contextValues: IQuestionnaireContext = {
    ...state,
    getQuestion,
    answerQuestion,
  };

  return (
    <QuestionnaireContext.Provider value={contextValues}>
      {children}
    </QuestionnaireContext.Provider>
  );
};

const useQuestionnaire = () => {
  const context = useContext(QuestionnaireContext);

  if (!context) {
  }
  return context;
};

export { useQuestionnaire, QuestionnaireProvider };
