import {
  IconButton,
  MenuItem,
  SelectChangeEvent,
  TextField,
  TextFieldProps,
} from "@material-ui/core";
import { Send } from "@material-ui/icons";
import { useState } from "react";
import Button from "../Button";

import "./styles.css";

interface Input {
  name?: string;
  type?: string;
  label?: string;
  placeholder?: string;
  onChange?: (event: any) => void;
  onSubmit?: (answer: string) => void;
  buttonColor?: string;
  buttonText?: string;
  variant?: any;
  required?: boolean;
  startAdornment?: any;
  select?: boolean;
  options?: any;
  value?: any;
  disabled?: boolean;
  className?: any;
  empty?: any
}

const Input: React.FC<Input> = ({
  placeholder,
  onChange,
  onSubmit,
  buttonColor,
  buttonText,
  name,
  type,
  label,
  variant,
  required,
  startAdornment,
  select = false,
  options,
  value,
  disabled,
  className,
  empty
}) => {
  const [error, setError] = useState<boolean>(false);
  const [text, setText] = useState<string>("");

  const verifyBeforeSubmit = (value: any): void => {
    if (required && !value) {
      setError(true);
    } else {
      onSubmit && onSubmit(value);

      setText("");
    }
  };

  const onTextChange = (input: any): void => {
    if (error) setError(false);

    const { value } = input.target;
    setText(value);
  };

  // const onSelectChange = (event: any) => {
  //   if (required && !value) {
  //     setError(true);
  //   } else {
  //     onChange && onChange(value);
  //   }
  // };

  return (
    <div className={`input-wrapper ${className}`}>
      {select ? (
        <TextField
          disabled={disabled}
          label={label}
          error={empty}
          select={select}
          helperText={empty ? "Filtro obrigatório!" : ""}
          name={name}
          type={type?.toLowerCase()}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          variant={variant}
          InputProps={{
            startAdornment: startAdornment ? startAdornment : <div></div>,
          }}
        >
          {options &&
            options?.map((option: any) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
        </TextField>
      ) : (
        <TextField
          label={label}
          error={error}
          helperText={error ? "Resposta obrigatória!" : ""}
          name={name}
          type={type?.toLowerCase()}
          placeholder={placeholder}
          onChange={onTextChange}
          variant={variant}
          InputProps={{
            startAdornment: startAdornment ? startAdornment : <div></div>,
          }}
        />
      )}

      {onSubmit && (
        <IconButton
          onClick={() => verifyBeforeSubmit(text)}
         
        >
          <Send style={{color: buttonColor}} />
        </IconButton>
      )}
    </div>
  );
};

export default Input;
