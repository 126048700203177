import { InputAdornment } from "@material-ui/core";
import { School } from "@material-ui/icons";
import { useState } from "react";
import { Colors } from "../../../common/Colors";
import {
  classes,
  indicators,
  mockDimensions,
  mockSchoolGeneralResult,
  mockStudentsResult,
  periods,
  schoolLevel,
  years,
} from "../../../common/Mock";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Layout from "../../components/Layout";
import Table from "../../components/Table";
import StudentListAsideMenu from "../../components/Layout/AsideMenu/StudentListAsideMenu";
import "./styles.css";

const actionOptions = [
  {
    value: "dimension",
    label: "Dimensões Socioemocionais",
  },
  {
    value: "support",
    label: "Necessidade de Apoio",
  },
  {
    value: "encourage",
    label: "Necessidade de Incentivo",
  },
  {
    value: "potentials",
    label: "Potenciais Desenvolvidos",
  },
];

// const studentsName = mockStudentsResult.

const MonitorGraphicsPage = () => {
  const [filter, setFilter] = useState({
    year: { value: "", empty: false },
    evaluativePeriod: { value: "", empty: false },
    studentClass: { value: 0, empty: false },
    schoolLevel: { value: 0, empty: false },
    analyzedAspect: { value: 0, empty: false },
    indicator: { value: 0, empty: false },
    action: { value: "dimension", empty: false },
    studentName: { value: "", empty: false },
  });

  const [rows, setRows] = useState<typeof mockSchoolGeneralResult[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [studentList, setStudentList] = useState<any[]>(
    mockStudentsResult.map(({ id, student: { name } }) => {
      return { studentId: id, studentName: name };
    })
  );

  const [asideMenuVisible, setAsideMenuVisible] = useState<boolean>(false);

  const handleFilters = (event: any) => {
    const {
      target: { value, name },
    } = event;

    if (name === "action") {
      setColumns([]);
      setRows([]);
    }

    setFilter({
      ...filter,
      [name]: { value: value, empty: false },
    });
  };

  const buildResults = () => {
    const { evaluativePeriod } = filter;
    if (evaluativePeriod.value !== "") {
      let newRows: any[] = [];

      mockSchoolGeneralResult.forEach(({ result, actionType }) => {
        if (actionType === filter.action.value) {
          newRows.push(result);
        }
      });

      setRows(newRows);
      setColumns([
        ...Object.keys(newRows[0]).map((column: any, i: number) => {
          if (column !== "actions") {
            return {
              field: column,
              headerName: column.replace("_", " "),
              type: "string",
              width: 200,
              headerClassName: "header",
            };
          }
        }),
      ]);
    } else {
      let isFilterEmpty = false;
      // const isFilterEmpty = false;

      if (evaluativePeriod.value === "") {
        isFilterEmpty = true;
      }

      setFilter({
        ...filter,
        ["evaluativePeriod"]: {
          empty: isFilterEmpty,
          value: isFilterEmpty ? "" : filter.year.value,
        },
      });
    }
  };

  return (
    <Layout showHeader={true} showMenu={true} showProfileIcon={true}>
      <StudentListAsideMenu
        asideMenuVisible={asideMenuVisible}
        studentList={studentList}
        handleAsideMenu={() => setAsideMenuVisible(false)}
      />
      <div className="wrapper-monitor-graphics ">
        <div className="filters">
          <Input
            select={true}
            label={"Período Avaliativo"}
            options={periods}
            value={filter.evaluativePeriod.value}
            empty={filter.evaluativePeriod.empty}
            onChange={handleFilters}
            name="evaluativePeriod"
          />

          <Input
            select={true}
            label={"Turma"}
            options={classes}
            value={filter.studentClass.value}
            empty={filter.studentClass.empty}
            onChange={handleFilters}
            name="studentClass"
          />

          <Input
            select={true}
            label={"Nome do aluno:"}
            options={filter.studentClass.value}
            value={filter.studentName.value}
            onChange={handleFilters}
            empty={filter.studentName.empty}
            disabled={filter.studentClass.value === 0}
            required={true}
            name="studentName"
          />

          <Input
            select={true}
            label={"Aspecto analisado"}
            options={[
              { label: "Todos os aspectos", value: 0 },
              ...mockDimensions.map((dimension: any) => {
                return { label: dimension.title, value: dimension.type };
              }),
            ]}
            value={filter.analyzedAspect.value}
            empty={filter.analyzedAspect.empty}
            onChange={handleFilters}
            name="analyzedAspect"
          />
          <Input
            select={true}
            label={"Indicadores"}
            options={indicators}
            value={filter.indicator.value}
            empty={filter.indicator.empty}
            onChange={handleFilters}
            name="indicator"
          />

          <Input
            select={true}
            label={"Nível de ensino"}
            options={schoolLevel}
            value={filter.schoolLevel.value}
            empty={filter.schoolLevel.empty}
            onChange={handleFilters}
            name="schoolLevel"
          />

          <Input
            select={true}
            label={"Ano avaliativo"}
            options={filter.schoolLevel.value}
            empty={filter.schoolLevel.empty}
            value={filter.year.value}
            onChange={handleFilters}
            name="year"
            disabled={filter.schoolLevel.value === 0}
          />

          <Input
            select={true}
            label={"Ações"}
            options={actionOptions}
            value={filter.action.value}
            empty={filter.action.empty}
            onChange={handleFilters}
            name="action"
          />

          <div className="generate-button">
            <Button
              height={50}
              width={"60%"}
              borderRadius={5}
              onClick={buildResults}
            >
              Gerar plano de ação
            </Button>
          </div>
        </div>

        {columns.length > 0 && (
          <>
            <div className="table-result-amount">
              O resultado foi processado com base em 3 turmas, totalizando{" "}
              {mockStudentsResult.length} estudantes.
            </div>
            <Table
              mockColumns={columns}
              rows={rows}
              width={"90%"}
              height={400}
              isDataGrid={false}
              isGraphicRow={true}
              mainProperty={"AÇÃO"}
              onGraphicClick={() => setAsideMenuVisible(!asideMenuVisible)}
            />
          </>
        )}
      </div>
    </Layout>
  );
};

export default MonitorGraphicsPage;
