import { SelectChangeEvent } from "@material-ui/core";
import { GridColumns } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { Colors } from "../../../common/Colors";
import { classes } from "../../../common/Mock";
import { getClassResults } from "../../../services/ResultService";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Layout from "../../components/Layout";
import Table from "../../components/Table";
import { Email } from "@material-ui/icons";
import "./styles.css";

const columns: GridColumns = [
  {
    field: "name",
    headerName: "Nome do Estudante",
    type: "string",
    width: 255,
    cellClassName: "main-column",
    headerClassName: "header",
  },
  {
    field: "selfConfidence",
    headerName: "Autoconfiança",
    type: "string",
    width: 255,
    cellClassName: "main-column",
    headerClassName: "header",
  },
  {
    field: "selfManagementAndLeadership",
    headerName: "Autogestão e liderança",
    type: "string",
    width: 255,
    cellClassName: "main-column",
    headerClassName: "header",
  },
  {
    field: "communication",
    headerName: "Comunicação",
    type: "string",
    width: 255,
    cellClassName: "main-column",
    headerClassName: "header",
  },
  {
    field: "cooperation",
    headerName: "Cooperação",
    type: "string",
    width: 255,
    cellClassName: "main-column",
    headerClassName: "header",
  },
  {
    field: "creativity",
    headerName: "Criatividade",
    type: "string",
    width: 255,
    cellClassName: "main-column",
    headerClassName: "header",
  },
  {
    field: "criticalThinking",
    headerName: "Pensamento crítico",
    type: "string",
    width: 255,
    cellClassName: "main-column",
    headerClassName: "header",
  },
  {
    field: "problemSolving",
    headerName: "Resolução de problemas",
    type: "string",
    width: 255,
    cellClassName: "main-column",
    headerClassName: "header",
  },
  {
    field: "action",
    headerName: "Ações",
    type: "string",
    width: 255,
    cellClassName: "main-column",
    headerClassName: "header",
  },
];

const MonitorPage = () => {
  const [rows, setRows] = useState([]);
  const [teacherClass, setTeacherClass] = useState("");

  useEffect(() => {}, []);

  const formatData = (dataToFormat: any) => {
    return dataToFormat.map((data: any) => {
      const { abilities, user, id } = data;
      // const {selfConfidence, selfManagementAndLeadership, communication, cooperation, creativity, criticalThinking} = data

      return {
        id,
        ...abilities,
        ...user,
      };
    });
  };

  const handleClassSelect = async (event: SelectChangeEvent) => {
    setTeacherClass(event.target.value as string);
    setRows(formatData(await getClassResults()));
  };

  return (
    <Layout showMenu={true} showProfileIcon={true}>
      <div className="results-filter">
        <Input
          select={true}
          label={"Turma"}
          options={classes}
          onChange={handleClassSelect}
          value={teacherClass}
        />
        <Button borderRadius={5} className="send-email" height={40} width={140}>
          <Email />
          Enviar resultados
        </Button>
      </div>
      <div className="results">
        {rows.length > 0 && (
          <div className="result-description">
            Mostrando informações de {rows.length} estudantes.
          </div>
        )}


        <Table
          isDataGrid={false}
          mockColumns={columns}
          rows={rows}
          mainProperty={"name"}
        />
      </div>
    </Layout>
  );
};

export default MonitorPage;
