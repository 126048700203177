import Button from "../../components/Button";
import Layout from "../../components/Layout";
import { Forum } from "@material-ui/icons";
import { useNavigate  } from "react-router-dom";

import "./styles.css";

const HomePage = () => {
  const history = useNavigate ();
  return (
    <Layout showPanel={true}>
      <div className="home-wrapper">
        <div className="main-content">
          <span className="main-information">
            Identifique hailidades e potenciais humanos.
            <p>Vamos conversar?</p>
          </span>
          <div className="buttons-wrapper">
            <Button className="questionnaire-button" onClick={() => history("/talk-with-meg/parents")}>
              <Forum />
              SOBRE SEU FILHO
            </Button>
            <Button className="questionnaire-button" onClick={() => history("/talk-with-meg/professionals")}>
              <Forum />
              SOBRE VOCÊ
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default HomePage;

