import { Popover } from "@material-ui/core";
import { useState } from "react";
import "./styles.css";

interface Props {
  title: string;
  icon: string;
  img: string;
  descriptions: string[];
  potentials: any[];
  support: any[];
  encourage: any[];
  guide: string;
}

const SocioEmotionalCard: React.FC<Props> = ({
  title,
  icon,
  img,
  descriptions,
  potentials,
  support,
  encourage,
  guide,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <div>
        <div className="icon">
          <img src={icon} />
        </div>
        <div className="card-header">
          {title}
          <Popover
            sx={{
              pointerEvents: "none",
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            onClose={() => setAnchorEl(null)}
            disableRestoreFocus
            PaperProps={{
              style: { width: "20%", padding: 10 },
            }}
          >
            <span className="guide">{guide}</span>
          </Popover>
        </div>
      </div>
      <div className="card-body">
        <div className="content">
          <img
            src={img}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={() => setAnchorEl(null)}
          />

          <div className="description">{descriptions[0]}</div>

          {potentials.length &&
            potentials.some((potentials) => potentials != "") && (
              <div className="subject">
                <b> CARACTERÍSTICAS JÁ OBSERVADAS</b>
                <div className="student-element">
                  {potentials?.map((characteristic) => (
                    <span key={characteristic}>{characteristic}</span>
                  ))}
                </div>
              </div>
            )}
          {support.length > 0 &&
            support.some((potentials) => potentials != "") && (
              <div className="subject">
                <b>APOIE AÇÕES QUE ENVOLVAM O DESENVOLVIMENTO DE</b>

                <div className="student-element">
                  {support?.map((characteristic) => (
                    <span key={characteristic}>{characteristic}</span>
                  ))}
                </div>
              </div>
            )}

          {encourage.length &&
            encourage.some((potentials) => potentials != "") && (
              <div className="subject">
                <b>INCENTIVE AÇÕES QUE DESENVOLVAM</b>

                <div className="student-element">
                  {encourage?.map((characteristic) => (
                    <span key={characteristic}>{characteristic}</span>
                  ))}
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default SocioEmotionalCard;
