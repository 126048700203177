import axios from 'axios';
    const {
        REACT_APP_API_URL: baseUrl,
        REACT_APP_API_KEY: XAPIKey,
    } = process.env;

    const Api = axios.create({
        baseURL: baseUrl
    })

    const headers = {
        headers: {
            'Accept': 'application/json',
		    'Content-Type': 'application/json; charset=utf-8',
            'X-API-Key' : `${XAPIKey}`,
          },
    }
    const apiPost = async (url: string, params?: any) => {

        try {
            const data = params;
            const response = await Api.post(url, data, headers ?  headers  : undefined);
			return response;
        } catch (err) {
            throw err;
        }
    }

    const apiGet = async (url: string) => {
        try {
            const response = await Api.get(url, headers ?  headers  : undefined);
			return response;
        } catch (err) {
            throw err;
        }
    }

    const apiPut = async (url: string, params?: any) => {
        try {
            const data = params;
            const response = await Api.post(url, data, headers ?  headers  : undefined);
			return response;
        } catch (err) {
            throw err;
        }
    }

    const apiDelete = async (url: string) => {
        try {
            const response = await Api.post(url, headers ?  headers  : undefined);
			return response;
        } catch (err) {
            throw err;
        }
    }


export {
    apiPost,
    apiGet,
    apiPut,
    apiDelete
};
