import { Box, SwipeableDrawer } from "@material-ui/core";

interface Props {
    open?: any
    toggleDrawer: () => void
}

const AsideMenu: React.FC<Props> = ({open, toggleDrawer, children}) => {
  return (
    <>
      <SwipeableDrawer
        anchor={'right'}
        open={open}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
      >
        {children}
      </SwipeableDrawer>
    </>
  );
};

export default AsideMenu;
