import { Box, ListItemText } from "@mui/material";
import Input from "../../../Input";
import AsideMenu from "..";
import { InputAdornment, List, ListItem } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { Colors } from "../../../../../common/Colors";
import { useNavigate } from "react-router-dom";

import './styles.css'


interface Props {
    asideMenuVisible: boolean,
    handleAsideMenu: () => void,
    studentList: any[];
}

const StudentListAsideMenu: React.FC<Props> = ({asideMenuVisible = false, handleAsideMenu, studentList}) => {

    const history = useNavigate();

    return (
            <AsideMenu open={asideMenuVisible} toggleDrawer={handleAsideMenu}>
              <Box sx={{ width: 450 }}>
                <div className="wrapper-aside-menu">
                  <Input
                    label={"E-mail"}
                    required={true}
                    type={"text"}
                    variant={"standard"}
                    startAdornment={
                      <InputAdornment position="start">
                        <Search style={{ color: Colors.COLOR_BLACK }} />
                    </InputAdornment>
                    }
                  />
    
                  <List className="list">
                    {studentList.map(
                      ({ studentName, studentId }: any, index: number) => (
                        <ListItem
                          key={index}
                          className="item"
                          button
                          divider
                          onClick={() => history(`/student-result/${studentId}`)}
                        >
                          {studentName}
                        </ListItem>
                      )
                    )}
                  </List>
                </div>
              </Box>
            </AsideMenu>
          )
}

export default StudentListAsideMenu;