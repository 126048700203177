import { mockStudentsLightResults, mockStudentsResult } from "../common/Mock";

const getClassResults = async () => {
  try {
    // const response = await apiGet(Routes.API_GET_CLASS_RESULTS);

    // return response.data;

    return mockStudentsLightResults;
  } catch (err) {
    console.error(err);
  }
};

const getStudentResult = async (studentId: any) => {
  try {
    //   const response = await apiGet(Routes.API_GET_STUDENT_RESULT + studentId);

    return mockStudentsResult.find(({ id }) => studentId === id);
  } catch (err) {
    console.error(err);
  }
};

export { getClassResults, getStudentResult };

