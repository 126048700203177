import backgroundPNG from "../../../assets/questionnairebackground.png";
import Input from "../../components/Input";
import Layout from "../../components/Layout";
import { Colors } from "../../../common/Colors";

import "./styles.css";
import { useQuestionnaire } from "../../../hooks/Questionnaire/QuestionnaireProvider";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Button from "../../components/Button";
import { IQuestion } from "../../../interfaces/IQuestionnaire";
import Typist from "react-typist";
import { CircularProgress } from "@material-ui/core";
import ProfilePhoto from "../../../assets/profile-mock-img.jpg"

interface Props {
  route?: any;
}

const QuestionnairePage: React.FC<Props> = () => {
  const { userType } = useParams();

  const {
    answerQuestion,
    getQuestion,
    answers,
    currentQuestionOrder,
  } = useQuestionnaire();

  const [question, setQuestion] = useState<IQuestion>({
    id: "",
    question: "",
    messages: [],
    inputType: "text",
  });

  const [isShowingActionButton, setIsShowingActionButton] = useState<boolean>(
    false
  );
  const [loading, setLoading] = useState<boolean>(false);
  const history = useNavigate();

  useEffect(() => {
    const loadQuestion = async () => {
      const question = await getQuestion(userType, 0);
      setQuestion(question);
    };

    loadQuestion();
  }, []);

  const clearQuestion = () => {
    setQuestion({
      id: "",
      question: "",
      messages: [],
      inputType: "text",
    });
  };

  const sendAnswers = async (answer: string) => {
    const completeAnswer = { answer: answer, questionName: question.question };
    let newQuestion: any = {};
    if(completeAnswer.questionName === "socioemotional_results_resume") {
      history("/")
    } else {
      answerQuestion(completeAnswer);
      newQuestion = await getQuestion(userType, currentQuestionOrder)
    }

    setLoading(true);
    clearQuestion();
    if (newQuestion.question !== "confirm_student_information") {
      setIsShowingActionButton(false);
    }
  
    setTimeout(() => {
      setLoading(false);
      setQuestion(newQuestion);
    }, 1000);
  };

  const renderLine = () => {
    const messages = question?.messages.map((message: string) => {
      return message
        .replace("{{get_student_name}}", "Gabriel")
        .split("^300")
        .join()
        .split("^700")
        .join()
        .split("^500")
        .join()
        .split("^200")
        .join()
        .replace("{{get_student_name}}", "Gabriel");
    });

    return (
      question?.messages.length > 0 && (
        <div className="wrapper-lines">
          <Typist
            avgTypingDelay={1}
            onTypingDone={() => setIsShowingActionButton(true)}
          >
            {messages.map((message: any) => (
              <div key={message}>
                {message}
                <br />
                <br />
              </div>
            ))}
          </Typist>
        </div>
      )
    );
  };

  const renderStudentProfile = () => {
    const { birthDate, email, studentName } = question?.studentInformation;
    return (
      <div className="profile">
        <div>
          <span>
            <b>Nome:</b>
            <p>{studentName}</p>
          </span>
          <span>
            <b>Email:</b>
            <p>{email}</p>
          </span>
          <span>
            <b>Data de Nascimento:</b>
            <p>{birthDate}</p>
          </span>
        </div>
        <div className="photo">
            <img src={ProfilePhoto} />
        </div>
      </div>
    );
  };

  return (
    <Layout>
      {loading && (
        <div className="loading">
          <CircularProgress />
        </div>
      )}
      <div className="questionnaire-wrapper">
        <div className="questionnaire">
          {question.question === "confirm_student_information"
            ? renderStudentProfile()
            : renderLine()}
        </div>

        {!loading && isShowingActionButton && (
          <div
            className="footer"
            style={{ backgroundColor: Colors.COLOR_LIGHT_GREY }}
          >
            {question.buttons ? (
              <div
                className={
                  Object.keys(question.buttons).length > 1
                    ? "buttons-wrapper-grid"
                    : "buttons-wrapper-flex"
                }
              >
                {Object.keys(question?.buttons).map(
                  (button: any, index: any) => (
                    <Button
                      key={index}
                      
                      onClick={() => sendAnswers(question.buttons[button])}
                      backgroundColor={Colors.COLOR_BLUE}
                      borderRadius={5}
                      width={"100%"}
                      height={50}
                    >
                      {button}
                    </Button>
                  )
                )}
              </div>
            ) : (
              <Input
                type={question.inputType}
                name="answer"
                variant="standard"
                required={true}
                onSubmit={sendAnswers}
                buttonColor={Colors.COLOR_BLUE}
                placeholder={question.inputPlaceholder}
              />
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default QuestionnairePage;
