import { QuestionnaireProvider } from "../hooks/Questionnaire/QuestionnaireProvider";
import {
  NavLink,
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import QuestionnairePage from "../ui/screens/QuestionnairePage";
import HomePage from "../ui/screens/HomePage";
import { useAuth } from "../hooks/Session/SessionProvider";
import LoginPage from "../ui/screens/LoginPage";
import ActionPlans from "../ui/screens/ActionPlansPage";
import StudentResult from "./../ui/screens/StudentResult/index";
import MonitorPage from "./../ui/screens/MonitorPage/index";
import MonitorGraphicsPage from "../ui/screens/MonitorGraphicsPage";

const MainRoutes = () => {
  const { user } = useAuth();

  const IsAllow = ({ children }) => {
    let component = children;
    // if (Object.keys(user).length) {
    // component = <Navigate to={"/"} replace />;
    // } else {
    let allow = true;
    //let userLogged = SessionManager.getUser();
    if (!allow) {
      component = <Navigate to={"/"} replace />;
    }
    // }
    return component;
  };

  // const routes = [
  //   {element: "{<QuestionnairePage />}", path: "/:userType"}
  // ]

  return (
    <>
      <Routes>
        <Route exact element={<HomePage />} path="/" />
      </Routes>
      <QuestionnaireProvider>
        <Routes>
          <Route
            exact
            element={<QuestionnairePage />}
            path="/talk-with-meg/:userType"
          />
        </Routes>
      </QuestionnaireProvider>

      <Routes>
        <Route
          exact
          element={<LoginPage />}
          path="/login"
        />

        <Route
          exact
          element={
            <IsAllow>
              <ActionPlans />
            </IsAllow>
          }
          path="/action-plans"
        />

        <Route
          exact
          element={
            <IsAllow>
              <MonitorPage />
            </IsAllow>
          }
          path="/monitor"
        />

        <Route
          exact
          element={
            <IsAllow>
              <StudentResult />
            </IsAllow>
          }
          path="/student-result/:studentId"
        />

        <Route
          exact
          element={
            <IsAllow>
              <MonitorGraphicsPage />
            </IsAllow>
          }
          path="/monitor-graphics"
        />
      </Routes>
    </>
  );
};

export default MainRoutes;
