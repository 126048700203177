import { Card, CardContent, InputAdornment } from "@material-ui/core";
import LogoPNG from "../../../assets/reconhecametoolbarlogo.png";
import { Colors } from "../../../common/Colors";
import Input from "../../components/Input";
import Layout from "../../components/Layout";
import { Email, Lock  } from "@material-ui/icons";

import "./styles.css";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
const LoginPage: React.FC = () => {
  const history = useNavigate();


  return (
    <Layout showHeader={false} showMenu={false}>
      <div className="login-wrapper">
        <Card className="card">
          <CardContent>
            <div className="logo">
              <a href="https://www.reconheca.me" target="_blank">
                <img src={LogoPNG} alt="ReconhecaMe Logo" />
              </a>
            </div>
            <div className="input-container">
              <Input
                label={"E-mail"}
                required={true}
                type={"text"}
                variant={"outlined"}
                startAdornment={
                  <InputAdornment position="start">
                    <Email style={{ color: Colors.COLOR_CYAN }} />
                  </InputAdornment>
                }
              />

              <Input
                label={"Senha"}
                required={true}
                type={"password"}
                variant={"outlined"}
                startAdornment={
                  <InputAdornment position="start">
                    <Lock style={{ color: Colors.COLOR_CYAN }} />
                  </InputAdornment>
                }
              />
            </div>
            <span className="forget-password">Esqueceu a senha?</span>


            <div className="submit-button-wrapper">
              <Button height={40} width={150} onClick={() => history("/monitor")}>
                ENTRAR
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </Layout>
  );
};

export default LoginPage;
