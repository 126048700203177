export const Colors = {
    COLOR_WHITE: '#F8F8F8',
    COLOR_BLACK: '#000000',
    COLOR_GREEN: '#4DAF51',
    COLOR_BLUE: '#2196F3',
    COLOR_LIGHT_GREY: '#E0E0E0',
    COLOR_DARK_GREY: '#787878',
    COLOR_CYAN: '#0295C9',
    COLOR_DARK_CYAN: '#012430',
    COLOR_ORANGE: '#F9A234',
    COLOR_YELLOW: '#FBC02D',
    COLOR_RED: '#E53935'
}