import { Tab, Tabs } from "@material-ui/core";
import { Cake } from "@material-ui/icons";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Colors } from "../../../common/Colors";
import { mockDimensions } from "../../../common/Mock";
import { IStudentResult } from "../../../interfaces/IResult";
import { getStudentResult } from "../../../services/ResultService";
import Layout from "../../components/Layout";
import SocioEmotionalCard from "./SocioEmotionalCard";
import "./styles.css";

const StudentResult = () => {
  const [results, setResults] = useState<any[]>([]);
  const [studentName, setStudentName] = useState<string>("");
  const [observer, setObserver] = useState<string>("");
  const [studentBirthdate, setStudentBirthdate] = useState<string>("");
  const [processDate, setProcessDate] = useState<string>("");

  const [page, setPage] = useState<string>("zero");
  const [actionPlans, setActionPlans] = useState<any>([]);

  const { studentId } = useParams();
  useEffect(() => {
    const getResult = async () => {
      const results: IStudentResult | any = await getStudentResult(studentId);
      const {
        socioemotionalResults,
        user: { name },
        student: { name: newStudentName, birthdate },
        processDateTime,
      } = results;
      setStudentName(newStudentName);
      setObserver(name);
      setProcessDate(moment(processDateTime).format("DD/MM/YYYY"));
      setStudentBirthdate(moment(birthdate).format("DD/MM/YYYY"));
      const dimensions: any[] = [];

      socioemotionalResults?.forEach(
        ({ dimension, potentials, encourage, support, ...results }: any) => {
          mockDimensions.forEach(({ type, img, ...dimensionResults }: any) => {
            if (type === dimension) {
              dimensions.push({
                ...results,
                ...dimensionResults,
                img: verifyImg(birthdate, img),
                potentials: potentials.split(";"),
                encourage: encourage.split(";"),
                support: support.split(";"),
              });
            }
          });
        }
      );

      buildActionPlans(dimensions);
      setResults(dimensions);
    };

    getResult();
  }, []);

  const verifyImg = (birthDate: any, imgs: any[]) => {
    let img = "";
    imgs.forEach(({ img: ageImg, age }) => {
      const diffAge = moment(new Date()).diff(moment(birthDate), "years");
      if (diffAge > age) {
        img = ageImg;
      }
    });

    return img;
  }
  const buildActionPlans = (results: any) => {
    let newPotentials: any[] = [];
    let newEncourages: any[] = [];
    let newSupports: any[] = [];
    results.forEach(
      ({ icon, potentials, encourage: encourages, support: supports }: any) => {
        if (
          potentials.length > 0 &&
          potentials.some((potentials: any) => potentials != "")
        ) {
          potentials.forEach((potential: any) => {
            if (!newPotentials.some((plan: any) => plan.name === potential))
              newPotentials.push({
                icon: icon,
                name: potential,
              });
          });
        }

        if (
          encourages.length > 0 &&
          encourages.some((encourage: any) => encourage != "")
        ) {
          encourages.forEach((encourage: any) => {
            if (!newEncourages.some((plan: any) => plan.name === encourage))
              newEncourages.push({
                icon: icon,
                name: encourage,
              });
          });
        }
        if (
          supports.length > 0 &&
          supports.some((support: any) => support != "")
        ) {
          supports.forEach((support: any) => {
            if (!newSupports.some((plan: any) => plan.name === support))
              newSupports.push({
                icon: icon,
                name: support,
              });
          });
        }
      }
    );

    setActionPlans({ newPotentials, newEncourages, newSupports });
  };

  const handlePages = (event: any, newValue: string) => {
    setPage(newValue);
  };

  return (
    <Layout showProfileIcon={true} showMenu={true}>
      <div className="wrapper-result">
        <div className="page-title">
          <span>
            PERFIL DE HABILIDADE SOCIOEMOCIONAIS DE <b>{studentName}</b>
          </span>
        </div>
        <div className="report-information">
          <div className="student-information">
            <span>
              Observador responsável:
              <br />
              <b>{observer}</b>
            </span>
            <div className="birthdate">
              <Cake style={{ color: Colors.COLOR_ORANGE, marginRight: 10 }} />
              <b>{studentBirthdate}</b>
            </div>
          </div>
          <span>Processado em {processDate}</span>
        </div>
      </div>
      <div className="tabs-box">
        <Tabs
          className="tabs"
          value={page}
          onChange={handlePages}
          aria-label="wrapped label tabs"
          variant="fullWidth"
        >
          <Tab className="tab" value="zero" label="RELATÓRIO COMPLETO"></Tab>
          <Tab className="tab" value="one" label="PLANO DE AÇÃO"></Tab>
        </Tabs>
      </div>
      {page === "zero" && (
        <div className="report-page">
          {results?.map((result) => (
            <SocioEmotionalCard
              key={result.title}
              {...result}
            ></SocioEmotionalCard>
          ))}
        </div>
      )}

      {page === "one" && (
        <div className="action-plans-page">
          <b>APOIE AÇÕES QUE ENVOLVAM O DESENVOLVIMENTO:</b>
          <div className="action-plan-actions">
            {actionPlans.newSupports?.map((support: any) => (
              <div key={support}>
                <img src={support.icon} />
                <span>{support.name}</span>
              </div>
            ))}
          </div>
          <div>
          <b>INCENTIVE AÇÕES QUE DESENVOLVAM:</b>
            <div className="action-plan-actions">
              {actionPlans.newEncourages?.map((encourage: any) => (
                <div key={encourage}>
                  <img src={encourage.icon} />
                  <span>{encourage.name}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default StudentResult;
