import { FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, OutlinedInput} from "@material-ui/core";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface Props {
    value: any,
    onChange: (value: any) => void,
    options: any[];
    label: string;
    className?: string
 
}

const MultiSelect:  React.FC<Props> = ({value, onChange, options, label, className}) => {
    
    return ( <div>
    <FormControl sx={{ m: 1, width: 300 }} className={className}>
      <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
      <Select
        labelId="demo-multiple-checkbox-label"
        multiple
        value={value}
        onChange={onChange}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) => selected.join(', ')}
        MenuProps={MenuProps}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            <Checkbox checked={value.indexOf(option) > -1} />
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </div>)
}

export default MultiSelect;