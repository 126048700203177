import React, { FC, Suspense } from 'react';
import logo from './logo.svg';
import './App.css';
import { getMessages } from './services/QuestionnaireService';
import { BrowserRouter } from 'react-router-dom';
import MainRoutes from './routes/MainRoutes';

const App : FC = () =>{

	return (
						// {/* </Suspense> */}

							<BrowserRouter basename="">
										<MainRoutes />
							</BrowserRouter>
				)
}

export default App;
