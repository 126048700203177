import {
  QuestionnaireActions,
  QuestionnaireActionTypes,
} from "./QuestionnaireActions";
import { QuestionnaireState } from "./QuestionnaireProvider";

export const QuestionnaireReducer = (
  state: QuestionnaireState,
  action: QuestionnaireActions
): QuestionnaireState => {
  switch (action.type) {
    case QuestionnaireActionTypes.getQuestion:

      return {
        ...state,
        answers: action.payload.answers,
        id: action.payload.id,
        currentQuestionOrder:action.payload.questionOrder
      };

    case QuestionnaireActionTypes.answerQuestion:
      state.answers[state.currentQuestionOrder] = action.payload;

      // console.log("ters", state.currentQuestionOrder)
      return {
        ...state,
        answers: state.answers,  
        currentQuestionOrder: state.currentQuestionOrder + 1 ,
      };

    default:
      return state;
  }
};
