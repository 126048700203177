import {
  Box,
  CSSObject,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { ChevronLeft, Print, Search, ViewCompact } from "@material-ui/icons";
import MuiDrawer from "@mui/material/Drawer";

import { Divider } from "@mui/material";
import { useState } from "react";
import Header from "../Header";
import { Mail, Menu } from "@material-ui/icons";

import "./styles.css";
import { Colors } from "../../../common/Colors";
import { MenuPages } from "../../../common/MenuPages";
import { useNavigate } from "react-router-dom";
import AsideMenu from "./AsideMenu";
import Input from "../Input";
import Button from "../Button";
import { PendingActions } from "@mui/icons-material";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  backgroundColor: Colors.COLOR_ORANGE,
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: Colors.COLOR_ORANGE,
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  backgroundColor: "red",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

interface Props {
  children: any;
  showHeader?: boolean;
  showMenu?: boolean;
  showProfileIcon?: boolean;
  showPanel?: boolean;
}

const Layout: React.FC<Props> = ({
  children,
  showHeader = true,
  showMenu = false,
  showProfileIcon = false,
  showPanel = false,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const history = useNavigate();

  const handleDrawer = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const renderIcon = (icon: string) => {
    switch (icon) {
      case "viewCompact":
        return <ViewCompact style={{color: Colors.COLOR_WHITE}} />;
      case "pendingActions":
        return <PendingActions style={{color: Colors.COLOR_WHITE}} />;
      case "print":
        return <Print style={{color: Colors.COLOR_WHITE}} />;
    }
  };

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      {showHeader && (
        <Header
          showProfileIcon={showProfileIcon}
          showMenuIcon={showMenu}
          isMenuOpen={isMenuOpen}
          handleDrawer={handleDrawer}
          drawerWidth={drawerWidth}
          showPanel={showPanel}
        />
      )}

      {showMenu && (
        <Drawer variant="permanent" open={isMenuOpen} className="drawer">
          <DrawerHeader>
            <IconButton onClick={handleDrawer}>
              <ChevronLeft style={{ color: Colors.COLOR_WHITE }} />
            </IconButton>
          </DrawerHeader>
          <Divider />
          <div>
            {MenuPages.map((page, index) => (
              <ListItem
                className={"item"}
                button
                key={page.title}
                onClick={() => history(`/${page.name}`)}
              >
                <ListItemIcon>
                  <IconButton onClick={handleDrawer}>
                    {renderIcon(page.iconComponent)}
                  </IconButton>
                </ListItemIcon>
                <span style={{ color: Colors.COLOR_WHITE }}>{page.title}</span>
              </ListItem>
            ))}
          </div>
          <Divider />
        </Drawer>
      )}
      <Box className="layout-wrapper">{children}</Box>

     
    </Box>
  );
};

export default Layout;
