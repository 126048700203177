import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp, Search } from "@material-ui/icons";
import { CloseFullscreen, OpenInFull } from "@mui/icons-material";
import { breadcrumbsClasses } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { isPropertyAccessChain } from "typescript";
import { Colors } from "../../../../common/Colors";

import "./styles.css";

interface Props {
  row: any;
  rowProperties?: any;
}

export const Row: React.FC<Props> = ({ row, rowProperties }) => {
  const [open, setOpen] = useState(true);
  const { properties, mainProperty } = rowProperties;

  const history = useNavigate();

  const showLight = (color: string, column: string) => {
    let backgroundColor = Colors.COLOR_DARK_GREY;
    switch (color) {
      case "Red":
        backgroundColor = Colors.COLOR_RED;
        break;
      case "Yellow":
        backgroundColor = Colors.COLOR_YELLOW;
        break;
      case "Green":
        backgroundColor = Colors.COLOR_GREEN;
        break;
      default:
        backgroundColor = Colors.COLOR_DARK_GREY;
        break;
    }

    if (column !== "action") {
      return (
        <div className="cell">
          <span
            style={{
              backgroundColor: backgroundColor,
              width: 20,
              height: 20,
              borderRadius: 20,
            }}
          ></span>
        </div>
      );
    }
  };

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" style={{ fontWeight: "bold" }}>
          {row[mainProperty]}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead className="wrapper-collapse-header">
                  <TableRow>
                    {properties?.map((property: any) => (
                      <TableCell key={property.field} className="table-cell">
                        <div className="cell">{property.headerName}</div>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {properties?.map((property: any) => (
                    <TableCell
                      className="table-cell"
                      component="th"
                      scope="row"
                      key={property.field}
                    >
                      {property.field === "action" ? (
                        <div className='cell'>
                          <IconButton
                            style={{ backgroundColor: Colors.COLOR_CYAN }}
                            className={"search"}
                            onClick={() => history(`/student-result/${row.id}`)}
                          >
                            <Search style={{ color: Colors.COLOR_WHITE }} />
                          </IconButton>
                        </div>
                      ) : (
                        <>{showLight(row[property.field], property.field)}</>
                      )}
                    </TableCell>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
